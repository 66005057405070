import { createSlice } from '@reduxjs/toolkit'
import { LocationState } from 'src/@types/location'
import LocationService from 'src/services/LocationService'
import { dispatch } from '../store'

const initialState: LocationState = {
  isLoading: false,
  error: null,
  locations: [],
  pageCount: 1,
  current: 1,
  total: 0,
}

const slice = createSlice({
  name: 'location',
  initialState,
  reducers: {
    startLoading(state) {
      state.isLoading = true
    },

    hasError(state, action) {
      state.isLoading = false
      state.error = action.payload
    },

    getLocationSuccess(state, action) {
      state.isLoading = false
      state.locations = action.payload.data
    },
  },
})

export default slice.reducer
export const { actions } = slice

export function getLocations(params?: object) {
  return async () => {
    dispatch(slice.actions.startLoading())
    try {
      const response = await LocationService.getLocations(params)
      dispatch(slice.actions.getLocationSuccess(response))
    } catch (error) {
      dispatch(slice.actions.hasError(error))
    }
  }
}
