// @mui
import { enUS } from '@mui/material/locale'

// PLEASE REMOVE `LOCAL STORAGE` WHEN YOU CHANGE SETTINGS.
// ----------------------------------------------------------------------

export const allLangs = [
  {
    label: 'English',
    value: 'en',
    locale: 'en_EN',
    systemValue: enUS,
    icon: '/assets/icons/flags/ic_flag_en.svg',
  },
  {
    label: 'Монгол',
    value: 'mn',
    locale: 'mn_MN',
    systemValue: enUS,
    icon: '/assets/icons/flags/ic_flag_mn.svg',
  },
]

export const defaultLang = allLangs[1] // Mongolia
