import { LoadingButton } from '@mui/lab'
import { Alert, Box, Button, IconButton, Stack, TextField, Typography } from '@mui/material'
import { useSnackbar } from 'notistack'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { Trans } from 'react-i18next'
import Iconify from 'src/components/Iconify'
import { useLocales } from 'src/locales'
import AuthService from 'src/services/AuthService'
import { LoginInfoValues } from './LoginInfoForm'

type Props = {
  loginInfo: LoginInfoValues
  handleVerificationValue: (values: VerificationInfoValues) => void
  setStep: React.Dispatch<React.SetStateAction<number>>
}

export type VerificationInfoValues = {
  verification_id: number
}

const EmailVerification = ({ handleVerificationValue, loginInfo, setStep }: Props) => {
  const { translate: t } = useLocales()
  const [isVerified, setIsVerified] = useState<boolean>(false)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [verification, setVerification] = useState<number | null>(null)
  const [countdown, setCountdown] = useState<number>(60)
  const [countdownPending, setCountdownPending] = useState<boolean>(false)
  const { enqueueSnackbar } = useSnackbar()

  const countdownRef = useRef<NodeJS.Timer | null>(null)

  const startTimer = useCallback(() => {
    setCountdownPending(true)
    countdownRef.current = setInterval(() => {
      setCountdown((prevState) => {
        if (prevState <= 0) {
          countdownRef.current !== null && clearInterval(countdownRef.current)
          countdownRef.current = null
          setCountdownPending(false)
          return 60
        }
        return prevState - 1
      })
    }, 1000)
  }, [setCountdown])

  const handleSendVerification = useCallback(() => {
    if (countdownRef.current !== null) return
    setIsLoading(true)
    startTimer()
    AuthService.sendVerificaitonEmail({ email: loginInfo.email })
      .then((res) => {
        setIsLoading(false)
        setVerification(res.data.data.verification_id)
      })
      .catch((err) => {
        setIsLoading(false)
        enqueueSnackbar(err.response.data.message, { variant: 'error' })
      })
  }, [startTimer, enqueueSnackbar, loginInfo])

  const handleVerify = (code: string) => {
    if (verification === null) return
    setIsLoading(true)
    AuthService.verifyEmail({
      verification_id: verification,
      code,
    })
      .then((res) => {
        setIsLoading(false)
        setIsVerified(true)
        handleVerificationValue({
          verification_id: verification,
        })
      })
      .catch((err) => {
        setIsVerified(false)
        setIsLoading(false)
      })
  }

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { value } = e.target
    if (value.length >= 4) handleVerify(value)
  }

  useEffect(
    () => () => {
      countdownRef.current !== null && clearInterval(countdownRef.current)
    },
    []
  )

  useEffect(() => {
    handleSendVerification()
  }, [handleSendVerification])

  return (
    <Stack spacing={3}>
      <Alert severity="info">
        <Trans i18nKey="verification.sendAlert" values={{ email: loginInfo.email }} />
      </Alert>
      <Stack direction="row" spacing={3} alignItems="center">
        <TextField
          onChange={handleInputChange}
          type="number"
          placeholder={t('verification.code') + ''}
          sx={{ flex: 1 }}
          inputProps={{ maxLength: 4 }}
        />

        <IconButton onClick={handleSendVerification} disabled={countdownPending || isLoading}>
          <Iconify icon="ic:round-refresh" />
        </IconButton>
      </Stack>
      {countdownPending && (
        <Typography>
          <Trans i18nKey="verification.reload" values={{ countdown }} />
        </Typography>
      )}
      <Box sx={{ textAlign: 'right' }}>
        <Button color="inherit" variant="text" sx={{ mr: 1, px: 2 }} onClick={() => setStep(3)}>
          {`${t('back')}`}
        </Button>
        <LoadingButton
          type="submit"
          variant="contained"
          loading={isLoading}
          disabled={!isVerified}
          sx={{
            bgcolor: '#1A0289',
            color: '#fff',
            '&:hover': {
              bgcolor: '#1A0289',
              color: '#fff',
            },
          }}
        >
          {`${t('continue')}`}
        </LoadingButton>
      </Box>
    </Stack>
  )
}

export default EmailVerification
