import { createSlice } from '@reduxjs/toolkit'
import { CompanyState } from 'src/@types/company'
import CompanyService from 'src/services/CompanyService'
import { dispatch } from '../store'

const initialState: CompanyState = {
  isLoading: false,
  error: null,
  companies: [],
}

const slice = createSlice({
  name: 'company',
  initialState,
  reducers: {
    startLoading(state) {
      state.isLoading = true
    },

    hasError(state, action) {
      state.isLoading = false
      state.error = action.payload
    },

    getCompaniesSuccess(state, action) {
      state.isLoading = false
      state.companies = action.payload
    },

    removeCompanySuccess(state, action) {
      const { id } = action.payload

      state.isLoading = false
      state.companies = state.companies.filter((c) => c.id !== id)
    },

    updateCompanySuccess(state, action) {
      const { id } = action.payload

      state.isLoading = false
      state.companies = state.companies.map((c) => (c.id === id ? action.payload : c))
    },

    addCompanySuccess(state, action) {
      const newCompany = action.payload

      state.isLoading = false
      state.companies = [...state.companies, newCompany]
    },
  },
})

export default slice.reducer
export const { actions } = slice

// ----------------------------------------------------------------------

export function getCompanies() {
  return async () => {
    dispatch(slice.actions.startLoading())
    try {
      const response = await CompanyService.getCompanies()
      dispatch(slice.actions.getCompaniesSuccess(response.data))
    } catch (error) {
      dispatch(slice.actions.hasError(error))
    }
  }
}

export function removeCompany(id: number) {
  return async () => {
    dispatch(slice.actions.startLoading())
    try {
      await CompanyService.removeCompany(id)
      dispatch(slice.actions.removeCompanySuccess({ id }))
    } catch (error) {
      dispatch(slice.actions.hasError(error))
    }
  }
}

export function updateCompany(data: FormData, id: number) {
  return async () => {
    dispatch(slice.actions.startLoading())
    try {
      const response = await CompanyService.updateCompany(id, data)
      dispatch(slice.actions.updateCompanySuccess(response.data))
    } catch (error) {
      dispatch(slice.actions.hasError(error))
    }
  }
}

export function addCompany(data: FormData) {
  return async () => {
    dispatch(slice.actions.startLoading())
    try {
      const response = await CompanyService.addCompany(data)
      dispatch(slice.actions.addCompanySuccess(response.data))
    } catch (error) {
      dispatch(slice.actions.hasError(error))
    }
  }
}
