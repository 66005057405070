import service from './interceptors'

const TaskService = {
  getSpaces() {
    return service({
      url: 'kanban/space',
      method: 'get',
    })
  },

  createSpace(data: object) {
    return service({
      url: 'kanban/space',
      method: 'post',
      data,
    })
  },

  updateSpace(id: number, data: object) {
    return service({
      url: `kanban/space/${id}`,
      method: 'put',
      data,
    })
  },

  deleteSpace(id: number) {
    return service({
      url: `kanban/space/${id}`,
      method: 'delete',
    })
  },

  getBoards(spaceId: number | string) {
    return service({
      url: `kanban/space/${spaceId}/board`,
      method: 'get',
    })
  },

  createBoard(spaceId: number | string, data: object) {
    return service({
      url: `kanban/space/${spaceId}/board`,
      method: 'post',
      data,
    })
  },

  moveBoard(spaceId: number | string, boardId: number, data: object) {
    return service({
      url: `kanban/space/${spaceId}/board/${boardId}/move`,
      method: 'post',
      data,
    })
  },

  updateBoard(spaceId: number | string, boardId: number, data: object) {
    return service({
      url: `kanban/space/${spaceId}/board/${boardId}`,
      method: 'put',
      data,
    })
  },

  deleteBoard(spaceId: number | string, boardId: number) {
    return service({
      url: `kanban/space/${spaceId}/board/${boardId}`,
      method: 'delete',
    })
  },

  getTasks(boardId: number) {
    return service({
      url: `kanban/board/${boardId}/task`,
      method: 'get',
    })
  },

  getTaskOverview(boardId: number, id: number) {
    return service({
      url: `kanban/board/${boardId}/task/${id}`,
      method: 'get',
    })
  },

  createTask(boardId: number, data: object) {
    return service({
      url: `kanban/board/${boardId}/task`,
      method: 'post',
      data,
    })
  },

  moveTask(boardId: number, taskId: number, data: object) {
    return service({
      url: `kanban/board/${boardId}/task/${taskId}/move`,
      method: 'post',
      data,
    })
  },

  updateTask(boardId: number, taskId: number, data: object) {
    return service({
      url: `kanban/board/${boardId}/task/${taskId}`,
      method: 'put',
      data,
    })
  },

  deleteTask(boardId: number, taskId: number) {
    return service({
      url: `kanban/board/${boardId}/task/${taskId}`,
      method: 'delete',
    })
  },
}

export default TaskService
