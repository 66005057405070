import { useTranslation } from 'react-i18next'
// utils
import localStorageAvailable from '../utils/localStorageAvailable'
// components
import { useSettings } from '../components/settings'
//
import { allLangs, defaultLang } from './config-lang'
import moment from 'moment'

// ----------------------------------------------------------------------

export default function useLocales() {
  const { i18n, t: translate } = useTranslation()

  const { onChangeDirectionByLang } = useSettings()

  const storageAvailable = localStorageAvailable()

  const langStorage = storageAvailable ? localStorage.getItem('i18nextLng') : ''

  const currentLang = allLangs.find((_lang) => _lang.value === langStorage) || defaultLang

  const handleChangeLanguage = (newlang: string) => {
    i18n.changeLanguage(newlang)
    onChangeDirectionByLang(newlang)
  }
  langStorage !== null && moment.locale(langStorage)
  return {
    onChangeLang: handleChangeLanguage,
    translate: (text: any, options?: any) => translate(text, options),
    currentLang,
    allLangs,
  }
}
