// @mui
import { Box, Typography, Stack } from '@mui/material'
// assets
import { UploadIllustration } from '../../assets'
import { useLocales } from 'src/locales'
import { Trans } from 'react-i18next'

// ----------------------------------------------------------------------

export default function BlockContent() {
  const { translate: t } = useLocales()

  return (
    <Stack
      spacing={2}
      alignItems="center"
      justifyContent="center"
      direction={{ xs: 'column', md: 'row' }}
      sx={{ width: 1, textAlign: { xs: 'center', md: 'left' } }}
    >
      <UploadIllustration sx={{ width: 220 }} />

      <Box sx={{ p: 3 }}>
        <Typography gutterBottom variant="h5">
          {`${t('dragDrop')}`}
        </Typography>

        <Typography variant="body2" sx={{ color: 'text.secondary' }}>
          <Trans i18nKey="dragHere" />
        </Typography>
      </Box>
    </Stack>
  )
}
