interface TermsType {
  title: string
  data: Condition[]
}

interface Condition {
  title: string
  list: List[]
}

interface List {
  body: string
  subData: Subdata
}

interface Subdata {
  haveSub: boolean
  data?: string[]
}

const terms: TermsType = {
  title: 'IRLE" ПЛАТФОРМЫН ҮЙЛЧИЛГЭЭНИЙ НӨХЦӨЛ',
  data: [
    {
      title: 'Нэг. Ерөнхий Нөхцөл',
      list: [
        {
          body: 'Энэхүү Нөхцөлүүд нь “Фаро Лимитед Групп” ХХК (цаашид "Үйлчилгээ үзүүлэгч" гэх)-ийн эзэмшил, өмчлөлийн Irle цаг бүртгэлийн аппликейшн болон вэб сайтын үйлчилгээ (цаашид "Үйлчилгээ" гэх)-г ашиглах Хэрэглэгчийн эрх, үүргийг зохицуулсан журам юм.',
          subData: {
            haveSub: false,
          },
        },
        {
          body: 'Энэхүү Нөхцөлүүд нь уг Үйлчилгээг ашиглахтай холбоотой Үйлчилгээ үзүүлэгч ба Хэрэглэгчийн хоорондох эрх, үүрэг болон үүнтэй холбогдох бусад харилцааг зохицуулах зорилготой.',
          subData: {
            haveSub: false,
          },
        },
        {
          body: 'Хэрэв Хэрэглэгч www.irle.app вэбсайт болон Irle аппликейшнд бүртгүүлсэн бол энэхүү нөхцөлүүдийг хүлээн зөвшөөрсөнд тооцно.',
          subData: {
            haveSub: false,
          },
        },
        {
          body: 'Вэб сайт болон аппликейшны ажиллагаа, түүний аюулгүй байдал, хэрэглэгчдийн мэдээллийн нууцлалыг Үйлчилгээ үзүүлэгч хариуцна.',
          subData: {
            haveSub: false,
          },
        },
        {
          body: 'Нөхцөлүүдийн бүх зүйлийг зөвшөөрөхгүй бол энэ Үйлчилгээг ашиглах боломжгүй.',
          subData: {
            haveSub: false,
          },
        },
        {
          body: 'Хэрэглэгч нь  www.irle.app вэб сайтад нэвтрэх цахим шуудан болон нууц үгийг бусдад дамжуулахгүй байх, үүнтэй холбоотой учирч болох аливаа эрсдэлээс урьдчилан сэргийлэх үүрэгтэй. Энэхүү үүргээ биелүүлээгүй буюу зохих ёсоор биелүүлээгүйн улмаас учирсан аливаа хохирлыг Үйлчилгээ үзүүлэгч хариуцахгүй.',
          subData: {
            haveSub: false,
          },
        },
        {
          body: 'Хэрэглэгч вэбсайтын талаар санал, гомдол, хүсэлт, шүүмж зэргээ илэрхийлэх, илгээх эрхтэй.',
          subData: {
            haveSub: false,
          },
        },
        {
          body: 'Хэрэглэгчийн мэдээллийн үнэн зөв, бодит байдалд хэрэглэгч бүрэн хариуцлага хүлээнэ.',
          subData: {
            haveSub: false,
          },
        },
      ],
    },
    {
      title: 'Хоёр. Үйлчилгээ үзүүлэгч',
      list: [
        {
          body: 'Энэхүү Үйлчилгээнд багтсан барааны тэмдэг, патент, зохиогчийн эрх, худалдааны нууц, ноу-хау болон агуулгын талаарх бусад контент (текст, диаграмм, зураг, зураг, видео, дуу, програм гэх мэт) нь Үйлчилгээ үзүүлэгчийн өөрийн буюу Үйлчилгээ үзүүлэгчид түүнийг ашиглах эрх олгосон эрх эзэмшигчдийн өмч болно.',
          subData: {
            haveSub: false,
          },
        },
        {
          body: 'Хэрэглэгч болон бусад гуравдагч этгээдүүд нь вэб сайт болон аппликейшны лого, нэр бусад зүйлсийг хуулах, олшруулах, дуурайх, өөр бусад хэлбэрээр ашиглахыг хориглоно.',
          subData: {
            haveSub: false,
          },
        },
        {
          body: 'Үйлчилгээний нөхцөлийн 2.2-т заасныг зөрчсөний улмаас, Үйлчилгээ үзүүлэгч болон гуравдагч этгээдтэй холбоотой оюуны өмчтэй холбоотой маргаан үүсвэл тухайн этгээд учирсан хохирлыг өөрийн зардлаар хариуцах бөгөөд Үйлчилгээ үзүүлэгч ямар нэгэн хариуцлага хүлээхгүй.',
          subData: {
            haveSub: false,
          },
        },
      ],
    },
    {
      title: 'Гурав. Хэрэглэгч',
      list: [
        {
          body: 'Нэг хэрэглэгч нэг л и-мэйл хаягаар бүртгүүлэх эрхтэй. Ашиглагдсан имэйл хаягийг дахин бүртгүүлэх боломжгүй.',
          subData: {
            haveSub: false,
          },
        },
        {
          body: 'Хэрэглэгч нь бүртгэлийн мэдээллээ бүрэн хянаж, баталгаажуулах ёстой бөгөөд  дараах зүйлсийг хийхийг хориглоно. Үүнд:',
          subData: {
            haveSub: true,
            data: [
              'Энэ үйлчилгээг ашигладаг бусад Хэрэглэгчдийг гүтгэх, ялгаварлан гадуурхах, аливаа үйлдэл хийхэд саад болох болон бусад зүй бус үйлдэл;',
              'Үйлчилгээ үзүүлэгчийн зохиогчийн эрх, оюуны өмчийн эрх, нууцлал зэрэг бүхий л хууль ёсны эрх, ашиг сонирхлыг зөрчсөн буюу түүнд харшлах үйлдэл;',
              'Энэхүү Үйлчилгээгээр дамжуулан олж авсан мэдээллийг ашиг олох болон өөртөө давуу эрх олж авах зорилгоор гуравдагч этгээдэд шилжүүлэх;',
              'Бусад Хэрэглэгчийн нэр эсвэл нууц үгийг ашиглах; ',
              'Хэрэглэгчийн нэр, нууц үгийг гуравдагч этгээд ашиглах, шилжүүлэх, нэр солилцох, худалдаж авах, зарах, барьцаалах;',
              'Энэ үйлчилгээнд багтсан програм хангамжийн эх кодыг задлах;',
              'Автоматжуулсан програм ашиглан энэ Үйлчилгээний ажиллагаанд саад болох эсвэл бусад хэрэглэгч нарт саад болох үйлдэл хийх;',
              'Олон нийтийн дэг журам, ёс суртахууныг зөрчсөн үйлдэл;',
              'Монгол улсын хууль тогтоомжийг зөрчсөн,  үйл ажиллагаанд саад учруулж буй бусад үйлдэл;',
            ],
          },
        },
        {
          body: 'Хэрэглэгчид өөрсдийн Хэрэглэгчийн нэр, нууц үгээ ашиглах, удирдах, хамгаалах үүрэгтэй.',
          subData: {
            haveSub: false,
          },
        },
        {
          body: 'Хэрэв Хэрэглэгчийн нэр, нууц үгийг гуравдагч этгээд ашигласан тохиолдолд тухайн Хэрэглэгч үүнтэй холбоотойгоор учирсан хохирлыг бүрэн хариуцна.',
          subData: {
            haveSub: false,
          },
        },
      ],
    },
    {
      title: 'Дөрөв. Үйлчилгээ',
      list: [
        {
          body: 'Үйлчилгээ нь “Basic”, “Pro”, “Premium” гэсэн үндсэн 3 төрлийн багцтай байна.',
          subData: {
            haveSub: false,
          },
        },
        {
          body: 'Үйлчилгээний төлбөр нь Хэрэглэгчийн ажилтны тооноос хамаарах буюу нэг ажилтанд ногдох төлбөрийг ажилтны тоогоор үржүүлэн бодно.',
          subData: {
            haveSub: false,
          },
        },
        {
          body: 'Үйлчилгээний төлбөрийг гэрээ байгуулснаас ажлын 7 өдрийн дотор Үйлчилгээ үзүүлэгчийн нэхэмжлэхийн дагуу “Фаро Лимитед Групп” ХХК-ийн Худалдаа хөгжлийн банк дахь 417039485 тоот дансанд шилжүүлнэ.',
          subData: {
            haveSub: false,
          },
        },
        {
          body: 'Хэрэглэгч нь Цаг бүртгэлийн платформыг ашиглахгүй байх эсвэл ажилтны тоо цөөрөх, гэрээний хугацаанаас өмнө гэрээг цуцлах нь төлбөр бууруулах буюу буцаан олгох үндэслэл болохгүй.',
          subData: {
            haveSub: false,
          },
        },
      ],
    },
    {
      title: 'Тав. Хувийн мэдээллийг хадгалж, хамгаалж, ашиглах',
      list: [
        {
          body: 'Үйлчилгээ үзүүлэгч нь холбогдох хууль, тогтоомж, энэхүү үйлчилгээний нөхцөлд заасны дагуу хэрэглэгчийг таних, баталгаажуулах, бүртгэх, мөн бүтээгдэхүүн үйлчилгээг сайжруулах, хэрэглэгчийн санал гомдлыг хүлээн авч шийдвэрлэх, дүн шинжилгээ хийх, төлбөр цуглуулах, урамшуулал сурталчилгааны мэдээлэл, мэдэгдэл хүргэх зорилгоор хүний хувийн мэдээллийг цуглуулж, боловсруулж, ашиглана.',
          subData: {
            haveSub: false,
          },
        },
        {
          body: 'Үйлчилгээ үзүүлэгч нь бүтээгдэхүүн үйлчилгээнд сайжруулалт хийх, шинэ бүтээгдэхүүн үйлчилгээг санал болгох зорилгын хүрээнд хуульд заасан үндэслэл, журмын дагуу хүний хувийн мэдээллийг гуравдагч этгээдэд гэрээ, үйлчилгээний нөхцөлийн үндсэн дээр дамжуулж болно.',
          subData: {
            haveSub: false,
          },
        },
        {
          body: 'Үйлчилгээ үзүүлэгч нь гэрээ, үйлчилгээний нөхцөлийн хүчин төгөлдөр хугацаанд болон дуусгавар болсноос хойш гэрээний үүргийн биелэлтийг хангахтай холбоотойгоор үүсэх шаардах эрхийг хэрэгжүүлэх эсхүл хууль тогтоомжид заасан хугацаанд хүний хувийн мэдээллийг боловсруулж, ашиглана.',
          subData: {
            haveSub: false,
          },
        },
        {
          body: 'Үйлчилгээ үзүүлэгч нь хүний хувийн мэдээллийг олон нийтэд ил болгохгүй бөгөөд цуглуулах, ашиглах, боловсруулах явцад санаатай болон болгоомжгүйгээр алдах, устгах, өөрчлөх, хуулбарлах, бусад хууль бус ажиллагаанаас хамгаалах, мэдээлэл алдагдсан тохиолдолд мэдээллийн эзэнд хуульд заасны дагуу мэдэгдэнэ.',
          subData: {
            haveSub: false,
          },
        },
        {
          body: 'Хэрэглэгч бичгээр хүсэлт гарган үйлчилгээ үзүүлэгчид олгосон өөрийн хувийн мэдээллийг цуглуулах, ашиглах, боловсруулах зөвшөөрлөө цуцлах эрхтэй.',
          subData: {
            haveSub: false,
          },
        },
        {
          body: 'Үйлчилгээний нөхцөлийг зөвшөөрснөөр Хэрэглэгч нь зөвхөн тухайн бүтээгдэхүүн үйлчилгээг үзүүлж буй үйлчилгээ үзүүлэгчид зөвшөөрлийг өгч байгаа болно.',
          subData: {
            haveSub: false,
          },
        },
        {
          body: 'Үйлчилгээний нөхцөлийг уншиж танилцсанаар хэрэглэгч үйлчилгээ үзүүлэгчийн үйлчилгээг авах боломжтой болох бөгөөд нөхцөлтэй танилцаагүйгээс үүдэн гарах аливаа асуудлыг Үйлчилгээ үзүүлэгч хариуцахгүй.',
          subData: {
            haveSub: false,
          },
        },
        {
          body: 'Харилцагч нь өөрийн төхөөрөмж, гар утсан дээрх үйлчилгээ, апликейшнүүдийг ашиглах, линк рүү орох, мэдээлэл, контент татаж авахдаа нууцлалын нөхцөлийг сайтар судлан танилцсан байх үүрэгтэй.',
          subData: {
            haveSub: false,
          },
        },
        {
          body: 'Үйлчилгээ үзүүлэгч нь Хэрэглэгч, түүний ажилтнуудын мэдээллийг Цаг бүртгэлийн платформд 6 сар хүртэлх хугацаагаар хадгалах үүрэгтэй бөгөөд энэ хугацаа өнгөрсөн тохиолдолд хугацаа хэтэрсэн дата, мэдээллийг Үйлчилгээ үзүүлэгч тал хариуцахгүй.',
          subData: {
            haveSub: false,
          },
        },
        {
          body: 'Үйлчилгээ үзүүлэгч нь Хэрэглэгчийн хувийн мэдээллийг зүй бусаар, ашиг олох зорилгоор ашиглах эсхүл гуравдагч этгээдэд шилжүүлэхгүй.',
          subData: {
            haveSub: false,
          },
        },
      ],
    },
    {
      title: 'Зургаа. Хариуцлага',
      list: [
        {
          body: 'Хэрэглэгчтэй холбоотой аливаа мэдээллийн үнэн, зөв байдалд Үйлчилгээ үзүүлэгчийн зүгээс баталгаа өгөхгүй бөгөөд Хэрэглэгчийн оруулсан мэдээлэлтэй холбоотой аливаа маргаан, хохирлыг Үйлчилгээ үзүүлэгч хариуцахгүй.',
          subData: {
            haveSub: false,
          },
        },
        {
          body: 'Талууд Үйлчилгээтэй холбоотой аливаа шууд бус хохирлыг нэхэмжлэхгүй.',
          subData: {
            haveSub: false,
          },
        },
        {
          body: 'Харилцагч болон гуравдагч этгээдийн үйлдэл, эс үйлдэл, гэнэтийн болон давагдашгүй хүчин зүйлээс учирсан хохирлыг Үйлчилгээ үзүүлэгч хариуцахгүй.',
          subData: {
            haveSub: false,
          },
        },
        {
          body: 'Хэрэглэгч нь Цаг бүртгэлийн платформыг туршилтаар эсхүл түүний демо хувилбарыг 30 хоног үнэ төлбөргүй ашиглах боломжтой. Туршилтаар эсхүл демо хувилбарыг ашиглах тохиолдолд Үйлчилгээ үзүүлэгч тал нь тухайн хугацаанд аливаа мэдээлэл, датаг хадгалах үүрэг хүлээхгүй.',
          subData: {
            haveSub: false,
          },
        },
      ],
    },
    {
      title: 'Долоо. Нэмэлт өөрчлөлт',
      list: [
        {
          body: 'Үйлчилгээ үзүүлэгч нь энэ Нөхцөлийг Хэрэглэгчийн зөвшөөрөлгүйгээр өөрчлөх боломжтой бөгөөд өөрчлөлтийн агуулгыг тухай бүр вебсайт болон аппликэйшнд нэг сарын турш байршуулах бөгөөд уг хугацаа өнгөрсний дараа бүх Хэрэглэгчид зөвшөөрсөнд тооцно.',
          subData: {
            haveSub: false,
          },
        },
      ],
    },
    {
      title: 'Найм. Бусад',
      list: [
        {
          body: 'Энэхүү Нөхцөлийг Монгол хэлээр баталсан бөгөөд гадаад хэл дээр орчуулга хийгдсэн тохиолдолд  Монгол хэл дээрх үндсэн эхийн утга агуулгыг баримтална.',
          subData: {
            haveSub: false,
          },
        },
        {
          body: 'Энэхүү Нөхцөлийг Монгол улсын хууль тогтоомжийн дагуу тайлбарлана.',
          subData: {
            haveSub: false,
          },
        },
        {
          body: 'Энэхүү Нөхцөлтэй холбоотойгоор маргаан үүссэн тохиолдолд Монгол улсын шүүхэд хандаж, шийдвэрлүүлнэ.',
          subData: {
            haveSub: false,
          },
        },
        {
          body: 'Хэрэглэгч тал нь Цаг бүртгэлийн платформтой холбоотой санал, хүсэлт, гомдлыг ажлын өдөр, 09:00 цагаас 18:00 цагийн хооронд Үйлчилгээ үзүүлэгч талын +976 72222220 утас болон hello@irle.app имэйл хаягаар хүргүүлж болно.',
          subData: {
            haveSub: false,
          },
        },
      ],
    },
  ],
}

export default terms
