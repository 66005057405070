import { HOST_API_V2 } from 'src/config'
import service from './interceptors'

const TimeshiftService = {
  getTimeshifts: function (params?: object) {
    return service({
      url: 'timeshift',
      method: 'get',
      params,
    })
  },

  addTimeshift: function (data: object) {
    return service({
      url: 'timeshift',
      method: 'post',
      data,
    })
  },

  getOverview: function (id: number) {
    return service({
      url: `timeshift/${id}`,
      method: 'get',
    })
  },

  deleteTimeshift: function (id: number) {
    return service({
      url: `timeshift/${id}`,
      method: 'delete',
    })
  },

  editTimeshift: function (id: number, data: object) {
    return service({
      url: `timeshift/${id}`,
      method: 'put',
      data,
    })
  },

  getEmployeeShifts: function (params?: object) {
    return service({
      baseURL: HOST_API_V2,
      url: 'shift',
      method: 'get',
      params,
    })
  },

  getEmployeeMonthlyShifts: function (params?: object) {
    return service({
      baseURL: HOST_API_V2,
      url: 'shift/group/month',
      method: 'get',
      params,
    })
  },

  moveShift: function (shiftId: number, data: object) {
    return service({
      baseURL: HOST_API_V2,
      url: `shift/${shiftId}/move`,
      method: 'put',
      data,
    })
  },

  addShift: function (data: object) {
    return service({
      baseURL: HOST_API_V2,
      url: 'shift',
      method: 'post',
      data,
    })
  },

  updateShift: function (id: number, data: object) {
    return service({
      baseURL: HOST_API_V2,
      url: `shift/${id}`,
      method: 'put',
      data,
    })
  },

  deleteShift: function (id: number, data: object) {
    return service({
      baseURL: HOST_API_V2,
      url: `shift/${id}`,
      method: 'delete',
      data,
    })
  },

  publishShifts: function (data: object) {
    return service({
      baseURL: HOST_API_V2,
      url: 'shift/action/publish',
      method: 'post',
      data,
    })
  },

  getEmployeeAttendances: function (params?: object) {
    return service({
      baseURL: HOST_API_V2,
      url: 'attendance/statistics/employee',
      method: 'get',
      params,
    })
  },

  getShiftOverview: function (id: number, date: string) {
    return service({
      baseURL: HOST_API_V2,
      url: `shift/${id}`,
      method: 'get',
      params: {
        date,
      },
    })
  },

  updateShiftAttendances: function (data: object) {
    return service({
      baseURL: HOST_API_V2,
      url: 'attendance/action/group/bulk',
      method: 'post',
      data,
    })
  },

  clearShifts: function (data: object, params: object) {
    return service({
      baseURL: HOST_API_V2,
      url: 'shift/action/clear',
      method: 'post',
      data,
      params,
    })
  },

  getAttendanceReport: function (params?: object) {
    return service({
      baseURL: HOST_API_V2,
      url: 'attendance/statistics/report',
      method: 'get',
      responseType: 'blob',
      params,
    })
  },

  getAttendanceDetailedReport: function (params?: object) {
    return service({
      baseURL: HOST_API_V2,
      url: 'attendance/statistics/detailed-report',
      method: 'get',
      responseType: 'blob',
      params,
    })
  },
}

export default TimeshiftService
