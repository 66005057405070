// @mui
import { TableRow, TableCell, BoxProps } from '@mui/material'
//
import EmptyContent from '../EmptyContent'

// ----------------------------------------------------------------------

interface Props extends BoxProps {
  isNotFound: boolean
  title?: string
}

export default function TableNoData({ isNotFound, title = 'No Data', sx }: Props) {
  return (
    <TableRow>
      {isNotFound ? (
        <TableCell colSpan={12}>
          <EmptyContent
            title={title}
            sx={{
              '& span.MuiBox-root': { height: 160 },
              ...sx,
            }}
          />
        </TableCell>
      ) : (
        <TableCell colSpan={12} sx={{ p: 0 }} />
      )}
    </TableRow>
  )
}
