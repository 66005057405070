import { createSlice } from "@reduxjs/toolkit";
import { RequestTypeState } from "src/@types/request";
import RequestService from "src/services/RequestService";
import { dispatch } from "../store";

const initialState: RequestTypeState = {
  loading: false,
  error: null,
  requestTypes: []
}

const slice = createSlice({
  name: 'request-type',
  initialState,
  reducers: {
    startLoading(state) {
      state.loading = true
    },

    hasError(state, action) {
      state.loading = false
      state.error = action.payload
    },

    getTypesSuccess(state, action) {
      state.loading = false
      state.requestTypes = action.payload
    }
  }
})

export default slice.reducer
export const { actions } = slice


export function getTypes() {
  return async() => {
      dispatch(slice.actions.startLoading())
      try {
        const response = await RequestService.getTypes()
        dispatch(slice.actions.getTypesSuccess(response.data))
      } catch (error) {
        dispatch(slice.actions.hasError(error.response.data.message))
      }
  }
}

