import { combineReducers } from '@reduxjs/toolkit'
import companyReducer from './slices/company'
import departmentReducer from './slices/department'
import employeeReducer from './slices/employee'
import teamReducer from './slices/team'
import roleReducer from './slices/role'
import locationReducer from './slices/location'
import formReducer from './slices/form'
import requestTypeReducer from './slices/requestType'
import shiftReducer from './slices/shift'
import taskReducer from './slices/task'

const rootReducer = combineReducers({
  company: companyReducer,
  employee: employeeReducer,
  team: teamReducer,
  role: roleReducer,
  location: locationReducer,
  department: departmentReducer,
  form: formReducer,
  requestType: requestTypeReducer,
  shift: shiftReducer,
  task: taskReducer,
})

export { rootReducer }
