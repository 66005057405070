import { useState } from 'react'
// @mui
import { alpha } from '@mui/material/styles'
import { Box, Divider, Typography, Stack, MenuItem, Avatar } from '@mui/material'
// components
import MenuPopover from '../../../components/MenuPopover'
import { IconButtonAnimate } from '../../../components/animate'
import useAuth from 'src/hooks/useAuth'
import { Link as RouterLink, useNavigate } from 'react-router-dom'
import { PATH_AUTH, PATH_DASHBOARD } from 'src/routes/paths'
import useIsMountedRef from 'src/hooks/useIsMountedRef'
import { useSnackbar } from 'notistack'
import { useLocales } from 'src/locales'

// ----------------------------------------------------------------------

const MENU_OPTIONS = [
  {
    label: 'settings',
    linkTo: PATH_DASHBOARD.settings.account,
  },
]

// ----------------------------------------------------------------------

export default function AccountPopover() {
  const { translate: t } = useLocales()
  const { user, mainUser, logout } = useAuth()
  const navigate = useNavigate()

  const isMountedRef = useIsMountedRef()

  const [open, setOpen] = useState<HTMLElement | null>(null)
  const { enqueueSnackbar } = useSnackbar()

  const handleOpen = (event: React.MouseEvent<HTMLElement>) => {
    setOpen(event.currentTarget)
  }

  const handleClose = () => {
    setOpen(null)
  }

  const handleLogout = async () => {
    try {
      await logout()
      navigate(PATH_AUTH.login, { replace: true })

      if (isMountedRef.current) {
        handleClose()
      }
    } catch (error) {
      console.error(error)
      enqueueSnackbar('Гарахад алдаа гарлаа.', { variant: 'error' })
    }
  }

  return (
    <>
      <IconButtonAnimate
        onClick={handleOpen}
        sx={{
          p: 0,
          ...(open && {
            '&:before': {
              zIndex: 1,
              content: "''",
              width: '100%',
              height: '100%',
              borderRadius: '50%',
              position: 'absolute',
              bgcolor: (theme) => alpha(theme.palette.grey[900], 0.8),
            },
          }),
        }}
      >
        <Avatar src={mainUser?.avatar} alt={user?.name} />
      </IconButtonAnimate>

      <MenuPopover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleClose}
        sx={{
          p: 0,
          mt: 1.5,
          ml: 0.75,
          '& .MuiMenuItem-root': {
            typography: 'body2',
            borderRadius: 0.75,
          },
        }}
      >
        <Box sx={{ my: 1.5, px: 2.5 }}>
          <Typography variant="subtitle2" noWrap>
            {user && user.name}
          </Typography>
          <Typography variant="body2" sx={{ color: 'text.secondary' }} noWrap>
            {mainUser && mainUser.email}
          </Typography>
        </Box>

        <Divider sx={{ borderStyle: 'dashed' }} />

        <Stack sx={{ p: 1 }}>
          {MENU_OPTIONS.map((option) => (
            <MenuItem
              key={option.label}
              to={option.linkTo}
              component={RouterLink}
              onClick={handleClose}
            >
              {`${t(option.label)}`}
            </MenuItem>
          ))}
        </Stack>

        <Divider sx={{ borderStyle: 'dashed' }} />

        <MenuItem onClick={handleLogout} sx={{ m: 1 }}>
          {`${t('logout')}`}
        </MenuItem>
      </MenuPopover>
    </>
  )
}
