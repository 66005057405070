import { createSlice } from '@reduxjs/toolkit'
import { MoveTaskProps, Task, TaskBoard, TaskState } from 'src/@types/task'
import { dispatch } from '../store'
import TaskService from 'src/services/TaskService'

const sortBoards = (data: TaskBoard[]) =>
  data.sort((a, b) => {
    if (a.order < b.order) return -1
    if (a.order > b.order) return 1
    return 0
  })

const sortTasks = (data: Task[]) =>
  data.sort((a, b) => {
    if (a.order < b.order) return -1
    if (a.order > b.order) return 1
    return 0
  })

const initialState: TaskState = {
  error: null,
  fetchError: null,
  isLoading: false,
  boards: [],
  tasks: [],
  maxBoardsOrder: 0,
}

const slice = createSlice({
  name: 'task',
  initialState,
  reducers: {
    setLoading(state, action) {
      state.isLoading = action.payload
    },

    hasError(state, action) {
      state.isLoading = false
      state.error = action.payload
    },

    hasFetchError(state, action) {
      state.isLoading = false
      state.fetchError = action.payload
    },

    getBoardsSuccess(state, action) {
      state.isLoading = false
      state.boards = sortBoards(action.payload)
      state.maxBoardsOrder =
        action.payload.length > 0 ? Math.max(...action.payload.map((b: TaskBoard) => b.order)) : 0
    },

    deleteBoardSuccess(state, action) {
      state.isLoading = false
      const deleteId = action.payload
      state.boards = sortBoards(state.boards.filter((b) => b.id !== deleteId))
    },

    moveBoardSuccess(state, action) {
      const { boardId, newOrder } = action.payload
      const boardIndex = state.boards.findIndex((b) => b.id === boardId)
      if (boardIndex > -1) {
        const board = state.boards[boardIndex]
        const tempBoards = state.boards
        if (newOrder > board.order) {
          state.boards = sortBoards(
            tempBoards.map((b) =>
              b.id === boardId
                ? { ...b, order: newOrder }
                : b.order <= newOrder && b.order > board.order
                ? { ...b, order: b.order - 1 }
                : b
            )
          )
        } else {
          state.boards = sortBoards(
            tempBoards.map((b) =>
              b.id === boardId
                ? { ...b, order: newOrder }
                : b.order >= newOrder && b.order < board.order
                ? { ...b, order: b.order + 1 }
                : b
            )
          )
        }
      }
    },

    getTasksSuccess(state, action) {
      state.isLoading = false
      state.tasks = action.payload
    },

    updateTasksSuccess(state, action) {
      state.isLoading = false
      const { boardId, tasks } = action.payload
      state.tasks[boardId] = tasks
    },

    moveTaskSuccess(state, action) {
      const { taskId, sourceId, boardId, newOrder } = action.payload

      const taskIndex = state.tasks[sourceId].findIndex((t) => t.id === taskId)
      if (taskIndex > -1) {
        const task = state.tasks[sourceId][taskIndex]
        if (boardId === sourceId && newOrder > task.order) {
          state.tasks[sourceId] = sortTasks(
            state.tasks[sourceId].map((t) =>
              t.id === taskId
                ? { ...t, order: newOrder }
                : t.order <= newOrder && t.order > task.order
                ? { ...t, order: t.order - 1 }
                : t
            )
          )
        } else if (boardId === sourceId && newOrder <= task.order) {
          state.tasks[sourceId] = sortTasks(
            state.tasks[sourceId].map((t) =>
              t.id === taskId
                ? { ...t, order: newOrder }
                : t.order >= newOrder && t.order < task.order
                ? { ...t, order: t.order + 1 }
                : t
            )
          )
        } else {
          state.tasks[sourceId] = state.tasks[sourceId].filter((t) => t.id !== taskId)
          state.tasks[boardId] = sortTasks(
            state.tasks[boardId]
              .map((t) => (t.order >= newOrder ? { ...t, order: t.order + 1 } : t))
              .concat([{ ...task, order: newOrder }])
          )
        }
      }
    },
  },
})

export default slice.reducer
export const { actions } = slice

export function getBoards(spaceId: string | number) {
  return async () => {
    dispatch(actions.setLoading(true))
    try {
      const { data: boards } = await TaskService.getBoards(spaceId)

      const tasksPromises = boards
        .map((b: TaskBoard) => b.id)
        .map(async (id: number) => {
          const res = await TaskService.getTasks(id)
          return { id, tasks: sortTasks(res.data) }
        })

      const tasksResult = await Promise.all(tasksPromises)
      const tasksData: Record<number, Task[]> = {}

      tasksResult.forEach(({ id, tasks }) => {
        tasksData[id] = tasks
      })

      dispatch(actions.getTasksSuccess(tasksData))
      dispatch(actions.getBoardsSuccess(boards))
    } catch (error) {
      dispatch(actions.hasFetchError(error.response.data.message))
    }
  }
}

export function createBoard(spaceId: string | number, data: object) {
  return async () => {
    try {
      await TaskService.createBoard(spaceId, data)

      const { data: boards } = await TaskService.getBoards(spaceId)
      const tasksPromises = boards
        .map((b: TaskBoard) => b.id)
        .map(async (id: number) => {
          const res = await TaskService.getTasks(id)
          return { id, tasks: sortTasks(res.data) }
        })

      const tasksResult = await Promise.all(tasksPromises)
      const tasksData: Record<number, Task[]> = {}

      tasksResult.forEach(({ id, tasks }) => {
        tasksData[id] = tasks
      })

      dispatch(actions.getTasksSuccess(tasksData))
      dispatch(actions.getBoardsSuccess(boards))
    } catch (error) {
      dispatch(actions.hasError(error.response.data.message))
    }
  }
}

export function updateBoard(spaceId: string | number, boardId: number, data: object) {
  return async () => {
    try {
      await TaskService.updateBoard(spaceId, boardId, data)

      const { data: boards } = await TaskService.getBoards(spaceId)
      const tasksPromises = boards
        .map((b: TaskBoard) => b.id)
        .map(async (id: number) => {
          const res = await TaskService.getTasks(id)
          return { id, tasks: sortTasks(res.data) }
        })

      const tasksResult = await Promise.all(tasksPromises)
      const tasksData: Record<number, Task[]> = {}

      tasksResult.forEach(({ id, tasks }) => {
        tasksData[id] = tasks
      })

      dispatch(actions.getTasksSuccess(tasksData))
      dispatch(actions.getBoardsSuccess(boards))
    } catch (error) {
      dispatch(actions.hasError(error.response.data.message))
    }
  }
}

export function deleteBoard(spaceId: string | number, boardId: number) {
  return async () => {
    dispatch(actions.setLoading(true))
    try {
      await TaskService.deleteBoard(spaceId, boardId)

      const { data: boards } = await TaskService.getBoards(spaceId)
      const tasksPromises = boards
        .map((b: TaskBoard) => b.id)
        .map(async (id: number) => {
          const res = await TaskService.getTasks(id)
          return { id, tasks: sortTasks(res.data) }
        })

      const tasksResult = await Promise.all(tasksPromises)
      const tasksData: Record<number, Task[]> = {}

      tasksResult.forEach(({ id, tasks }) => {
        tasksData[id] = tasks
      })

      dispatch(actions.getTasksSuccess(tasksData))
      dispatch(actions.getBoardsSuccess(boards))
    } catch (error) {
      dispatch(actions.hasError(error.response.data.message))
    }
  }
}

export function moveBoard(spaceId: string | number, boardId: number, newOrder: number) {
  return async () => {
    try {
      dispatch(actions.setLoading(true))
      dispatch(actions.moveBoardSuccess({ boardId, newOrder }))
      await TaskService.moveBoard(spaceId, boardId, { order: newOrder })
      dispatch(actions.setLoading(false))
    } catch (error) {
      const res = await TaskService.getBoards(spaceId)
      dispatch(actions.getBoardsSuccess(res.data))
      dispatch(actions.hasError(error.response.data.message))
    }
  }
}

// TASKS
export function moveTask({ sourceId, boardId, newOrder, taskId }: MoveTaskProps) {
  return async () => {
    try {
      dispatch(actions.setLoading(true))
      dispatch(actions.moveTaskSuccess({ taskId, sourceId, boardId, newOrder }))
      await TaskService.moveTask(sourceId, taskId, {
        kanban_board_id: boardId,
        order: newOrder,
      })
      dispatch(actions.setLoading(false))
    } catch (error) {
      const sourceRes = await TaskService.getTasks(sourceId)
      const boardRes = await TaskService.getTasks(boardId)
      dispatch(actions.updateTasksSuccess({ boardId: sourceId, tasks: sourceRes.data }))
      dispatch(actions.updateTasksSuccess({ boardId: boardId, tasks: boardRes.data }))
      dispatch(actions.hasError(error.response.data.message))
    }
  }
}

export function createTask(boardId: number, taskData: object) {
  return async () => {
    try {
      dispatch(actions.setLoading(true))
      await TaskService.createTask(boardId, taskData)
      const res = await TaskService.getTasks(boardId)
      dispatch(actions.updateTasksSuccess({ boardId, tasks: res.data }))
    } catch (error) {
      dispatch(actions.hasError(error.response.data.message))
    }
  }
}

export function deleteTask(boardId: number, taskId: number) {
  return async () => {
    try {
      dispatch(actions.setLoading(true))
      await TaskService.deleteTask(boardId, taskId)
      const res = await TaskService.getTasks(boardId)
      dispatch(actions.updateTasksSuccess({ boardId, tasks: res.data }))
    } catch (error) {
      dispatch(actions.hasError(error.response.data.message))
    }
  }
}

export function updateTask(boardId: number, taskId: number, data: object) {
  return async () => {
    try {
      dispatch(actions.setLoading(true))
      await TaskService.updateTask(boardId, taskId, data)
      const res = await TaskService.getTasks(boardId)
      dispatch(actions.updateTasksSuccess({ boardId, tasks: res.data }))
    } catch (error) {
      dispatch(actions.hasError(error.response.data.message))
    }
  }
}
