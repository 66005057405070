import service from './interceptors'

const DepartmentService = {
  getDepartments: function (params?: object) {
    return service({
      url: 'department',
      method: 'get',
      params,
    })
  },

  createDepartment: function (data: object) {
    return service({
      url: 'department',
      method: 'post',
      data,
    })
  },

  specificDepartment: function (id: number) {
    return service({
      url: `department/${id}`,
      method: 'get',
    })
  },

  updateDepartment: function (data: object, id: number) {
    return service({
      url: `department/${id}`,
      method: 'put',
      data,
    })
  },

  deleteDepartment: function (id: number) {
    return service({
      url: `department/${id}`,
      method: 'delete',
    })
  },

  addEmployees: function (id: number, data: object) {
    return service({
      url: `department/${id}/employee`,
      method: 'post',
      data,
    })
  },
}

export default DepartmentService
