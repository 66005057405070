
import { createSlice } from '@reduxjs/toolkit'
import { dispatch } from '../store'
import FormService from 'src/services/FormService'

import {  FormState } from 'src/@types/form'

const initialState: FormState = {
  isLoading: false,
  error: null,
  forms: [],
  total: 0,
}

const slice = createSlice({
  name: 'form',
  initialState,
  reducers: {
    startLoading(state) {
      state.isLoading = true
    },

    hasError(state, action) {
      state.isLoading = false
      state.error = action.payload
    },

    getForms(state, action) {
      state.isLoading = false
      state.forms = action.payload.data
      state.total = action.payload.meta.total
    },
  },
})

export default slice.reducer

// ----------------------------------------------------------------------

export function getForms(params?: object) {
  return async () => {
    dispatch(slice.actions.startLoading())
    try {
      const response = await FormService.getForms(params)
      dispatch(slice.actions.getForms(response))
    } catch (error) {
      dispatch(slice.actions.hasError(error.response.data.message))
    }
  }
}

export function deleteForm(id: string | number, params?: object) {
  return async () => {
    dispatch(slice.actions.startLoading())
    try {
      await FormService.deleteForm(id)
      const response = await FormService.getForms(params)
      dispatch(slice.actions.getForms(response))
    } catch (error) {
      dispatch(slice.actions.hasError(error.response.data.message))
    }
  }
}
