// routes
import Router from './routes'
// theme
import ThemeProvider from './theme'
// components
import { ThemeSettings } from './components/settings'
import ScrollToTop from './components/ScrollToTop'
import { ProgressBarStyle } from './components/ProgressBar'
import MotionLazyContainer from './components/animate/MotionLazyContainer'

import './override.css'
import SnackbarProvider from './components/snackbar/SnackbarProvider'

import { Toaster } from 'sonner'
import ThemeLocalization from './locales/ThemeLocalization'
import 'moment/locale/mn'
import 'moment/locale/en-in'
// ----------------------------------------------------------------------


export default function App() {
  return (
    <MotionLazyContainer>
      <ThemeProvider>
        <ThemeSettings>
          <ThemeLocalization>
            <SnackbarProvider>
              <ProgressBarStyle />
              <ScrollToTop />
              <Router />
              <Toaster position="bottom-center" />
            </SnackbarProvider>
          </ThemeLocalization>
        </ThemeSettings>
      </ThemeProvider>
    </MotionLazyContainer>
  )
}
