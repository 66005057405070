import service from './interceptors'

const LocationService = {
  getLocations: function (params?: object) {
    return service({
      url: `location`,
      method: 'get',
      params,
    })
  },

  updateLocation: function (id: number, data: object) {
    return service({
      url: `location/${id}`,
      method: 'put',
      data,
    })
  },

  addLocation: function (data: object) {
    return service({
      url: `location`,
      method: `post`,
      data,
    })
  },

  removeLocation: function (id: number) {
    return service({
      url: `location/${id}`,
      method: 'delete',
    })
  },
}

export default LocationService
