import { yupResolver } from '@hookform/resolvers/yup'
import { LoadingButton } from '@mui/lab'
import { Alert, Box, Button, Stack, Typography } from '@mui/material'
import React, { useCallback } from 'react'
import { useForm } from 'react-hook-form'
import { FormProvider, RHFTextField, RHFUploadAvatar } from 'src/components/hook-form'
import { CustomFile } from 'src/components/upload'
import { useLocales } from 'src/locales'
import AuthService from 'src/services/AuthService'
import CONSTANTS from 'src/utils/constant'
import * as Yup from 'yup'

type Props = {
  setStep: React.Dispatch<React.SetStateAction<number>>
  setCompanyInfo: React.Dispatch<React.SetStateAction<CompanyInfoValues | null>>
  companyInfo: CompanyInfoValues | null
}

export type CompanyInfoValues = {
  avatar: CustomFile | string | null
  register_code: string
  phone: string
  afterSubmit?: string
}

export default function CompanyInfoForm({ companyInfo, setStep, setCompanyInfo }: Props) {
  const { translate: t } = useLocales()

  const NameSchema = Yup.object().shape({
    register_code: Yup.string()
      .required(`${t('error.enterCompanyRegister')}`)
      .max(10, `${t('error.registerMin10')}`),
    phone: Yup.number()
      .typeError(`${t('error.phoneTypeError')}`)
      .required(`${t('error.enterPhoneNumber')}`),
    avatar: Yup.mixed().test('required', `${t('error.uploadPicture')}`, (value) => value !== ''),
  })

  const methods = useForm<CompanyInfoValues>({
    resolver: yupResolver(NameSchema),
    defaultValues: {
      register_code: companyInfo?.register_code || '',
      avatar: companyInfo?.avatar || '',
      phone: companyInfo?.phone || '',
    },
  })

  const {
    handleSubmit,
    setValue,
    setError,
    formState: { errors, isSubmitting },
  } = methods

  const onSubmit = async (data: CompanyInfoValues) => {
    try {
      const phoneResponse = await AuthService.checkModel({
        type: 0,
        filters: [{ field: 'phone', value: data.phone + '' }],
      })
      if (phoneResponse.data.data.exists) {
        throw Error(`${t('error.existingPhone')}`)
      }

      const registerResponse = await AuthService.checkModel({
        type: 0,
        filters: [{ field: 'register_code', value: data.register_code }],
      })
      if (registerResponse.data.data.exists) {
        throw Error(`${t('error.existingCompanyCode')}`)
      }

      setCompanyInfo(data)
      setStep(3)
    } catch (error) {
      setError('afterSubmit', { ...error, message: error.message })
    }
  }

  const handleDrop = useCallback(
    (acceptedFiles: File[]) => {
      const file = acceptedFiles[0]

      if (file) {
        setValue(
          'avatar',
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          })
        )
      }
    },
    [setValue]
  )

  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      <Stack spacing={3}>
        <Box>
          <Typography variant="h4" sx={{ mb: 1 }}>
            {`${t('companyInfo')}`}
          </Typography>
          <Typography variant="body2" sx={{ color: 'text.secondary' }}>
            {`${t('companyInfoSubheader')}`}
          </Typography>
        </Box>

        <RHFUploadAvatar
          name="avatar"
          maxSize={CONSTANTS.maxSize}
          onDrop={handleDrop}
          helperText={
            <Typography
              variant="caption"
              sx={{
                mt: 2,
                mx: 'auto',
                display: 'block',
                textAlign: 'center',
                color: 'text.secondary',
              }}
            >
              {`${t('fileType')}`}
              <br /> {`${t('maxSize', { size: '1mb' })}`}
            </Typography>
          }
        />

        {!!errors.afterSubmit && <Alert severity="error">{errors.afterSubmit.message}</Alert>}
        <RHFTextField name="register_code" label={`${t('companyRegistry')}`} />
        <RHFTextField name="phone" type="number" label={`${t('phone')}`} />

        <Box sx={{ textAlign: 'right' }}>
          <Button color="inherit" variant="text" sx={{ mr: 1, px: 2 }} onClick={() => setStep(1)}>
            {`${t('back')}`}
          </Button>
          <LoadingButton
            type="submit"
            variant="contained"
            loading={isSubmitting}
            sx={{
              bgcolor: '#1A0289',
              color: '#fff',
              '&:hover': {
                bgcolor: '#1A0289',
                color: '#fff',
              },
            }}
          >
            {`${t('continue')}`}
          </LoadingButton>
        </Box>
      </Stack>
    </FormProvider>
  )
}
