import service from './interceptors'

const CompanyService = {
  getCompanies: function (params?: object) {
    return service({
      url: `companies`,
      method: 'get',
      params,
    })
  },

  updateCompany: function (id: number, data: FormData) {
    return service({
      url: `company/${id}?_method=PUT`,
      method: 'post',
      data,
    })
  },

  removeCompany: function (id: number) {
    return service({
      url: `companies/${id}`,
      method: 'delete',
    })
  },

  addCompany: function (data: FormData) {
    return service({
      url: `companies`,
      method: 'post',
      data,
    })
  },

  updateSettings: function (data: any) {
    return service({
      url: `company/group/settings`,
      method: 'put',
      data,
    })
  },
}

export default CompanyService
