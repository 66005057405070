import {
  styled,
  Popover,
  TextField,
  Chip,
  InputLabel,
  FormControl,
  OutlinedInput,
  FormHelperText,
} from '@mui/material'
import { LocalizationProvider, PickersDay, StaticDatePicker } from '@mui/x-date-pickers'
import moment from 'moment'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { MouseEvent, useState } from 'react'
import { Controller, useFormContext } from 'react-hook-form'

const CustomPickersDay = styled(PickersDay, {
  shouldForwardProp: (prop) => prop !== 'selected',
})(({ theme, selected }) => ({
  ...(selected && {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    '&:hover, &:focus': {
      backgroundColor: theme.palette.primary.dark,
    },
    borderTopLeftRadius: '50%',
    borderBottomLeftRadius: '50%',
    borderTopRightRadius: '50%',
    borderBottomRightRadius: '50%',
  }),
}))

type Props = {
  dates: Date[]
  name: string
}

export default function RHFMultiDatePicker({ dates, name }: Props) {
  const { control } = useFormContext()

  const [openDate, setOpenDate] = useState<HTMLElement | null>(null)

  const renderPickerDay = (date: Date, selectedDates: Date[], pickersDayProps: any) => {
    if (!dates) {
      return <PickersDay {...pickersDayProps} />
    }

    const selected = dates.some((v) => v.getTime() === date.getTime())

    return (
      <CustomPickersDay
        {...pickersDayProps}
        // disableMargin
        selected={selected}
      />
    )
  }

  const handleDateChange = (newValue: Date | null, values: Date[]) => {
    if (newValue) {
      const valueExists = values.some((v) => v.getTime() === newValue.getTime())

      if (valueExists) {
        return values
          .filter((v) => v.getTime() !== newValue.getTime())
          .sort((d1: Date, d2: Date) => d1.getTime() - d2.getTime())
      } else {
        return [...values, newValue].sort((d1: Date, d2: Date) => d1.getTime() - d2.getTime())
      }
    }
  }

  const handleRemove = (val: Date, values: Date[]) => values.filter((v) => v !== val)

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <>
          <FormControl
            variant="outlined"
            fullWidth
            onClick={(evt: MouseEvent<HTMLElement>) => setOpenDate(evt.currentTarget)}
          >
            <InputLabel
              variant="outlined"
              shrink
              size="small"
              filled
              sx={{ bgcolor: 'background.paper' }}
            >
              Ажиллах өдрүүд
            </InputLabel>
            <OutlinedInput
              size="small"
              readOnly
              startAdornment={field.value.map((val: Date, index: number) => (
                <Chip
                  key={index}
                  tabIndex={-1}
                  label={moment(val).format('MM/DD')}
                  sx={{ mr: 1, mb: 0.5, '& + input': { height: 0, padding: 0 } }}
                  onDelete={() => field.onChange(handleRemove(val, field.value))}
                />
              ))}
              sx={{ flexWrap: 'wrap', paddingTop: '8px', paddingBottom: '4px' }}
            />
            <FormHelperText error={!!error}>{error?.message}</FormHelperText>
          </FormControl>

          <Popover
            open={!!openDate}
            anchorEl={openDate}
            onClose={() => setOpenDate(null)}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'center',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'center',
            }}
          >
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <StaticDatePicker
                displayStaticWrapperAs="desktop"
                label="Week picker"
                value={field.value}
                onChange={(newValue) => field.onChange(handleDateChange(newValue, field.value))}
                renderInput={(params) => <TextField {...params} />}
                renderDay={renderPickerDay}
              />
            </LocalizationProvider>
          </Popover>
        </>
      )}
    />
  )
}
