import axios from 'axios'
import { BASE_HOST_API } from 'src/config'

const axiosInstance = axios.create({
  baseURL: BASE_HOST_API,
})

axiosInstance.interceptors.request.use(
  (config: any) => {
    config.params = { ...config.params, system: 1 }
    return config
  },
  (error) => {
    Promise.reject(error)
  }
)

axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => Promise.reject(error.response && error.response.data) || 'Something went wrong.'
)

export default axiosInstance
