import { createSlice } from '@reduxjs/toolkit'
import { dispatch } from '../store'

import { EmployeState } from 'src/@types/employee'
import EmployeeService from 'src/services/EmployeeService'

const initialState: EmployeState = {
  isLoading: false,
  error: null,
  employees: [],
  total: 0,
}

const slice = createSlice({
  name: 'employee',
  initialState,
  reducers: {
    startLoading(state) {
      state.isLoading = true
    },

    hasError(state, action) {
      state.isLoading = false
      state.error = action.payload
    },

    getEmployeesSuccess(state, action) {
      state.isLoading = false
      state.employees = action.payload.data
      state.total = action.payload.meta.total
    },

    removeEmployeeSuccess(state, action) {
      const { id } = action.payload

      state.isLoading = false
      state.employees = state.employees.filter((e) => e.id !== Number(id))
    },

    editEmployeeSuccess(state, action) {
      const updatedEmployee = action.payload.data

      state.isLoading = false
      state.employees = state.employees.map((e) =>
        e.id === updatedEmployee.id ? updatedEmployee : e
      )
    },
  },
})

export default slice.reducer
export const { actions } = slice

// ----------------------------------------------------------------------

export function getEmployees(params?: object) {
  return async () => {
    dispatch(slice.actions.startLoading())
    try {
      const response = await EmployeeService.utilityEmployees(params)
      dispatch(slice.actions.getEmployeesSuccess(response))
    } catch (error) {
      dispatch(slice.actions.hasError(error))
    }
  }
}

export function editEmployee(id: number, params: object) {
  return async () => {
    dispatch(slice.actions.startLoading())
    try {
      const response = await EmployeeService.editEmployee(id, params)
      dispatch(slice.actions.editEmployeeSuccess(response))
    } catch (error) {
      dispatch(slice.actions.hasError(error))
    }
  }
}

export function removeEmployee(id: string) {
  return async () => {
    dispatch(slice.actions.startLoading())
    try {
      await EmployeeService.removeEmployee(id)
      dispatch(slice.actions.removeEmployeeSuccess({ id }))
    } catch (error) {
      dispatch(slice.actions.hasError(error))
    }
  }
}
