const en = {
  company: 'Company',
  companyDetail: {
    name: 'Name',
    website: 'Website',
    phone: 'Phone',
    avatar: 'Avatar',
    subtitle: 'Change company information',
    success: 'Company updated successfully!',
  },
  companySettings: {
    title: 'Settings',
    subtitle: 'Change company settings',
    timezone: 'Timezone',
  },
  passwordSubtitle: 'Change authentication password',
  home: {
    totalDepartments: 'Total departments',
    recruit: {
      title: 'Company code',
      description: 'Company code to add the registered employees to the company.',
    },
    pendingRequests: 'Pending requests',
    attendanceForToday: 'Attendance for today',
    weeklyAttendance: 'Weekly Attendance',
    registered: 'Clocked in',
    nonregistered: 'Clocked out',
  },
  features: {
    home: 'Home',
    settings: 'Settings',
    location: 'Location',
    timeshift: 'Timeshift',
    specialDay: 'Special day',
    account: 'Account',
    payment: 'Payment',
    schedule: 'Schedule',
    attendance: 'Attendance',
    departments: 'Departments',
    employees: 'Employees',
    requests: 'Requests',
    report: 'Report',
    survey: 'Survey',
    announcement: 'Announcement',
    event: 'Event',
    adminLog: 'Admin log',
    thanksBox: 'Thanks box',
    task: 'Tasks',
    invoice: 'Invoice',
  },
  requests: 'Requests',
  noRequests: 'Requests not found',
  created: 'Created',
  details: 'Details',
  decideRequest: 'Request details',
  request: {
    requestType: 'Request type',
    additionalTypes: 'Add new request type',
    createAdditionalType: 'Create additional type',
    editAdditionalType: 'Edit additional type',
    deleteAdditionalType: 'Delete additional type',
    deleteAdditionalConfirm:
      'Are you sure you want to delete <strong>{{name}}</strong> additional type?',
    notFound: 'Request list is empty.',

    status: 'Status',
    start_date: 'Start Date',
    end_date: 'End Date',
    type: 'Type',
    additional: 'Additional',
  },
  paid: 'Paid',
  unpaid: 'Unpaid',
  timeRequestStart: 'Time request - arrive',
  timeRequestEnd: 'Time request - leave',
  timeRequestFull: 'Time request - full',

  // Form Values
  description: 'Description',
  name: 'Name',
  date: 'Date',
  time: 'Time',
  startDate: 'Start date',
  endDate: 'End date',
  startTime: 'Start time',
  endTime: 'End time',
  type: 'Type',
  requestType: 'Request type',
  additional: 'Additional',
  response: 'Response',
  password: 'Password',
  oldPassword: 'Old password',
  newPassword: 'New password',
  passwordConfirmation: 'Confirm password',
  email: 'Email',
  phone: 'Phone',
  role: 'Job role',
  addRole: 'Add job role',
  status: 'Status',
  chooseDates: 'Select dates',
  selectOrder: 'Select admin',
  arriveDate: 'Arrive date',
  arriveTime: 'Arrive time',
  leaveDate: 'Leave date',
  leaveTime: 'Leave time',
  admin: 'Admin',
  firstName: 'First name',
  lastName: 'Last name',
  title: 'Title',

  contrast: 'Contrast',
  direction: 'Direction',
  layout: 'Layout',
  stretch: 'Stretch',
  availableXl: 'Only available at large resolutions > 1600px (xl)',
  fullscreen: 'Fullscreen',
  exit: 'Exit',

  // Common
  my: 'My',
  general: 'General',
  all: 'All',
  pending: 'Pending',
  cancel: 'Cancel',
  close: 'Close',
  accept: 'Accept',
  arrive: 'Clock in',
  leave: 'Clock out',
  plan: 'Plan',
  remove: 'Remove',
  delete: 'Delete',
  edit: 'Edit',
  create: 'Create',
  check: 'Check',
  choose: 'Choose',
  continue: 'Continue',
  back: 'Back',
  checkout: 'Checkout',
  add: 'Add',
  export: 'Export',
  uploadPic: 'Upload picture',
  updatePic: 'Update picture',
  accepted: 'Approved',
  rejected: 'Rejected',
  required: 'Required',
  cancelled: 'Cancelled',
  view: 'View',
  action: 'Action',
  before: 'Before',
  after: 'After',
  changes: 'Changes',
  change: 'Change',
  approve: 'Approve',
  basic: 'Basic',
  qpay: 'QPay',

  active: 'Active',
  inactive: 'Inactive',
  resigned: 'Resigned',
  overview: 'Overview',
  duration: 'Duration',
  available: 'Available',
  unavailable: 'Unavailable',
  week: 'week',
  month: 'month',
  settings: 'Settings',
  search: 'Search',
  color: 'Color',
  code: 'Code',
  newCode: 'New code',
  other: 'Other',
  image: 'Image',
  dragDrop: 'Drag and drop a file', // Файл зөөх эсвэл сонгох
  dragHere: 'Drag and drop your file or click <strong>here</strong> to select.',
  choice: 'Choice',
  try: 'Try',
  exportType: 'Export type',
  download: 'Download',
  selectEmployee: 'Select employee',
  selectDepartment: 'Select department',
  total: 'Total',

  totalEmployees: 'Total employees',
  totalLocations: 'Total locations',
  totalDepartments: 'Total departments',

  // Auth
  logout: 'Logout',
  login: 'Login',
  register: 'Register',
  noAccount: 'No account',
  haveAccount: 'Have an account?',
  atWork: 'At <strong>Work</strong>',
  onTime: 'On <strong>Time</strong>',
  loginSubheader: 'Enter your login information',
  chooseAdmin: 'Choose admin account',
  chooseCompany: 'Choose your company',
  registerSuccess: 'Your account is registered successfully.', // 'Амжилттай бүртгэлээ.'
  registerError: 'There was an error registering your account.', // 'Бүртгэхэд асуудал гарлаа.'
  registerStep: 'Steps',
  welcome: 'Welcome',
  irleInfo:
    'IRLE APP нь ажлын байрны төвөгтэй дэс дарааллыг халж ажилтан болон ажил олгогч нарт цагаа үр ашигтайгаар зарцуулах боломжийг олгож буй байгууллагын систем юм.', // IRLE APP нь ажлын байрны төвөгтэй дэс дарааллыг халж ажилтан болон ажил олгогч нарт цагаа үр ашигтайгаар зарцуулах боломжийг олгож буй байгууллагын систем юм.
  nameQuestion: 'What is your company name?',
  getStarted: 'Get started',
  companyInfo: 'Company Information',
  companyInfoSubheader: 'Company information to be used on IRLE dashboard and application',
  maxSize: 'max size of {{size}}',
  fileType: 'Image file: *.jpeg, *.jpg, *.png, *.gif, *.webp',
  companyRegistry: 'Company registry code',
  loginInfo: 'Login Info',
  loginInfoSubheader: 'Account information to use when you login.',
  termsOfUse: 'Terms of use',
  acceptTerms: 'I accept the terms of use.',
  saveChanges: 'Save Changes',
  verifyMail: 'Verify email',
  verify: 'Verify',
  accountOverview: 'Account overview',
  editDetails: 'Edit your account details',
  accountName: 'Account name',
  profilePic: 'Profile picture',
  clickToUpload: 'Click to upload',
  orDrag: 'or drag and drop',
  fileTypes: 'PNG, JPG or WEBP (max: 1MB)',
  codeSent: 'The verification code has been sent your email. Check your inbox, or spam folder.',
  changePassword: 'Password',
  accountSuccess: 'Account information changed successfully.',
  passwordSuccess: 'Password changed successfully.',
  companySuccess: 'Company updated successfully.',
  settingsSuccess: 'Settings changed successfully.',
  mailExists: 'Email is already in use on another account.',

  // Errors
  error: {
    default: 'Something went wrong!',
    nameRequired: 'Name is required!',
    startDateMore: "Start date can't be after end date",
    daysRangeLimit: 'Days range limit is 90.',
    notFound: 'Not found',
    chooseEmployee: 'Choose employee',
    chooseDepartment: 'Choose department',
    chooseDay: 'Choose a day',
    chooseAdmin: 'Choose admin',
    chooseHeadDepartment: 'Choose head department',
    chooseRole: 'Choose role',
    selectDate: 'No date is selected',
    enterNumber: 'Enter number value',
    enterName: 'Enter name',
    enterDuration: 'Enter duration',
    enterShortName: 'Enter short name',
    endDateAfter: 'End date must be after the start date.',
    invalidEmail: 'Enter valid email.',
    emailRequired: 'Email field is required.',
    oldPasswordRequired: 'Old password is required',
    passwordRequired: 'Password is required',
    passwordMin8: 'Password length must be more than 8.',
    passwordValid:
      'Password must contain minimum of 1 uppercase, lowercase letter and 1 special character',
    passwordConfirmation: 'Passwords do not match.',
    enterEmployeeCount: 'Enter employee count',
    employeeCountLow: 'Employee count is less than number of current employees.',
    signatureRequired: 'Signature is required!',
    errorInvoiceCreate: 'There was an error creating an invoice.',
    activateSubscription: 'There was an error activating the subscription.',
    paymentFailed: 'Payment is failed! Call us: +976 7222 2220',
    paymentPending: 'Payment is in pending state.',
    contractFailed: 'There was an error creating the contract.',
    contractSuccess: 'Contract is created successfully. Check your email inbox!',
    enterCompanyRegister: 'Enter company register.',
    enterFirstName: 'First name is required.',
    enterLastName: 'Last name is required.',
    enterPosition: 'Position field is required',
    enterPhoneNumber: 'Phone number is required.',
    enterResponse: 'Response is required.',
    enterLocation: 'Enter location.',
    uploadPicture: 'Upload picture',
    registerMin10: 'Company register length can not be more than 10.',
    phoneTypeError: 'Phone number should be valid.',

    enterQuestion: 'Question can not be empty.',
    enterAssessmentValue: 'Assessment value is empty.',
    enterChoice: 'Choice can not be empty',
    chooseCategory: 'Choose category',

    enterDescription: 'Description is empty.',
    enterExpireDate: 'Enter expire date.',

    leaveDateAfter: 'Leave date must be after arrive date',
    leaveTimeAfter: 'Leave time must be after arrive time',
    requestType: 'Request type could not be found',

    existingPhone: 'Phone number is already registered',
    existingEmail: 'Email is already registered',
    existingCompanyCode: 'Company code is already registered',

    invalidDate: 'Invalid date value.',
    invalidTime: 'Invalid time value.',
    initialTimeBefore: 'Initial time must be before the start time.',
    repeatEndAfter: 'Repeat end date must be after the end date.',
    enterTemplate: 'Please enter template title',
  },

  createSuccess: '{{name}} created successfully.',
  editSuccess: '{{name}} edited successfully.',
  deleteSuccess: '{{name}} deleted successfully.',
  acceptSuccess: 'Request is accepted successfully',
  rejectSuccess: 'Request is rejected successfully',

  target: {
    title: 'Target',
    all: 'All',
    employee: 'Employee',
    department: 'Department',
    employees: 'Employees',
    departments: 'Departments',
  },

  // Days
  days: {
    mo: 'Mo',
    tu: 'Tu',
    we: 'We',
    th: 'Th',
    fr: 'Fr',
    sa: 'Sa',
    su: 'Su',
    monday: 'Monday',
    tuesday: 'Tuesday',
    wednesday: 'Wednesday',
    thursday: 'Thursday',
    friday: 'Friday',
    saturday: 'Saturday',
    sunday: 'Sunday',
  },

  durations: {
    month: 'Monthly',
    quarter: 'Quarterly',
    yearly: 'Yearly',
    weekly: 'Weekly',
  },

  location: {
    name: 'Location',
    heading: 'Location',
    locations: 'Locations',
    notFound: 'No Locations',
    add: 'Add Location',
    radius: 'Range',
    create: 'Create Location',
    formName: 'Location name',
    latitude: 'Latitude',
    longitude: 'Longitude',
    confirm: 'Are you sure you want to delete location {{name}}?',
    meter: 'meter',

    enterLocation: 'Enter location name.', // Байршлын нэрээ оруулна уу.
    rangePositive: 'Range must be more than 1m',
    enterRange: 'Enter range value.',
    permission: 'Please enable location permission on your browser.',
  },

  timeshift: {
    heading: 'Timeshifts',
    createHeading: 'New Timeshift',
    editHeading: 'Edit Timeshift',
    short: 'Timeshift',
    name: 'Name',
    type: 'Type',
    target: 'Target',
    location: 'Check location',
    daily: 'Daily',
    shift: 'Shift',
    free: 'Flexible',
    duration: 'Duration',
    breakTime: 'Break time',
    checkLocation: 'Check location',
    dailyDuration: 'Daily work duration',
    flexibleInfo:
      'Чөлөөт цагийн хуваарь нь тогтсон ирэх, явах цаг байхгүй ба хоцролт, илүү цаг бодогдохгүй.',
    workDuration: 'Work duration',
    moveConfirm: 'Note that daily shifts within same day will also move.', // Энэ өдрийн бүх тогтмол цагууд адил хөдлөхийг анхаарна уу.
  },

  specialDay: {
    meta: 'Settings: Special Day',
    heading: 'Special Day',
    edit: 'Edit Day',
    create: 'Add Day',
  },

  account: {
    meta: 'Settings: Account',
    heading: 'Account Settings',
  },

  subscription: {
    meta: 'Subscription',
    choosePlan: 'Choose Plan',
    checkout: 'Checkout',
    payment: 'Payment',

    info: 'You have a <strong>{{plan}}</strong> plan ending on <strong>{{date}}</strong>',
    employeeCount: 'Employee count',
    trial: 'Start free 1 month trial',
    employee: 'employee',
    promo: 'Promo code',
    available: 'Available code',
    unavailable: 'Unavailable code',
    total: 'Total',

    plan: 'Plan',
    price: 'Price',
    initialPrice: 'Initial Price',
    tax: 'Tax',
    totalPrice: 'Total Price',

    trialLoading: 'Processing trial plan',
    trialSuccess: 'Trial plan is created successfully.',
    transferInfo: 'Make sure to write invoice number on bank transfer description.',
    bankTransfer: 'Bank transfer',

    bank: 'Bank',
    tdb: 'Trade Development Bank',
    bankAccount: 'Bank account',
    faroLimited: 'Faro Limited Group',
    accountNumber: 'Account number',
    transferDescription: 'Transfer description',

    contractWait:
      'It takes about <strong>1 minute</strong> to process the contract. So, please wait.', // Гэрээг үүсгэхэд <strong>1 минут</strong> хүртэлх хугацаа шаардагддаг тул хэсэг хугацаанд хүлээнэ үү!
  },

  schedule: {
    calendarHeading: 'Time Schedule',
    filterType: 'Filter Type',
    templates: 'Templates',
    template: 'Template',
    clear: 'Clear',
    apply: 'Apply',
    chooseEmployee: 'Choose employee',
    chooseDepartment: 'Choose department',
    daily: 'Fixed shift',
    shift: 'Rotating shift',
    initialTime: 'Flexible clock in time',
    initialInfo: 'Time when employees can start their work for flexible work hours.',
    breakStart: 'Break start time',
    breakEnd: 'Break end time',
    repeatEnd: 'Repeat end date',
    repeatDuration: 'Repeat duration',
    create: 'Create shift',
    edit: 'Edit shift',
    delete: 'Delete shift',
    repeat: 'Repeat',
    noRepeat: 'No repeat',
    repeatWeekly: 'Weekly',
    deleteDailyInfo: 'This action will delete every recurring daily timeshift on this day',
    updateDailyInfo: 'This action will  update every recurring daily timeshift on this day',
    deleteShiftInfo: 'Choose which type of shifts would you like to delete',
    updateShiftOnfo: 'Choose which type of shifts would you like to update',
    singleConfirm: 'Only this timeshift',
    recurringConfirm: 'Recurring timeshifts',

    newTemplate: 'New template',
    applyTemplate: 'Apply template',
    removeTemplate: 'Remove template',
    applyConfirmation:
      'Are you sure you want to apply <strong>{{template}}</strong> template to every selected employees?',
    deleteConfirmation: 'Are you sure you want to delete <strong>{{template}}</strong> template?',

    clearHeading: 'Clear timeshifts',
    clearInfo:
      'This action will delete every timeshifts on the selected date and employees. Note that repeated daily timeshifts will also be deleted.',
    clearTypes: 'Timeshift types',
    applied: 'Applied',
    unapplied: 'Unapplied',
    all: 'All',
    selectEmployee: 'Select employee',

    applySuccess: 'The timeshift was successfully applied.',
    templateDeleted: 'Template deleted successfully.',
    clearSuccess: 'Schedule is cleared successfully.',

    startDate: 'Clock in date',
    endDate: 'Clock out date',
    startTime: 'Clock in time',
    endTime: 'Clock out time',
  },

  calendarAtt: {
    meta: 'Schedule | Attendance',
    heading: 'Attendance',
    scheduled: 'Hours to work',
    totalWork: 'Total hours',
    overtime: 'Overtime',
    cut: 'Absent',
    totalNonWork: 'Work hours left',
    salaryLeave: 'Paid leave',
    nonSalaryLeave: 'Unpaid leave',
    overtimeLeave: 'Compensatory leave',
    vacation: 'Annual leave',
    specialDay: 'Public holiday',
    attendances: 'Attendances',
    add: 'Add attendance',
    addAttendance: 'Add attendance', // Ирц бүртгэх
    notFound: 'No data',
    export: 'Export attentance report',
    exportDuration: 'Selected dates',
  },

  department: {
    heading: 'Departments',
    short: 'Department',
    edit: 'Edit department',
    create: 'Create department',
    delete: 'Delete department',
    shortName: 'Short name',
    headDepartment: 'Head department',
    admins: 'Admins',
    deleteConfirm: 'Are you sure you want to delete <strong>{{name}}</strong> department?',
  },

  employee: {
    search: 'Employee, phone',
    makeAdmin: 'Make admin',
    removeAdmin: 'Remove admin',
    assessment: 'Assessment',
    changePhone: 'Change phone',
    makeAdminConfirm: 'Are you sure you want to make <strong>{{name}}</strong> employee an admin?',
    removeAdminConfirm:
      'Are you sure you want to remove <strong>{{name}}</strong> employee from the admin role?',
    edit: 'Edit employee information',
    create: 'Add employee',
    joinDate: 'Date of employment',
    insuranceDuration: 'Total months paid for insurance',
    phoneInfo: 'Enter an unregistered phone number.', // Байгууллагад бүртгэлгүй утасны дугаар оруулна уу.
    changeCode: 'Change phone code',
    removeConfirm:
      'Are you sure you want to remove employee <strong>{{name}}</strong> from company?',
    assessmentMeta: 'Employee: Assessment',
    kpi: 'KPI value',
    totalRating: 'Total rating',
    assessmentCount: 'Assessments count',
    averageRating: 'Average rating',
    recieved: 'Recieving',
    assessed: 'Assessing',
  },

  attendance: {
    heading: 'Attendance',
    daily: 'By Date',
    employee: 'By Employee',
    report: 'Report (xlsx)',
    dailyHeading: 'Daily attendance',
    employeeHeading: 'Attendance by employee',
    reportHeading: 'Attendance report',
    create: 'Create attendance',
    edit: 'Edit attendance',
    delete: 'Delete attendance',
    deleteConfirm:
      'Are you sure you want to delete an attendance of date <strong>{{date}}</strong>',
    requestDuration: 'Request duration',
  },

  objectTypes: {
    leave: 'Leave',
    overtime: 'Overtime',
    vacation: 'Annual leave',
    overtimeLeave: 'Compensatory leave',
    businessTrip: 'Business trip',
    offsite: 'Work remotely',
    timeRequest: 'Time request',
    longTerm: 'Long-term leave',
    document: 'Verification letter',
    all: 'All',
  },

  report: {
    heading: 'Report',
    overview: 'Overview',
    detailed: 'Detailed',
    date: 'Date',
    created: 'Created at',
    description: 'Description',
  },

  survey: {
    heading: 'Survey',
    short: 'Survey',
    category: 'Category',
    duration: 'Duration',
    responses: 'Responses',
    target: 'Target',
    expireDate: 'Expire date',
    expireTime: 'Expire time',
    created: 'Created',
    normal: 'Survey',
    assessment: 'Assessment',
    overview: 'Overview',
    detailed: 'Detailed',
    employeeNames: 'Employee names',
    noResponses: 'No responses',
    create: 'Create Survey',
    edit: 'Edit Survey',
    min: 'min',
    questions: 'Questions',
    question: 'Question',
    assessmentVal: 'Assessment value',
    text: 'Text',
    choice: 'Multiple choice',
    checkbox: 'Checkbox',
    anonymous: 'Anonymous',
    addCategory: 'Add Category',
    assessmentInfo:
      'When making <strong>assessment</strong> survey, <strong>multiple choice</strong> questions will only take <strong>number</strong> value.',
    assessmentTarget: 'Assessment survey can be created without selected target employee.',

    editTitle: 'Are you sure you want to edit this survey?',
    editBody: "You have unsaved questions! Press '✓' button to save your changes.",

    deleteTitle: 'Are you sure you want to delete this question?',
    deleteBody: 'Note: every responses to this question will also be deleted.',
    deleteConfirmation: 'Are you sure you want to delete <strong>{{name}}</strong> survey?',
  },

  announcement: {
    heading: 'Announcement',
    announcements: 'Announcements',
    create: 'Create announcement',
    createConfirm: 'Are you sure you want to create announcement <strong>{{name}}</strong>?',
    createBody: 'Note that you will not be able to edit the announcement after it is sent.', // Үүсгэсэн мэдэгдлийг засах боломжгүйг анхаарна уу.
    deleteConfirm: 'Are you sure you want to delete announcement <strong>{{name}}</strong>?',
  },

  event: {
    heading: 'Events',
    short: 'Event',
    create: 'Create event',
    edit: 'Edit event',
    location: 'Location',
    deleteConfirm: 'Are you sure want to delete <strong>{{name}}</strong> event?',
  },

  logs: {
    heading: 'Admin logs',
  },

  thanksBox: {
    heading: 'Thanks Box',
    empty: 'Thanks box is empty.',
    from: 'From',
    to: 'To',
    created: 'Created',
    thanks: 'Thanks',
  },

  task: {
    heading: 'Tasks',
    short: 'Task',
    space: 'Task space',
    board: 'Task board',

    addSpace: 'Add space',
    createSpace: 'Create Task space',
    editSpace: 'Edit Task space',
    deleteSpace: 'Delete Task space',
    spaceConfirmation: 'Are you sure you want to delete <strong>{{name}}</strong> task space?',

    addBoard: 'Add board',
    editBoard: 'Edit task board',
    deleteBoard: 'Delete task board',
    boardConfirmation: 'Are you sure you want to delete <strong>{{name}}</strong> task board?',

    deleteTask: 'Delete task',
    taskConfirmation: 'Are you sure you want to delete <strong>{{name}}</strong> task?',
    addTask: 'Add task',
    taskName: 'Task name',
    description: 'Description',
    assignees: 'Assignees',
    assigned: 'assigned',
    assign: 'assign',

    dueDate: 'Due date',
    addDueDate: 'Add due date',
    applyDate: 'Apply',
    selectDate: 'Select date',
    save: 'Save',

    subtasks: 'Sub tasks',
    taskChanged: 'Task changed successfully.',
    enterTask: 'Enter task name',
  },

  nav: {
    hello: 'Hello, {{name}}',
    needHelp: 'Need help?', // Тусламж хэрэгтэй юу?
  },

  tablet: {
    registerAtt: 'Register attendance',
    noPermission: 'You dont have permission to access this.',
    enterPhone: 'Enter phone number',
    phoneDescription: 'Enter your registered phone number to find your employee account.',
  },

  invoice: {
    heading: 'Invoice',
    number: 'Invoice number',
    method: 'Method',
    trial: 'Trial',
    employeeCount: 'Employee count',
    duration: 'Duration',
    download: 'Download invoice',
    notFound: 'Invoice list is empty.',
    total: 'Total',
  },

  verification: {
    sendAlert: 'Verification code is sent to <strong>{{email}}</strong>.',
    reload: 'Please try again after <strong>{{countdown}}</strong> seconds later!',
    code: 'Verification code',
  },
  alert: {
    location: 'Drag the red marker to your desired location.',
  },
  requestSettings: {
    type: 'Type',
    policy: 'Policy',
    policyTitle: 'Add new policy',
    adminOrder: 'Admin approval order',
    adminAdd: 'Add Admin',
    adminInfo: 'Admins will approve requests from low to high order.',
    objectTypeRequired: 'Request type is required.',
  },
  defaultCreateSuccess: 'Successfully created.',
  defaultCreateError: 'There has been an error.',
  defaultDeleteSuccess: 'Successfully deleted.',
  defaultDeleteError: 'There has been an error.',
}

export default en
