import moment from 'moment'

export function getWeekStringDates(start: Date, end: Date): string[] {
  const dates = []
  let currentDate = moment(start)
  let limitDate = moment(end).endOf('day').toDate() // MM-DD 01:00 -> MM:DD 23:59

  while (currentDate.isSameOrBefore(moment(limitDate))) {
    dates.push(moment(currentDate.format('YYYY-MM-DD')).format('YYYY-MM-DD'))
    currentDate = moment(currentDate).add(1, 'days')
  }

  return dates
}

export default function getDates(
  startDate: Date,
  endDate: Date,
  format: string = 'date'
): (Date | string)[] {
  const dates = []
  let currentDate = moment(startDate)
  let limitDate = moment(endDate).endOf('day').toDate() // MM-DD 01:00 -> MM:DD 23:59

  while (currentDate.isSameOrBefore(moment(limitDate))) {
    if (format === 'string') {
      dates.push(moment(currentDate.format('YYYY-MM-DD')).format('YYYY-MM-DD'))
    }
    if (format === 'date') {
      dates.push(moment(currentDate.format('YYYY-MM-DD')).toDate())
    }
    currentDate = moment(currentDate).add(1, 'days')
  }

  return dates
}

export function getMonthDates(start: Date, end: Date): Date[] {
  const dates = []
  const startOfMonth = moment(start).startOf('month').toDate()
  const endOfMonth = moment(end).endOf('month').toDate()

  let currentDate = moment(startOfMonth).startOf('isoWeek')
  let limitDate = moment(endOfMonth).endOf('isoWeek')

  while (currentDate.isSameOrBefore(moment(limitDate))) {
    dates.push(moment(currentDate.format('YYYY-MM-DD')).toDate())
    currentDate = moment(currentDate).add(1, 'days')
  }

  return dates
}

export function getDayIndex(date: Date | string) {
  const momentDayIndex = moment(date).day()

  if (momentDayIndex === 0) {
    return 6
  }
  return momentDayIndex - 1
}

export function convertHourStringToDate(dateInput: string, time: string): Date {
  const date = dateInput.substring(0, 10)
  return moment(`${date} ${time}`).toDate()
}
