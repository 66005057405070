import baseAxios from 'src/utils/baseAxios'
import axios from 'axios'
import { HOST_API, BASE_HOST_API } from 'src/config'
import service from './interceptors'
import localStorageAvailable from 'src/utils/localStorageAvailable'
import { allLangs, defaultLang } from 'src/locales'

const AuthService = {
  login: function (data: object) {
    return service({
      url: 'login',
      method: 'post',
      data,
    })
  },

  self: function () {
    return service({
      url: 'self',
      method: 'get',
    })
  },

  editProfile: function (data: object) {
    return service({
      url: 'action/update',
      method: 'post',
      data,
    })
  },

  changePassword: function (data: object) {
    return service({
      url: 'change_password',
      method: 'post',
      data,
    })
  },

  checkModel: function (data: object) {
    return axios.post(`${HOST_API}utility/check`, data)
  },

  sendVerificaitonEmail: function (data: object) {
    return baseAxios({
      url: 'user/auth/email/send',
      method: 'post',
      data,
    })
  },

  verifyEmail: function (data: object) {
    return baseAxios({
      url: 'user/auth/email/verify',
      method: 'post',
      data,
    })
  },

  updatePassword: function (data: object) {
    const mainToken = localStorage.getItem('mainAccessToken')
    const storageAvailable = localStorageAvailable()
    const langStorage = storageAvailable ? localStorage.getItem('i18nextLng') : ''

    const currentLang = allLangs.find((_lang) => _lang.value === langStorage) || defaultLang

    return axios.put(`${BASE_HOST_API}user/auth/password`, data, {
      headers: {
        Authorization: `Bearer ${mainToken}`,
      },
      params: { locale: currentLang.locale },
    })
  },
}

export default AuthService
