import baseAxios from 'src/utils/baseAxios'
import service from './interceptors'

const RegisterService = {
  checkPromo: function (code: string) {
    return service({
      url: 'promo/check',
      method: 'GET',
      params: {
        promo_code: code,
      },
    })
  },

  registerCompany: function (data: FormData) {
    return baseAxios({
      url: 'organization/registration',
      method: 'POST',
      data,
    })
  },
}

export default RegisterService
