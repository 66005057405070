import { useTheme, Box, Typography, Tooltip } from '@mui/material'
import { merge } from 'lodash'
import { useState } from 'react'
import ReactApexChart from 'react-apexcharts'
import { IconButtonAnimate } from 'src/components/animate'
import BaseOptionChart from 'src/components/chart/BaseOptionChart'
import Iconify from 'src/components/Iconify'
import MenuPopover from 'src/components/MenuPopover'
import useAuth from 'src/hooks/useAuth'
import { useLocales } from 'src/locales'
import { fShortenNumber } from 'src/utils/formatNumber'

export default function EmployeeProgress() {
  const { translate: t } = useLocales()
  const theme = useTheme()
  const chartColors = [[theme.palette.primary.light, theme.palette.primary.main]]

  const { user } = useAuth()

  const [open, setOpen] = useState<HTMLElement | null>(null)

  const handleOpen = (event: React.MouseEvent<HTMLElement>) => {
    setOpen(event.currentTarget)
  }

  const handleClose = () => {
    setOpen(null)
  }

  const chartOptionsCheckIn = merge(BaseOptionChart(), {
    grid: {
      padding: {
        top: -9,
        bottom: -9,
      },
    },
    fill: {
      type: 'gradient',
      gradient: {
        colorStops: chartColors.map((colors) => [
          { offset: 0, color: colors[0] },
          { offset: 100, color: colors[1] },
        ]),
      },
    },
    legend: { show: false },
    plotOptions: {
      radialBar: {
        hollow: { size: '64%' },
        track: { margin: 0 },
        dataLabels: {
          name: { offsetY: -16 },
          value: { offsetY: 8 },
          total: {
            label: `${t('target.employees')}`,
            formatter: () =>
              `${
                user
                  ? `${fShortenNumber(user.company.employees)}/${fShortenNumber(
                      user.company.active_subscription.employee_count
                    )}`
                  : '0/0'
              }`,
          },
        },
      },
    },
  })

  return (
    <>
      <IconButtonAnimate color="primary" onClick={handleOpen} sx={{ width: 40, height: 40 }}>
        <Tooltip title={`${t('subscription.employeeCount')}`}>
          <>
            <Iconify icon="ic:baseline-info" />
          </>
        </Tooltip>
      </IconButtonAnimate>

      <MenuPopover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleClose}
        sx={{ width: 250, p: 0, mt: 1.5, ml: 0.75 }}
      >
        <Box sx={{ display: 'flex', justifyContent: 'center', pt: 2, px: 2.5 }}>
          <Typography variant="subtitle2" sx={{ fontWeight: 700 }}>
            {`${t('subscription.employeeCount')}`}
          </Typography>
        </Box>

        <Box sx={{ px: 2.5, pb: 2 }}>
          <ReactApexChart
            type="radialBar"
            series={[
              user
                ? (user.company.employees / user.company.active_subscription.employee_count) * 100
                : 0,
            ]}
            options={chartOptionsCheckIn}
            height={250}
          />
        </Box>
      </MenuPopover>
    </>
  )
}
