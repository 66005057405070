import { createSlice } from '@reduxjs/toolkit'
import { RoleState } from 'src/@types/team'
import axios from 'src/utils/axios'
import { dispatch } from '../store'

const initialState: RoleState = {
  isLoading: false,
  roles: [],
  error: null,
}

const slice = createSlice({
  name: 'team',
  initialState,
  reducers: {
    startLoading(state) {
      state.isLoading = true
    },

    hasError(state, action) {
      state.isLoading = false
      state.error = action.payload
    },

    getRolesSuccess(state, action) {
      state.isLoading = false
      state.roles = action.payload.data
    },

    addRoleSuccess(state, action) {
      state.isLoading = false
      state.roles = [...state.roles, action.payload]
    },
  },
})

export default slice.reducer
export const { actions } = slice

// ----------------------------------------------------------------------
export function getRoles(params?: object) {
  return async () => {
    dispatch(slice.actions.startLoading())
    try {
      const response = await axios.get('roles')
      dispatch(slice.actions.getRolesSuccess(response.data))
    } catch (error) {
      dispatch(slice.actions.hasError(error))
    }
  }
}

export function addRole(data: object) {
  return async () => {
    dispatch(slice.actions.startLoading())
    try {
      await axios.post('roles', data)
      // might not use this
      // dispatch(slice.actions.addRoleSuccess(response.data.data))
    } catch (error) {
      dispatch(slice.actions.hasError(error))
    }
  }
}
