import { createSlice } from '@reduxjs/toolkit'
import { TeamState } from 'src/@types/team'
import axios from 'src/utils/axios'
import { dispatch } from '../store'

const initialState: TeamState = {
  isLoading: false,
  error: null,
  teams: [],
}

const slice = createSlice({
  name: 'team',
  initialState,
  reducers: {
    startLoading(state) {
      state.isLoading = true
    },

    hasError(state, action) {
      state.isLoading = false
      state.error = action.payload
    },

    getTeamsSuccess(state, action) {
      state.isLoading = false
      state.teams = action.payload.data
    },

    addTeamSuccess(state, action) {
      state.isLoading = false
      state.teams = [...state.teams, action.payload.data]
    },
  },
})

export default slice.reducer
export const { actions } = slice

// ----------------------------------------------------------------------

type FetchParams = {
  company: number
}

type AddParams = {
  name: string
  company_id: number
}

export function getTeams(params: FetchParams) {
  return async () => {
    dispatch(slice.actions.startLoading())
    try {
      const response = await axios.get('teams', { params: params })
      dispatch(slice.actions.getTeamsSuccess(response.data))
    } catch (error) {
      dispatch(slice.actions.hasError(error))
    }
  }
}

export function addTeam(params: AddParams) {
  return async () => {
    dispatch(slice.actions.startLoading())
    try {
      const response = await axios.post('teams', params)
      dispatch(slice.actions.addTeamSuccess(response.data))
    } catch (error) {
      dispatch(slice.actions.hasError(error))
    }
  }
}
