import { ReactNode } from 'react'
import { Navigate } from 'react-router'
import LoadingScreen from 'src/components/LoadingScreen'
import useAuth from 'src/hooks/useAuth'
import { PATH_DASHBOARD } from 'src/routes/paths'

type GuestGuardProps = {
  children: ReactNode
}

export default function GuestGuard({ children }: GuestGuardProps) {
  const { isAuthenticated, isInitialized } = useAuth()

  if (isAuthenticated) {
    return <Navigate to={PATH_DASHBOARD.root} />
  }

  if (!isInitialized) {
    return <LoadingScreen />
  }

  return <>{children}</>
}
