import { createSlice, Dispatch } from '@reduxjs/toolkit'
import moment from 'moment'
import { toast } from 'sonner'
import { ShiftState, ShiftItem, CalendarView, WeekShift, ShiftTemplate } from 'src/@types/shift'
import TimeshiftService from 'src/services/TimeshiftService'
import { getDayIndex, getMonthDates, getWeekStringDates } from 'src/utils/getDates'
import { dispatch } from '../store'
import { MoveParams } from 'src/sections/custom-calendar/week/CalendarWeekView'
import { TIMESHIFT_TYPE_DAILY, TIMESHIFT_TYPE_NORMAL } from 'src/utils/constant'

const initialState: ShiftState = {
  monthShifts: [],
  weekShifts: [],
  isLoading: false,
  error: null,
  success: null,
  date: new Date(),
  view: 'week',
  weekArray: getWeekStringDates(
    moment().startOf('isoWeek').toDate(),
    moment().endOf('isoWeek').toDate()
  ),
  monthArray: getMonthDates(moment().toDate(), moment().toDate()),
  selectedTemplate: null,
  templateShifts: [],
  publishCount: 0,
}

const slice = createSlice({
  name: 'shift',
  initialState,
  reducers: {
    startLoading(state) {
      state.isLoading = true
    },
    endLoading(state) {
      state.isLoading = false
    },

    hasError(state, action) {
      state.isLoading = false
      state.error = action.payload
    },

    setSuccess(state, action) {
      state.success = action.payload
    },

    getWeekShiftsSuccess(state, action) {
      state.isLoading = false
      state.weekShifts = action.payload
    },

    getMonthShiftsSuccess(state, action) {
      state.isLoading = false
      state.monthShifts = action.payload
    },

    moveWeekShiftSuccess(state, action) {
      const { sourceItem, destinationEmployee, sourceEmployee, destinationDate } = action.payload
      const sourceIndex = state.weekShifts.findIndex((s) => s.id === sourceEmployee)
      const destinationIndex = state.weekShifts.findIndex((s) => s.id === destinationEmployee)

      if (sourceIndex > -1 && destinationIndex > -1) {
        const itemIndex = state.weekShifts[sourceIndex].shifts.findIndex(
          (i) => i.id === sourceItem.id
        )
        if (itemIndex > -1) {
          state.weekShifts[sourceIndex].shifts.splice(itemIndex, 1)

          const updatedStart =
            moment(destinationDate).format('YYYY-MM-DD') + sourceItem.start_date.substring(10, 18)
          const updatedEnd =
            moment(destinationDate).format('YYYY-MM-DD') + sourceItem.end_date.substring(10, 18)
          const updatedItem = { ...sourceItem, start_date: updatedStart, end_date: updatedEnd }

          state.weekShifts[destinationIndex].shifts.push(updatedItem)
        }
      }
    },

    moveMonthShiftSuccess(state, action) {
      const { shiftId, destinationDate } = action.payload
      const shiftIndex = state.monthShifts.findIndex((s) => s.id === shiftId)

      if (shiftIndex > -1) {
        const shiftItem = state.monthShifts[shiftIndex]
        if (shiftItem.type === TIMESHIFT_TYPE_NORMAL) {
          state.monthShifts[shiftIndex].start_date =
            moment(destinationDate).format('YYYY-MM-DD') + shiftItem.start_date.substring(10, 18)
          state.monthShifts[shiftIndex].end_date =
            moment(destinationDate).format('YYYY-MM-DD') + shiftItem.end_date.substring(10, 18)
        }

        if (shiftItem.type === TIMESHIFT_TYPE_DAILY) {
          state.monthShifts[shiftIndex].day_index = getDayIndex(destinationDate)
        }
      }
    },

    updateWeekShiftsSuccess(state, action) {
      const { newShifts } = action.payload
      const updatedShifts = state.weekShifts.map((shift) => {
        const newShift = newShifts.find((s: ShiftItem) => s.id === shift.id)
        if (newShift) {
          return newShift
        }
        return shift
      })
      state.weekShifts = updatedShifts
      state.isLoading = false
    },

    changeViewSuccess(state, action) {
      state.view = action.payload.view
    },

    changeDateSuccess(state, action) {
      state.date = action.payload.date
      state.weekArray = getWeekStringDates(
        moment(state.date).startOf('isoWeek').toDate(),
        moment(state.date).endOf('isoWeek').toDate()
      )
      state.monthArray = getMonthDates(
        moment(state.date).startOf('month').toDate(),
        moment(state.date).endOf('month').toDate()
      )
    },

    changeSelectedTemplateSuccess(state, action) {
      state.selectedTemplate = action.payload.template
    },

    changeTemplateShiftsSuccess(state, action) {
      state.templateShifts = action.payload
    },

    changePublishCount(state, action) {
      state.publishCount = action.payload
    },
  },
})

export default slice.reducer

export const { actions } = slice

export function getWeekShifts(params?: object) {
  return async () => {
    dispatch(slice.actions.startLoading())
    try {
      const response = await TimeshiftService.getEmployeeShifts(params)
      dispatch(slice.actions.getWeekShiftsSuccess(response.data))
      dispatch(slice.actions.changePublishCount(response.publish_count))
    } catch (error) {
      dispatch(slice.actions.hasError(error))
    }
  }
}

export function getMonthShifts(params?: object) {
  return async () => {
    dispatch(slice.actions.startLoading())
    if (params) {
      try {
        const response = await TimeshiftService.getEmployeeMonthlyShifts(params)
        dispatch(slice.actions.getMonthShiftsSuccess(response.data))
      } catch (error) {
        dispatch(slice.actions.hasError(error))
      }
    }
  }
}

export function moveWeekShift(
  sourceItem: ShiftItem,
  sourceEmployee: number,
  destinationEmployee: number,
  destinationDate: string,
  itemType: number,
  sourceType: number,
  moveShift: (params: MoveParams) => Promise<void>
) {
  return async () => {
    dispatch(slice.actions.startLoading())
    dispatch(
      slice.actions.moveWeekShiftSuccess({
        sourceItem,
        destinationEmployee,
        sourceEmployee,
        destinationDate,
      })
    )
    toast.promise(
      moveShift({
        sourceType,
        sourceItem,
        destinationEmployee,
        sourceEmployee,
        destinationDate,
        itemType,
      }),
      {
        loading: 'Түр хүлээнэ үү...',
        success: 'Хуваарь амжилттай зөөгдлөө.',
        error: 'Хуваарийг зөөхөд алдаа гарлаа.',
      }
    )
    // dispatch(slice.actions.endLoading())
  }
}

export function moveMonthShift(
  shiftId: number,
  destinationDate: string,
  employeeId: number,
  moveShift: (id: number, employeeId: number, destinationDate: string) => Promise<void>
) {
  return async () => {
    dispatch(slice.actions.startLoading())
    dispatch(slice.actions.moveMonthShiftSuccess({ shiftId, destinationDate }))
    toast.promise(moveShift(shiftId, employeeId, destinationDate), {
      loading: 'Түр хүлээнэ үү...',
      success: 'Хуваарь амжилттай зөөгдлөө.',
      error: 'Хуваарийг зөөхөд алдаа гарлаа.',
    })
  }
}

export function updateWeekShifts(newShifts: WeekShift[], publishCount: number) {
  return (dispatch: Dispatch) => {
    dispatch(slice.actions.updateWeekShiftsSuccess({ newShifts }))
    dispatch(slice.actions.changePublishCount(publishCount))
  }
}

export function changeView(view: CalendarView) {
  return (dispatch: Dispatch) => {
    dispatch(slice.actions.changeViewSuccess({ view }))
  }
}

export function changeDate(date: Date) {
  return (dispatch: Dispatch) => {
    dispatch(slice.actions.changeDateSuccess({ date }))
  }
}

export function changeSelectedTemplate(template: null | ShiftTemplate) {
  return (dispatch: Dispatch) => {
    dispatch(slice.actions.changeSelectedTemplateSuccess({ template }))
  }
}

export function changeTemplateShifts(items: ShiftItem[]) {
  return (dispatch: Dispatch) => {
    dispatch(slice.actions.changeTemplateShiftsSuccess(items))
  }
}

export function setLoading(state: boolean) {
  return (dispatch: Dispatch) => {
    if (state) {
      dispatch(slice.actions.startLoading())
    } else {
      dispatch(slice.actions.endLoading())
    }
  }
}
