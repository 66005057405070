import { yupResolver } from '@hookform/resolvers/yup'
import { LoadingButton } from '@mui/lab'
import { Box, Stack, Typography } from '@mui/material'
import React from 'react'
import { useForm } from 'react-hook-form'
import { FormProvider, RHFTextField } from 'src/components/hook-form'
import { useLocales } from 'src/locales'
import * as Yup from 'yup'

type Props = {
  setStep: React.Dispatch<React.SetStateAction<number>>
  setCompanyName: React.Dispatch<React.SetStateAction<string>>
  companyName: string
}

type NameFormValues = {
  name: string
}

export default function NameForm({ companyName, setStep, setCompanyName }: Props) {
  const { translate: t } = useLocales()
  const NameSchema = Yup.object().shape({
    name: Yup.string().required(`${t('error.enterName')}`),
  })

  const methods = useForm({
    resolver: yupResolver(NameSchema),
    defaultValues: {
      name: companyName,
    },
  })

  const {
    handleSubmit,
    formState: { isSubmitting },
  } = methods

  const onSubmit = (data: NameFormValues) => {
    setCompanyName(data.name)
    setStep(2)
  }

  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      <Stack spacing={3}>
        <Box>
          <Typography variant="h3" sx={{ mb: 1 }}>
            {`${t('welcome')}.`}
          </Typography>
          <Typography variant="body2" sx={{ color: 'text.secondary' }}>
            {`${t('irleInfo')}`}
          </Typography>
        </Box>
        <Typography variant="subtitle1">{`${t('nameQuestion')}`}</Typography>
        <RHFTextField name="name" label={`${t('name')}`} />
        <Box sx={{ textAlign: 'right' }}>
          <LoadingButton
            type="submit"
            variant="contained"
            loading={isSubmitting}
            sx={{
              bgcolor: '#1A0289',
              color: '#fff',
              '&:hover': {
                bgcolor: '#1A0289',
                color: '#fff',
              },
            }}
          >
            {`${t('getStarted')}`}
          </LoadingButton>
        </Box>
      </Stack>
    </FormProvider>
  )
}
