import service from "./interceptors"

const DashboardService = {
  getBlocks: function () {
    return service({
      url: 'dashboard/statistics/block',
      method: 'get',
    })
  },

  getRecruitCode: function (companyId: string | number) {
    return service({
      url: `recruit_codes/${companyId}`,
      method: 'get',
    })
  },

  newRecruitCode: function (companyId: string | number) {
    return service({
      url: `recruit_codes/${companyId}`,
      method: 'post',
    })
  },
  getSubscriptions: function (params?: object) {
    return service({
      url: `/utility/subscription`,
      method: 'get',
      params,
    })
  },
  createInvoice: function (data?: any) {
    return service({
      url: `/payment/invoice`,
      method: 'POST',
      data,
    })
  },
  createQpayInvoice: function (invoiceId: string, data?: any) {
    return service({
      url: `/payment/invoice/qpay/${invoiceId}`,
      method: 'POST',
      data,
    })
  },
  createDemoSubscription: function (data?: any) {
    return service({
      url: `/payment/subscription/demo`,
      method: 'POST',
      data,
    })
  },
  checkInvoiceStatus: function (invoiceId: string, params?: any) {
    return service({
      url: `/payment/invoice/check/qpay/${invoiceId}`,
      method: 'GET',
      params,
    })
  },
  checkDemoAccess: function () {
    return service({
      url: `/payment/subscription/demo/check`,
      method: 'POST',
    })
  },
  createContract: function (data: any) {
    return service({
      url: `contract`,
      method: 'POST',
      data,
    })
  },
  checkContract: function () {
    return service({
      url: `utility/check/contract`,
      method: 'GET',
    })
  },
  getTags: function (params?: object) {
    return service({
      url: `tag`,
      method: 'GET',
      params,
    })
  },
  createTag: function (data: any) {
    return service({
      url: `tag`,
      method: 'GET',
      data,
    })
  },
  getEmployeeDataByData: function (token: string, params?: object) {
    return service({
      url: `employee/group/user`,
      method: 'GET',
      headers: {
        Authorization: 'Bearer ' + token,
      },
      params,
    })
  },
  createCompanyToken: function (data?: object) {
    return service({
      url: `company/token`,
      method: 'POST',
      data,
    })
  },
  getTodayAttendace: function () {
    return service({
      url: 'dashboard/statistics/today-attendance',
      method: 'get',
    })
  },
  getWeekAttendace: function () {
    return service({
      url: 'dashboard/statistics/attendance',
      method: 'get',
    })
  },
}

export default DashboardService