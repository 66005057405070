import { CircularProgress, Fab } from '@mui/material'
import React, { useState } from 'react'
import Iconify from 'src/components/Iconify'
import { useLocales } from 'src/locales'
import { PATH_DASHBOARD } from 'src/routes/paths'
import DashboardService from 'src/services/DashboardService'

export default function PhoneAttendancePopover() {
  const { translate: t } = useLocales()
  const [loading, setLoading] = useState<boolean>(false)

  const handleFetch = () => {
    setLoading(true)
    DashboardService.createCompanyToken()
      .then((res) => {
        setLoading(false)
        window.open(
          PATH_DASHBOARD.attendance.phone + '?t=' + res.data.access_token,
          '_blank',
          'rel=noopener noreferrer'
        )
        // navigate(PATH_DASHBOARD.attendance.phone + '?t=' + res.data.access_token)
      })
      .catch((err) => {
        setLoading(false)
      })
  }

  return (
    <>
      <Fab variant="extended" color="default" onClick={handleFetch}>
        <Iconify icon="eva:file-add-fill" width={20} height={20} />
        {`${t('calendarAtt.addAttendance')}`}
      </Fab>
      {loading && <CircularProgress size={20} />}
    </>
  )
}
