// @mui
import { Stack, Button, Typography } from '@mui/material'
import useAuth from 'src/hooks/useAuth'
import { PATH_DOCS } from 'src/routes/paths'
// assets
import { DocIllustration } from '../../../assets'
import { useLocales } from 'src/locales'

// ----------------------------------------------------------------------

export default function NavbarDocs() {
  const { translate: t } = useLocales()
  const { user } = useAuth()
  return (
    <Stack
      spacing={3}
      sx={{ px: 5, pb: 5, mt: 10, width: 1, textAlign: 'center', display: 'block' }}
    >
      <DocIllustration sx={{ width: 1 }} />

      <div>
        <Typography gutterBottom variant="subtitle1">
          {`${t('nav.hello', { name: user?.name })}`}
        </Typography>
        <Typography variant="body2" sx={{ color: 'text.secondary' }}>
          {`${t('nav.needHelp')}`}
        </Typography>
      </div>
      <Button href={PATH_DOCS.root} variant="contained">
        Guideline
      </Button>
    </Stack>
  )
}
