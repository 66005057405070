import { useState } from 'react'
import { useForm } from 'react-hook-form'
import { FormProvider, RHFCheckbox, RHFTextField } from 'src/components/hook-form'
import useAuth from 'src/hooks/useAuth'
import * as Yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { IconButton, InputAdornment, Stack, Alert } from '@mui/material'
import Iconify from 'src/components/Iconify'
import { LoadingButton } from '@mui/lab'
import { useLocales } from 'src/locales'

type FormValuesProps = {
  email: string
  password: string
  afterSubmit?: string
}

export default function LoginForm() {
  const { translate: t } = useLocales()
  const { mainUserLogin } = useAuth()

  const [showPassword, setShowPassword] = useState(false)

  const LoginSchema = Yup.object().shape({
    email: Yup.string()
      .email(`${t('error.invalidEmail')}`)
      .required(`${t('error.emailRequired')}`),
    password: Yup.string().required(`${t('error.passwordRequired')}`),
  })

  const defaultValues = {
    email: '',
    password: '',
    remember: true,
  }

  const methods = useForm<FormValuesProps>({
    resolver: yupResolver(LoginSchema),
    defaultValues,
  })

  const {
    reset,
    setError,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = methods

  const onSubmit = async (data: FormValuesProps) => {
    try {
      await mainUserLogin(data.email, data.password)
    } catch (err) {
      reset()
      setError('afterSubmit', { ...err, message: err.message })
    }
  }

  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      <Stack spacing={3}>
        {!!errors.afterSubmit && <Alert severity="error">{errors.afterSubmit.message}</Alert>}

        <RHFTextField name="email" label="И-мэйл" />

        <RHFTextField
          name="password"
          label="Нууц үг"
          type={showPassword ? 'text' : 'password'}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                  <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </Stack>

      <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ my: 2 }}>
        <RHFCheckbox name="remember" label="Сануулах" />
      </Stack>

      <LoadingButton
        fullWidth
        size="large"
        type="submit"
        variant="contained"
        loading={isSubmitting}
      >
        {`${t('login')}`}
      </LoadingButton>
    </FormProvider>
  )
}
