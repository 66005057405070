// components
import BusinessIcon from '@mui/icons-material/Business'
import PeopleIcon from '@mui/icons-material/People'
import CalendarIcon from '@mui/icons-material/CalendarMonth'
import LocationOnIcon from '@mui/icons-material/LocationOn'
import CalendarViewWeekIcon from '@mui/icons-material/CalendarViewWeek'
import AccountCircleIcon from '@mui/icons-material/AccountCircle'
import TodayIcon from '@mui/icons-material/Today'

// ----------------------------------------------------------------------

const ICONS = {
  auth: <AccountCircleIcon />,
  location: <LocationOnIcon />,
  timeshift: <CalendarViewWeekIcon />,
  specialDay: <TodayIcon />,
  departments: <BusinessIcon />,
  employees: <PeopleIcon />,
  attendances: <CalendarIcon />,
}

const navConfig = [
  // GENERAL
  // ----------------------------------------------------------------------
  {
    subheader: 'Заавар',
    items: [
      {
        title: 'Нэвтрэх',
        path: '/documentation/auth',
        icon: ICONS.auth,
      },
      {
        title: 'Байршил',
        path: '/documentation/location',
        icon: ICONS.location,
      },
      {
        title: 'Цагийн хуваарь',
        path: '/documentation/timeshift',
        icon: ICONS.timeshift,
      },
      {
        title: 'Амралтын өдөр',
        path: '/documentation/special-day',
        icon: ICONS.specialDay,
      },
      {
        title: 'Хэлтэс',
        path: '/documentation/department',
        icon: ICONS.departments,
      },
      {
        title: 'Ажилтан',
        path: '/documentation/employee',
        icon: ICONS.employees,
      },
      {
        title: 'Ирц',
        path: '/documentation/attendance',
        icon: ICONS.attendances,
      },
    ],
  },
]

export default navConfig
