import service from './interceptors'

const EmployeeService = {
  getEmployees: function (params?: object) {
    return service({
      url: 'employee',
      method: 'get',
      params: params,
    })
  },

  utilityEmployees: function (params?: object) {
    return service({
      url: 'utility/employee',
      method: 'get',
      params: params,
    })
  },

  utilityUsers: function (params?: object) {
    return service({
      url: 'utility/user',
      method: 'get',
      params: params,
    })
  },

  getSpecificEmployee: function (id: number | string) {
    return service({
      url: `employee/${id}`,
      method: 'get',
    })
  },

  checkEmployeeAdmin: function (id: number | string) {
    return service({
      url: `utility/organization/check/${id}`,
      method: 'get',
    })
  },

  removeEmployee: function (id: string | number) {
    return service({
      url: `employee/${id}`,
      method: 'delete',
    })
  },

  editEmployee: function (id: string | number, data: object) {
    return service({
      url: `employee/${id}?_method=PUT`,
      method: 'post',
      data,
    })
  },

  addEmployee: function (data: object) {
    return service({
      url: 'employee',
      method: 'post',
      data,
    })
  },
  createEmployeeFromUser: function (id: string | number, data: object) {
    return service({
      url: `employee/group/user/${id}`,
      method: 'post',
      data,
    })
  },
  getEmployeeRating: function (employeeId: string | number, params?: object) {
    return service({
      url: `employee/${employeeId}/rating`,
      method: 'GET',
      params,
    })
  },
  createEmployeeRating: function (employeeId: string | number, data?: any) {
    return service({
      url: `employee/${employeeId}/rating`,
      method: 'POST',
      data,
    })
  },
  getEmployeeRatingSource: function (employeeId: string | number, params?: object) {
    return service({
      url: `employee/${employeeId}/rating/group/source`,
      method: 'GET',
      params,
    })
  },

  deleteEmployeeRating: function (
    employeeId: string | number,
    ratingId: string | number,
    data?: any
  ) {
    return service({
      url: `employee/${employeeId}/rating/${ratingId}`,
      method: 'DELETE',
      data,
    })
  },
  getEmployeeRatingValues: function (employeeId: string | number, params?: object) {
    return service({
      url: `employee/${employeeId}/rating/group/value`,
      method: 'GET',
      params,
    })
  },

  checkByPhone: function (params?: object) {
    return service({
      url: 'employee/group/check',
      method: 'GET',
      params,
    })
  },

  toggleAdmin: function (id: number) {
    return service({
      url: `employee/${id}/admin`,
      method: 'post',
    })
  },
}

export default EmployeeService
