import service from './interceptors'

const FormService = {
  getForms: function (params?: object) {
    return service({
      url: 'form',
      method: 'get',
      params,
    })
  },

  getOverview: function (id: string | number) {
    return service({
      url: `form/${id}`,
      method: 'get',
    })
  },

  getEmployeeOverview: function (formId: string | number, employeeId: string | number) {
    return service({
      url: `form/${formId}/employee/${employeeId}`,
      method: 'get',
    })
  },

  createForm: function (data: object) {
    return service({
      url: 'form',
      method: 'post',
      data,
    })
  },

  updateForm: function (id: number | string, data: object) {
    return service({
      url: `form/${id}/update`,
      method: 'post',
      data,
    })
  },

  createQuestions: function (data: object) {
    return service({
      url: 'question',
      method: 'post',
      data,
    })
  },

  deleteForm: function (id: number | string) {
    return service({
      url: `form/${id}`,
      method: 'delete',
    })
  },

  updateQuestion: function (id: string | number, data: object) {
    return service({
      url: `question/${id}`,
      method: 'put',
      data,
    })
  },

  deleteQuestion: function (id: string | number) {
    return service({
      url: `question/${id}`,
      method: 'delete',
    })
  },

  deleteQuestionOption: function (id: string | number) {
    return service({
      url: `question/option/${id}`,
      method: 'delete',
    })
  },
  getUtilityForms: function (params?: object) {
    return service({
      url: `utility/form`,
      method: 'GET',
      params,
    })
  },
}

export default FormService
