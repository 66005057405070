import { Box, Stack, styled, Typography } from '@mui/material'
import moment from 'moment'
import React from 'react'
import useAuth from 'src/hooks/useAuth'
import { useLocales } from 'src/locales'
// ----------------------------------------------------------------------

const RootStyle = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(2, 0),
  color: theme.palette.common.white,
  borderRadius: Number(theme.shape.borderRadius),
  backgroundImage: `linear-gradient(135deg,
    ${theme.palette.primary.main} 0%,
    ${theme.palette.primary.dark} 100%)`,
  transition: theme.transitions.create('opacity', {
    duration: theme.transitions.duration.shorter,
  }),
}))

type Props = {
  isCollapse: boolean | undefined
}

export default function SubscriptionPlan({ isCollapse }: Props) {
  const { user } = useAuth()
  const { translate } = useLocales()

  return (
    <RootStyle
      sx={{
        ...(isCollapse && {
          bgcolor: 'transparent',
          border: 'none',
          display: 'none',
        }),
      }}
    >
      <Box
        sx={{
          ml: 2,
          width: 180,
          transition: (theme) =>
            theme.transitions.create('width', {
              duration: theme.transitions.duration.shorter,
            }),
          ...(isCollapse && {
            ml: 0,
            width: 0,
          }),
        }}
      >
        <Stack direction="row" spacing={2} alignItems="center" justifyContent="space-between">
          <Box>
            <Typography variant="caption">{`${translate('plan')}`}</Typography>
            <Typography variant="subtitle1" noWrap sx={{ fontWeight: 700 }}>
              {user?.company.active_subscription.subscription.name}
            </Typography>
          </Box>
          <Box>
            <Typography variant="caption">{`${translate('endDate')}`}</Typography>
            <Typography variant="subtitle2" noWrap>
              {user ? moment(user.company.active_subscription.end_date).format('DD MMM YYYY') : '-'}
            </Typography>
          </Box>
        </Stack>
      </Box>
    </RootStyle>
  )
}
