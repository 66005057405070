import { Dialog } from '@mui/material'
import { ReactNode, useEffect, useState } from 'react'
import { Navigate, useLocation } from 'react-router'
import LoadingScreen from 'src/components/LoadingScreen'
import useAuth from 'src/hooks/useAuth'
import navConfig from 'src/layouts/dashboard/navbar/NavConfig'
import Login from 'src/pages/auth/Login'
import { PATH_DASHBOARD } from 'src/routes/paths'
import NewScheduleContent from 'src/sections/notification/NewScheduleContent'
import localStorageAvailable from 'src/utils/localStorageAvailable'

type AuthGuardProps = {
  children: ReactNode
}

export default function AuthGuard({ children }: AuthGuardProps) {
  const { user, isMainAuthenticated, isAuthenticated, isMainInitialized, isInitialized } = useAuth()

  const { pathname } = useLocation()

  const [requestedLocation, setRequestedLocation] = useState<string | null>(null)
  const [openNotification, setOpenNotification] = useState<boolean>(false)

  const subscription = user?.company.active_subscription

  useEffect(() => {
    const storageAvailable = localStorageAvailable()
    const triedSchedule = storageAvailable ? localStorage.getItem('triedSchedule') : false
    if (!triedSchedule) {
      setOpenNotification(true)
    }
  }, [])

  if (!isMainInitialized || !isInitialized) {
    return <LoadingScreen />
  }

  if (!isMainAuthenticated || !isAuthenticated) {
    if (pathname !== requestedLocation) {
      setRequestedLocation(pathname)
    }
    return <Login />
  }

  if (subscription === null) {
    return <Navigate to={PATH_DASHBOARD.subscription.root} />
  }

  const features = user?.company.active_subscription.features.filter((f) => f.active)
  const navItem = navConfig[0].items.find((item) => item.path === pathname)

  if (navItem && features) {
    const featureExists =
      'id' in navItem ? features.find((feature) => feature.id === navItem.id) : true

    if (!featureExists) {
      return <Navigate to="/403" />
    }
  }

  if (requestedLocation && pathname !== requestedLocation) {
    setRequestedLocation(null)
    return <Navigate to={requestedLocation} />
  }

  return (
    <>
      {children}
      {/* <Dialog
        open={openNotification}
        onClose={() => setOpenNotification(false)}
        fullWidth
        maxWidth="xs"
      >
        <NewScheduleContent onClose={() => setOpenNotification(false)} />
      </Dialog> */}
    </>
  )
}
