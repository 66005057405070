import { HOST_API_V2 } from 'src/config'
import service from './interceptors'

const RequestService = {
  getRequests: function (params?: object) {
    return service({
      url: 'request',
      method: 'get',
      params,
    })
  },

  updateRequest: function (id: string | number, data: object) {
    return service({
      url: `request/${id}`,
      method: 'put',
      data,
    })
  },

  decide: function (id: number, data: object) {
    return service({
      url: `request/${id}/status`,
      method: 'post',
      data,
    })
  },

  getTypes: function () {
    return service({
      url: 'utility/request-type',
      method: 'get',
    })
  },

  updateWithStatus: function (id: string | number, data: object) {
    return service({
      baseURL: HOST_API_V2,
      url: `request/action/${id}`,
      method: 'put',
      data,
    })
  },

  getObjectTypes: function () {
    return service({
      url: 'utility/request-object-type',
      method: 'get',
    })
  },

  addObjectType: function (data: object) {
    return service({
      url: 'utility/request-object-type',
      method: 'post',
      data,
    })
  },

  updateObjectType: function (id: number, data: object) {
    return service({
      url: `utility/request-object-type/${id}`,
      method: 'put',
      data,
    })
  },

  deleteObjectType: function (id: number) {
    return service({
      url: `utility/request-object-type/${id}`,
      method: 'delete',
    })
  },

  getPolicies: function (params?: any) {
    return service({
      baseURL: HOST_API_V2,
      url: `request/policy`,
      method: 'GET',
      params,
    })
  },

  createPolicy: function (data: any) {
    return service({
      baseURL: HOST_API_V2,
      url: `request/policy`,
      method: 'POST',
      data,
    })
  },

  deletePolicy: function (id: number) {
    return service({
      baseURL: HOST_API_V2,
      url: `request/policy/${id}`,
      method: 'delete',
    })
  },

  getOrganizationRequests: function (params?: any) {
    return service({
      baseURL: HOST_API_V2,
      url: `request/organization`,
      method: 'GET',
      params,
    })
  },

  getRequestOverview: function (id: number, params?: any) {
    return service({
      baseURL: HOST_API_V2,
      url: `request/action/${id}`,
      method: 'GET',
      params,
    })
  },
}

export default RequestService
