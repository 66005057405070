// @mui
import { List, Box } from '@mui/material'
//
import { NavSectionProps, NavListProps } from '../type'
import { ListSubheaderStyle } from './style'
import NavList from './NavList'
import { useEffect, useState } from 'react'
import RequestService from 'src/services/RequestService'
import Label from 'src/components/Label'
import useAuth from 'src/hooks/useAuth'
import { useLocales } from 'src/locales'

// ----------------------------------------------------------------------

export default function NavSectionVertical({ navConfig, isCollapse, ...other }: NavSectionProps) {
  const { translate: t } = useLocales()
  const { user } = useAuth()
  const features = user ? user.company.active_subscription.features.filter((f) => f.active) : []

  const [config, setConfig] = useState<
    {
      subheader: string
      items: NavListProps[]
    }[]
  >([])

  useEffect(() => {
    RequestService.getRequests({ status: 0 })
      .then((res) => {
        const requestsCount: number = res.data.length
        const items = navConfig[0].items.map((item) =>
          item.path === '/dashboard/requests/'
            ? { ...item, info: <Label color="primary">{requestsCount}</Label> }
            : item
        )
        setConfig([{ ...navConfig[0], items: items }])
      })
      .catch((err) => console.error(err))
  }, [navConfig])

  return (
    <Box {...other}>
      {config.map((group) => (
        <List key={group.subheader} disablePadding sx={{ px: 2 }}>
          <ListSubheaderStyle
            sx={{
              ...(isCollapse && {
                opacity: 0,
              }),
            }}
          >
            {`${t(group.subheader)}`}
          </ListSubheaderStyle>

          {group.items.map((list) => {
            if (!('id' in list)) {
              return (
                <NavList
                  key={list.title + list.path}
                  data={list}
                  depth={1}
                  hasChildren={!!list.children}
                  isCollapse={isCollapse}
                />
              )
            }

            const featureExists = features.some((feature) => list.id === feature.id)

            return (
              featureExists && (
                <NavList
                  key={list.title + list.path}
                  data={list}
                  depth={1}
                  hasChildren={!!list.children}
                  isCollapse={isCollapse}
                />
              )
            )
          })}
        </List>
      ))}
    </Box>
  )
}
