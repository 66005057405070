// components
import SvgIconStyle from '../../../components/SvgIconStyle'
import SettingsIcon from '@mui/icons-material/Settings'
import BusinessIcon from '@mui/icons-material/Business'
import PeopleIcon from '@mui/icons-material/People'
import CalendarIcon from '@mui/icons-material/CalendarMonth'
import SendIcon from '@mui/icons-material/Send'
import QuizIcon from '@mui/icons-material/Quiz'
import InventoryIcon from '@mui/icons-material/Inventory'
import Iconify from 'src/components/Iconify'
import EventIcon from '@mui/icons-material/Event'
import TaskIcon from '@mui/icons-material/Task'
import VolunteerActivismIcon from '@mui/icons-material/VolunteerActivism'
import Label from 'src/components/Label'
import DescriptionIcon from '@mui/icons-material/Description'
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted'
// ----------------------------------------------------------------------

const getIcon = (name: string) => (
  <SvgIconStyle src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />
)

const ICONS = {
  user: getIcon('ic_user'),
  dashboard: getIcon('ic_dashboard'),
  settings: <SettingsIcon />,
  departments: <BusinessIcon />,
  employees: <PeopleIcon />,
  attendances: <CalendarIcon />,
  requests: <SendIcon />,
  logs: <InventoryIcon />,
  form: <QuizIcon />,
  announcement: <Iconify icon="ic:baseline-announcement" />,
  event: <EventIcon />,
  report: <TaskIcon />,
  thanks: <VolunteerActivismIcon />,
  schedule: <Iconify icon="material-symbols:edit-calendar" />,
  document: <DescriptionIcon />,
  task: <FormatListBulletedIcon />,
}

const navConfig = [
  // GENERAL
  // ----------------------------------------------------------------------
  {
    subheader: 'general',
    items: [
      {
        title: 'features.home',
        path: '/dashboard/overview',
        icon: ICONS.dashboard,
      },
      {
        title: 'features.settings',
        path: '/dashboard/settings',
        icon: ICONS.settings,
        children: [
          { title: 'features.account', path: '/dashboard/settings/account' },
          { title: 'features.location', path: '/dashboard/settings/locations' },
          //   { title: 'features.timeshift', path: '/dashboard/settings/timeshifts' },
          { title: 'features.specialDay', path: '/dashboard/settings/special-day' },
          { title: 'features.payment', path: '/payment/subscription' },
          { title: 'features.invoice', path: '/dashboard/settings/invoice' },
        ],
      },
      {
        title: 'features.schedule',
        path: '/dashboard/schedule/calendar',
        icon: ICONS.schedule,
        id: 'FEATURE_ATTENDANCE_MANAGEMENT',
        // info: <Label color="primary">BETA</Label>,
      },
      {
        title: 'features.attendance',
        path: '/dashboard/schedule/attendance',
        icon: ICONS.attendances,
        id: 'FEATURE_ATTENDANCE_MANAGEMENT',
        // info: <Label color="primary">BETA</Label>,
      },
      {
        title: 'features.departments',
        path: '/dashboard/departments',
        icon: ICONS.departments,
        id: 'FEATURE_TEAM',
      },
      {
        title: 'features.employees',
        path: '/dashboard/employees',
        icon: ICONS.employees,
        id: 'FEATURE_EMPLOYEE_MANAGEMENT',
      },
      //   {
      //     title: 'features.attendance',
      //     path: '/dashboard/attendances',
      //     icon: ICONS.attendances,
      //     children: [
      //       { title: 'attendance.daily', path: '/dashboard/attendances/daily' },
      //       { title: 'attendance.employee', path: '/dashboard/attendances/employee' },
      //       { title: 'attendance.report', path: '/dashboard/attendances/report' },
      //     ],
      //     id: 'FEATURE_ATTENDANCE_MANAGEMENT',
      //   },
      {
        title: 'features.requests',
        path: '/dashboard/requests',
        icon: ICONS.requests,
        id: 'FEATURE_REQUEST_MANAGEMENT',
      },
      {
        title: 'features.task',
        path: '/dashboard/task',
        icon: ICONS.task,
        id: 'FEATURE_THANKS_BOX',
      },
      {
        title: 'features.report',
        path: '/dashboard/report',
        icon: ICONS.report,
        id: 'FEATURE_REPORT_MANAGEMENT',
      },
      {
        title: 'features.survey',
        path: '/dashboard/form',
        icon: ICONS.form,
        id: 'FEATURE_SURVEY_MANAGEMENT',
      },
      {
        title: 'features.announcement',
        path: '/dashboard/announcement/',
        icon: ICONS.announcement,
        id: 'FEATURE_NOTICE',
      },
      {
        title: 'features.event',
        path: '/dashboard/event',
        icon: ICONS.event,
        id: 'FEATURE_EVENT',
      },
      {
        title: 'features.adminLog',
        path: '/dashboard/logs',
        icon: ICONS.logs,
        id: 'FEATURE_ADMIN_LOG',
      },
      {
        title: 'features.thanksBox',
        path: '/dashboard/thanks-box',
        icon: ICONS.thanks,
        id: 'FEATURE_THANKS_BOX',
      },
    ],
  },
]

export default navConfig
