import {
  Avatar,
  Box,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemSecondaryAction,
  ListItemText,
} from '@mui/material'
import { useSnackbar } from 'notistack'
import { useState } from 'react'
import Iconify from 'src/components/Iconify'
import { TableNoData } from 'src/components/table'
import useAuth from 'src/hooks/useAuth'

const OrganizationSection = () => {
  const { mainUser, getOrganizationAccess } = useAuth()
  const [loading, setLoading] = useState<boolean>(false)
  const { enqueueSnackbar } = useSnackbar()

  const handleOrganizationLogin = (id: number | string) => {
    setLoading(true)
    getOrganizationAccess(id)
      .then((res) => {
        setLoading(false)
      })
      .catch((err) => {
        setLoading(false)
        enqueueSnackbar('Мэдээлэл авахад алдаа гарлаа', { variant: 'error' })
      })
  }

  //   const handleAutoLogin = useCallback(
  //     (id: number | string) => {
  //       setLoading(true)
  //       getOrganizationAccess(id)
  //         .then((res) => {
  //           setLoading(false)
  //           setInitLoading(false)
  //         })
  //         .catch((err) => {
  //           setLoading(false)
  //           setInitLoading(false)
  //           enqueueSnackbar('Мэдээлэл авахад алдаа гарлаа', { variant: 'error' })
  //         })
  //     },
  //     [enqueueSnackbar, getOrganizationAccess]
  //   )

  //   useEffect(() => {
  //     setInitLoading(true)
  //     mainUser !== null &&
  //       mainUser.organizations.length === 1 &&
  //       handleAutoLogin(mainUser.organizations[0].id)
  //   }, [mainUser, handleAutoLogin])

  return (
    <Box>
      <List>
        <TableNoData
          isNotFound={mainUser?.organizations.length === 0}
          title="Админ эрх байхгүй байна"
        />
        {mainUser?.organizations.map((item) => (
          <ListItem key={'organization_list_' + item.id}>
            <ListItemAvatar>
              <Avatar src={item.company.avatar} />
            </ListItemAvatar>
            <ListItemText>{item.company.name}</ListItemText>
            <ListItemSecondaryAction>
              <IconButton disabled={loading} onClick={() => handleOrganizationLogin(item.id)}>
                <Iconify icon="eva:chevron-right-fill" />
              </IconButton>
            </ListItemSecondaryAction>
          </ListItem>
        ))}
      </List>
    </Box>
  )
}

export default OrganizationSection
