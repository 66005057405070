import { createContext, ReactNode, useEffect, useReducer } from 'react'
import { ActionMap, AuthContextType, AuthMainUser, AuthState, AuthUser } from 'src/@types/auth'
import baseAxios from 'src/utils/baseAxios'
import axios from 'src/utils/axios'
import { isValidToken, setMainSession, setSession } from 'src/utils/jwt'
import { ROLE_SUPERADMIN } from 'src/utils/constant'

enum Types {
  Initial = 'INITIALIZE',
  Login = 'LOGIN',
  Logout = 'LOGOUT',
  Register = 'REGISTER',
  MainUserLogin = 'MAIN_USER_LOGIN',
  MainInitialize = 'MAIN_INITIALIZE',
  MainLogout = 'MAIN_LOGOUT',
}

type AuthPayload = {
  [Types.Initial]: {
    isAuthenticated: boolean
    user: AuthUser
  }
  [Types.Login]: {
    user: AuthUser
  }
  [Types.Logout]: undefined
  [Types.Register]: {
    user: AuthUser
  }
  [Types.MainUserLogin]: {
    mainUser: AuthMainUser
  }
  [Types.MainInitialize]: {
    isAuthenticated: boolean
    mainUser: AuthMainUser
  }
  [Types.MainLogout]: undefined
}

export type AuthActions = ActionMap<AuthPayload>[keyof ActionMap<AuthPayload>]

const initialState: AuthState = {
  isInitialized: false,
  isAuthenticated: false,
  user: null,
  mainUser: null,
  isMainAuthenticated: false,
  isMainInitialized: false,
}

const authReducer = (state: AuthState, action: AuthActions) => {
  switch (action.type) {
    case 'INITIALIZE':
      return {
        ...state,
        isAuthenticated: action.payload.isAuthenticated,
        isInitialized: true,
        user: action.payload.user,
      }
    case 'LOGIN':
      return {
        ...state,
        isAuthenticated: true,
        user: action.payload.user,
      }
    case 'LOGOUT':
      return {
        ...state,
        isAuthenticated: false,
        user: null,
        mainUser: null,
        isMainAuthenticated: false,
      }
    case 'REGISTER':
      return {
        ...state,
        isAuthenticated: true,
        user: action.payload.user,
      }
    case 'MAIN_INITIALIZE':
      return {
        ...state,
        isMainAuthenticated: action.payload.isAuthenticated,
        isMainInitialized: true,
        mainUser: action.payload.mainUser,
        user: null,
      }
    case 'MAIN_USER_LOGIN':
      return {
        ...state,
        isMainAuthenticated: true,
        user: null,
        mainUser: action.payload.mainUser,
      }
    case 'MAIN_LOGOUT':
      return {
        ...state,
        isMainAuthenticated: false,
        isAuthenticated: false,
        mainUser: null,
        user: null,
      }
    default:
      return state
  }
}

const AuthContext = createContext<AuthContextType | null>(null)

type AuthProviderProps = {
  children: ReactNode
}

function AuthProvider({ children }: AuthProviderProps) {
  const [state, dispatch] = useReducer(authReducer, initialState)

  const mainInitialize = async () => {
    try {
      const accessToken = localStorage.getItem('mainAccessToken')
      if (accessToken && isValidToken(accessToken)) {
        setMainSession(accessToken)

        const response = await baseAxios.get('user/auth/user')
        const user = response.data.data

        dispatch({
          type: Types.MainInitialize,
          payload: {
            isAuthenticated: true,
            mainUser: user,
          },
        })
      } else {
        dispatch({
          type: Types.MainInitialize,
          payload: {
            isAuthenticated: false,
            mainUser: null,
          },
        })
      }
    } catch (err) {
      console.error(err)
      dispatch({
        type: Types.MainInitialize,
        payload: {
          isAuthenticated: false,
          mainUser: null,
        },
      })
    }
  }

  const initialize = async () => {
    try {
      const accessToken = localStorage.getItem('accessToken')
      if (accessToken && isValidToken(accessToken)) {
        setSession(accessToken)

        const response = await axios.get('self')
        const user = response.data.data

        dispatch({
          type: Types.Initial,
          payload: {
            isAuthenticated: true,
            user,
          },
        })
      } else {
        dispatch({
          type: Types.Initial,
          payload: {
            isAuthenticated: false,
            user: null,
          },
        })
      }
    } catch (err) {
      console.error(err)
      dispatch({
        type: Types.Initial,
        payload: {
          isAuthenticated: false,
          user: null,
        },
      })
    }
  }

  useEffect(() => {
    mainInitialize()
  }, [])

  useEffect(() => {
    state.user === null && initialize()
  }, [state.mainUser, state.user])

  const mainUserLogin = async (email: string, password: string) => {
    const response = await baseAxios.post('user/auth/login/email', {
      email,
      password,
      device_id: 'system',
    })

    const { user } = response.data.data
    const accessToken = response.data.data.access_token
    // console.log(accessToken)

    setMainSession(accessToken)
    dispatch({
      type: Types.MainUserLogin,
      payload: {
        mainUser: user,
      },
    })
  }

  const getOrganizationAccess = async (id: number | string) => {
    const response = await baseAxios.get('user/auth/organization/' + id)

    const user = response.data.data.organization
    const accessToken = response.data.data.access_token

    setSession(accessToken)
    dispatch({
      type: Types.Login,
      payload: {
        user,
      },
    })
  }

  const login = async (email: string, password: string) => {
    const response = await axios.post('login', { email, password })

    const user = response.data.data.organization
    const accessToken = response.data.data.token
    // console.log(accessToken)

    setSession(accessToken)
    dispatch({
      type: Types.Login,
      payload: {
        user,
      },
    })
  }

  const register = async (email: string, password: string, name: string) => {
    const response = await axios.post('register', { email, password, name })

    const user = response.data.data.organization
    const accessToken = response.data.data.token

    setSession(accessToken)
    dispatch({
      type: Types.Register,
      payload: {
        user,
      },
    })
  }

  const logout = () => {
    setSession(null)
    setMainSession(null)
    dispatch({ type: Types.Logout })
  }

  return (
    <AuthContext.Provider
      value={{
        ...state,
        isSuperadmin: state.user !== null && state.user.role_id === ROLE_SUPERADMIN,
        method: 'jwt',
        getOrganizationAccess,
        mainUserLogin,
        login,
        logout,
        register,
        initialize,
        mainInitialize,
      }}
    >
      {children}
    </AuthContext.Provider>
  )
}

export { AuthContext, AuthProvider }
