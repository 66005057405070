import { Helmet } from 'react-helmet-async'
import { forwardRef, ReactNode } from 'react'
// @mui
import { Box, BoxProps } from '@mui/material'

// ----------------------------------------------------------------------

interface Props extends BoxProps {
  children: ReactNode
  meta?: ReactNode
  title: string
}

const Page = forwardRef<HTMLDivElement, Props>(({ children, title = '', meta, ...other }, ref) => (
  <>
    <Helmet>
      <title>{`${capitalize(title)} | IRLE`}</title>
      {meta}
    </Helmet>

    <Box ref={ref} {...other}>
      {children}
    </Box>
  </>
))

const capitalize = (str: string) => str.charAt(0).toUpperCase() + str.slice(1)

export default Page
