import axios from './axios'
import baseAxios from './baseAxios'
import jwtDecode from 'jwt-decode'
import { PATH_AUTH } from 'src/routes/paths'

const isValidToken = (accessToken: string) => {
  if (!accessToken) {
    return false
  }
  const decoded = jwtDecode<{ exp: number }>(accessToken)

  const currentTime = Date.now() / 1000

  return decoded.exp > currentTime
}

const handleExpiredToken = (exp: number) => {
  let expiredTimer

  const currentTime = Date.now()

  // Test token expires after 10s
  // const timeLeft = currentTime + 10000 - currentTime // ~10s
  const timeLeft = exp * 1000 - currentTime // ms

  clearTimeout(expiredTimer)

  expiredTimer = setTimeout(() => {
    alert('Token expired')

    localStorage.removeItem('accessToken')

    window.location.href = PATH_AUTH.login
  }, timeLeft)
}

const setSession = (accessToken: string | null) => {
  if (accessToken) {
    localStorage.setItem('accessToken', accessToken)
    axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`

    const { exp } = jwtDecode<{ exp: number }>(accessToken)
    handleExpiredToken(exp)
  } else {
    localStorage.removeItem('accessToken')
    delete axios.defaults.headers.common.Authorization
  }
}

const setMainSession = (accessToken: string | null) => {
  if (accessToken) {
    localStorage.setItem('mainAccessToken', accessToken)
    baseAxios.defaults.headers.common.Authorization = `Bearer ${accessToken}`

    const { exp } = jwtDecode<{ exp: number }>(accessToken)
    handleExpiredToken(exp)
  } else {
    localStorage.removeItem('mainAccessToken')
    delete baseAxios.defaults.headers.common.Authorization
  }
}

export { setSession, isValidToken, setMainSession }
