import { LoadingButton } from '@mui/lab'
import { Checkbox, FormControlLabel, Card, Button, Stack, Typography, Box } from '@mui/material'
import React, { useState } from 'react'
import Scrollbar from 'src/components/Scrollbar'
import terms from './TermsContent'
import { useLocales } from 'src/locales'

type Props = {
  handlePrev: () => void
  handleNext: (e: React.MouseEvent<HTMLButtonElement>) => void
  loading: boolean
}

export default function Terms({ handlePrev, handleNext, loading }: Props) {
  const { translate: t } = useLocales()
  const [accepted, setAccepted] = useState<boolean>(false)

  return (
    <Stack spacing={3}>
      <Card sx={{ p: 1 }}>
        <Stack spacing={2} sx={{ py: 2 }}>
          <Typography variant="h5" align="center">
            {`${t('termsOfUse')}`}
          </Typography>
          <Scrollbar sx={{ height: '520px' }}>
            <Stack spacing={2} sx={{ px: 3 }}>
              {terms.data.map(({ title, list }, cIndex) => (
                <Stack key={`c-${cIndex}`}>
                  <Typography variant="h6">{title}</Typography>
                  <Stack sx={{ px: 3 }} spacing={1}>
                    {list.map(({ body, subData }, lIndex) => (
                      <Stack key={`l-${lIndex}`}>
                        <Stack direction="row" gap={1}>
                          <Typography>{`${cIndex + 1}.${lIndex + 1}`}</Typography>
                          <Typography>{body}</Typography>
                        </Stack>
                        {subData.haveSub && (
                          <Stack sx={{ px: 3 }}>
                            {subData.data?.map((d, dIndex) => (
                              <Stack key={dIndex} direction="row" gap={1}>
                                <Typography>{`${cIndex + 1}.${lIndex + 1}.${
                                  dIndex + 1
                                }`}</Typography>
                                <Typography>{d}</Typography>
                              </Stack>
                            ))}
                          </Stack>
                        )}
                      </Stack>
                    ))}
                  </Stack>
                </Stack>
              ))}
              <Stack justifyContent="center">
                <FormControlLabel
                  control={<Checkbox value={accepted} onChange={() => setAccepted(!accepted)} />}
                  label={<Typography variant="body1">{`${t('acceptTerms')}`}</Typography>}
                />
              </Stack>
            </Stack>
          </Scrollbar>
        </Stack>
      </Card>
      <Box sx={{ textAlign: 'right' }}>
        <Button onClick={handlePrev} sx={{ mr: 1, px: 2 }} variant="text" color="inherit">
          {`${t('back')}`}
        </Button>
        <LoadingButton
          sx={{
            minWidth: '140px',
            bgcolor: '#1A0289',
            color: '#fff',
            '&:hover': {
              bgcolor: '#1A0289',
              color: '#fff',
            },
          }}
          type="button"
          variant="contained"
          onClick={handleNext}
          disabled={!accepted}
          loading={loading}
        >
          {`${t('register')}`}
        </LoadingButton>
      </Box>
    </Stack>
  )
}
