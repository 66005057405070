import {
  Container,
  Box,
  Link,
  Card,
  styled,
  Typography,
  LinearProgress,
  linearProgressClasses,
  Stack,
} from '@mui/material'
import { useSnackbar } from 'notistack'
import { useState } from 'react'
import { Trans } from 'react-i18next'
import { Link as RouterLink, useNavigate } from 'react-router-dom'
import Image from 'src/components/Image'
import Logo from 'src/components/Logo'
import Page from 'src/components/Page'
import useResponsive from 'src/hooks/useResponsive'
import { useLocales } from 'src/locales'
import { PATH_AUTH, PATH_DASHBOARD } from 'src/routes/paths'
import CompanyInfoForm, { CompanyInfoValues } from 'src/sections/auth/register/CompanyInfoForm'
import EmailVerification, {
  VerificationInfoValues,
} from 'src/sections/auth/register/EmailVerification'
import LoginInfoForm, { LoginInfoValues } from 'src/sections/auth/register/LoginInfoForm'
import NameForm from 'src/sections/auth/register/NameForm'
import Terms from 'src/sections/auth/register/Terms'
import RegisterService from 'src/services/RegisterService'

const RootStyle = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex',
  },
}))

const HeaderStyle = styled('header')(({ theme }) => ({
  top: 0,
  zIndex: 9,
  lineHeight: 0,
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  position: 'absolute',
  padding: theme.spacing(3),
  justifyContent: 'space-between',
  flexDirection: 'row-reverse',
  [theme.breakpoints.up('md')]: {
    alignItems: 'flex-start',
    padding: theme.spacing(7, 5, 0, 7),
  },
  [theme.breakpoints.down('sm')]: {
    flexDirection: 'row',
  },
}))

const SectionStyle = styled(Card)(({ theme }) => ({
  width: '100%',
  maxWidth: 464,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  borderRadius: '0',
  background: "url('/assets/bg_login.png')",
  backgroundSize: 'cover',
  paddingLeft: '2.5em',
}))

const ContentStyle = styled('div')(({ theme }) => ({
  margin: 'auto',
  minHeight: '100vh',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  padding: theme.spacing(12, 0),
}))

const LogoImage = styled(Image)(({ theme }) => ({
  width: '45%',
}))

const Progress = styled(LinearProgress)(({ theme }) => ({
  height: 10,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: theme.palette.mode === 'light' ? '#1A0289' : '#27264E',
  },
}))

export default function Register() {
  const { translate: t } = useLocales()

  const smUp = useResponsive('up', 'sm')
  const mdUp = useResponsive('up', 'md')
  const mdDown = useResponsive('down', 'md')

  const [activeStep, setActiveStep] = useState<number>(1)
  const [submitLoading, setSubmitLoading] = useState<boolean>(false)

  const [companyName, setCompanyName] = useState<string>('')
  const [companyInfo, setCompanyInfo] = useState<CompanyInfoValues | null>(null)
  const [loginInfo, setLoginInfo] = useState<LoginInfoValues | null>(null)
  const [verificationInfo, setVerificationInfo] = useState<VerificationInfoValues | null>(null)

  const { enqueueSnackbar } = useSnackbar()
  const navigate = useNavigate()

  const handleAcceptTerms = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault()
    if (!(companyInfo && companyName && loginInfo && verificationInfo)) return
    setSubmitLoading(true)
    const formData = new FormData()
    if (companyInfo.avatar && typeof companyInfo.avatar !== 'string')
      formData.append('avatar', companyInfo.avatar, 'avatar')
    formData.append('name', companyName)
    formData.append('phone', companyInfo.phone)
    formData.append('register_code', companyInfo.register_code)
    formData.append('email', loginInfo.email)
    formData.append('verification_id', verificationInfo.verification_id + '')
    formData.append('password', loginInfo.password)
    RegisterService.registerCompany(formData)
      .then((res) => {
        setSubmitLoading(false)
        enqueueSnackbar(`${t('registerSuccess')}`, { variant: 'success' })
        navigate(PATH_DASHBOARD.subscription.root)
      })
      .catch((error) => {
        setSubmitLoading(false)
        enqueueSnackbar(`${t('registerError')}`, { variant: 'error' })
        setActiveStep(1)
      })
  }

  const handleVerificationValue = (values: VerificationInfoValues) => {
    setVerificationInfo(values)
    setSubmitLoading(false)
    setActiveStep(activeStep + 1)
  }

  return (
    <Page title="Бүртгүүлэх">
      <RootStyle>
        <HeaderStyle>
          {smUp && (
            <Typography variant="body2" sx={{ mt: { md: -2 } }}>
              {`${t('haveAccount')} `}
              <Link variant="subtitle2" component={RouterLink} to={PATH_AUTH.login}>
                {`${t('login')}`}
              </Link>
            </Typography>
          )}
          {mdDown && <Logo sx={{ width: '72x' }} />}
        </HeaderStyle>

        {mdUp && (
          <SectionStyle>
            <LogoImage
              visibleByDefault
              disabledEffect
              src="/assets/login_logo.png"
              alt="login"
              sx={{ px: 5 }}
            />
            <Typography
              variant="h3"
              sx={{ px: 5, mt: 5, fontWeight: 'light', color: 'common.white', lineHeight: '1' }}
            >
              <Trans i18nKey="atWork" />
            </Typography>
            <Typography
              variant="h3"
              sx={{ px: 5, mt: 0, fontWeight: 'light', color: 'common.white' }}
            >
              <Trans i18nKey="onTime" />
            </Typography>
          </SectionStyle>
        )}

        <Container>
          <ContentStyle sx={{ maxWidth: activeStep === 5 ? 620 : 464 }}>
            <Box sx={{ mb: 5, display: 'flex', alignItems: 'center' }}>
              <Box sx={{ flexGrow: 1 }}>
                <Stack gap={1}>
                  <Typography variant="subtitle2">
                    {`${t('registerStep')}`} {activeStep}/5
                  </Typography>
                  <Box sx={{ width: 160 }}>
                    <Progress variant="determinate" value={activeStep * 20} />
                  </Box>
                </Stack>
              </Box>
            </Box>

            {activeStep === 1 && (
              <NameForm
                setStep={setActiveStep}
                setCompanyName={setCompanyName}
                companyName={companyName}
              />
            )}

            {activeStep === 2 && (
              <CompanyInfoForm
                setStep={setActiveStep}
                setCompanyInfo={setCompanyInfo}
                companyInfo={companyInfo}
              />
            )}
            {activeStep === 3 && (
              <LoginInfoForm setStep={setActiveStep} setData={setLoginInfo} loginInfo={loginInfo} />
            )}

            {activeStep === 4 && loginInfo !== null && (
              <EmailVerification
                handleVerificationValue={handleVerificationValue}
                setStep={setActiveStep}
                loginInfo={loginInfo}
              />
            )}

            {activeStep === 5 && (
              <Terms
                handlePrev={() => setActiveStep(3)}
                handleNext={handleAcceptTerms}
                loading={submitLoading}
              />
            )}

            {!smUp && (
              <Typography variant="body2" sx={{ mt: 3, textAlign: 'center' }}>
                {`${t('haveAccount')} `}
                <Link variant="subtitle2" to={PATH_AUTH.login} component={RouterLink}>
                  {`${t('login')}`}
                </Link>
              </Typography>
            )}
          </ContentStyle>
        </Container>
      </RootStyle>
    </Page>
  )
}
