import axios from 'axios'
import { HOST_API } from 'src/config'
import history from 'src/history'
import { allLangs, defaultLang } from 'src/locales'
import localStorageAvailable from 'src/utils/localStorageAvailable'

const service = axios.create({
  baseURL: HOST_API,
  timeout: 120000,
})

// Config
const ENTRY_ROUTE = '/auth/login'
const TOKEN_PAYLOAD_KEY = 'authorization'
const PUBLIC_REQUEST_KEY = 'public-request'

// API Request interceptor
service.interceptors.request.use(
  (config: any) => {
    const jwtToken = localStorage.getItem('accessToken')
    if (jwtToken) {
      config.headers[TOKEN_PAYLOAD_KEY] = 'Bearer ' + jwtToken
    }

    if (!jwtToken && !config.headers[PUBLIC_REQUEST_KEY]) {
      history.push(ENTRY_ROUTE)
      window.location.reload()
    }

    const storageAvailable = localStorageAvailable()
    const langStorage = storageAvailable ? localStorage.getItem('i18nextLng') : ''

    const currentLang = allLangs.find((_lang) => _lang.value === langStorage) || defaultLang

    config.params = { ...config.params, system: 1, locale: currentLang.locale }
    return config
  },
  (error) => {
    Promise.reject(error)
  }
)

// API respone interceptor
service.interceptors.response.use(
  (response) => response.data,
  (error) => {
    let notificationParam = {
      message: '',
      description: '',
    }
    // Remove token and redirect
    if (error.response.status === 401 || error.response.status === 403) {
      notificationParam.message = 'Authentication Fail'
      notificationParam.description = 'Please login again'
      // localStorage.removeItem('accessToken')
      // history.push(ENTRY_ROUTE)
      // window.location.reload()
    }

    if (error.response.status === 404) {
      notificationParam.message = 'Not Found'
    }

    if (error.response.status === 500) {
      notificationParam.message = 'Internal Server Error'
    }

    if (error.response.status === 508) {
      notificationParam.message = 'Time Out'
    }

    if (error.response.status === 400) {
      if ('message' in error.response.data) notificationParam.message = error.response.data.message
    }

    // if (error.response.status === 422) {
    // }

    return Promise.reject(error)
  }
)

export default service
