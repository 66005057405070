// @mui
import { enUS } from '@mui/material/locale'
// components
import { SettingsValueProps } from './components/settings/types'

// LAYOUT
// ----------------------------------------------------------------------

export const HOST_API = process.env.REACT_APP_API_KEY || ''
export const HOST_API_V2 = process.env.REACT_APP_API_KEY_V2 || ''

export const BASE_HOST_API = process.env.REACT_APP_API_BASE_KEY || ''

export const MAP_TOKEN = process.env.REACT_APP_MAPBOX_API

export const CDN_PATH = 'https://d1asorbx1o9egm.cloudfront.net'

export const MAPS_APIKEY = process.env.REACT_APP_MAPS_APIKEY
  ? process.env.REACT_APP_MAPS_APIKEY
  : ''

export const HEADER = {
  MOBILE_HEIGHT: 64,
  MAIN_DESKTOP_HEIGHT: 88,
  DASHBOARD_DESKTOP_HEIGHT: 96,
  DASHBOARD_DESKTOP_OFFSET_HEIGHT: 96 - 32,
}

export const NAVBAR = {
  BASE_WIDTH: 260,
  DASHBOARD_WIDTH: 280,
  DASHBOARD_COLLAPSE_WIDTH: 88,
  //
  DASHBOARD_ITEM_ROOT_HEIGHT: 48,
  DASHBOARD_ITEM_SUB_HEIGHT: 40,
  DASHBOARD_ITEM_HORIZONTAL_HEIGHT: 32,
}

export const NAV = {
  W_BASE: 260,
  W_DASHBOARD: 280,
  W_DASHBOARD_MINI: 88,
  //
  H_DASHBOARD_ITEM: 48,
  H_DASHBOARD_ITEM_SUB: 36,
  //
  H_DASHBOARD_ITEM_HORIZONTAL: 32,
}

export const ICON = {
  NAVBAR_ITEM: 22,
  NAVBAR_ITEM_HORIZONTAL: 20,
}

// SETTINGS
// Please remove `localStorage` when you change settings.
// ----------------------------------------------------------------------

export const defaultSettings: SettingsValueProps = {
  themeMode: 'light',
  themeDirection: 'ltr',
  themeContrast: 'default',
  themeLayout: 'horizontal',
  themeColorPresets: 'blue',
  themeStretch: false,
}

// MULTI LANGUAGES
// Please remove `localStorage` when you change settings.
// ----------------------------------------------------------------------

export const allLangs = [
  {
    label: 'English',
    value: 'en',
    systemValue: enUS,
    icon: '/assets/icons/flags/ic_flag_en.svg',
  },
  {
    label: 'Монгол',
    value: 'mn',
    icon: '/assets/icons/flags/ic_flag_fr.svg',
  },
]

export const defaultLang = allLangs[0] // English
