// form
import { useFormContext, Controller } from 'react-hook-form'
// @mui
import { Autocomplete, TextField } from '@mui/material'
import React from 'react'
import { AutocompleteProps } from '@mui/material/Autocomplete'

// ----------------------------------------------------------------------

interface IProps<T>
  extends AutocompleteProps<T, boolean | undefined, boolean | undefined, boolean | undefined> {
  name: string
  label: string
}

export default function RHFAutocomplete<T>({ name, label, ...rest }: IProps<T>) {
  const { control } = useFormContext()

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <Autocomplete
          {...rest}
          filterSelectedOptions
          value={field.value}
          onChange={(event, newValue) => {
            field.onChange(newValue)
          }}
          renderInput={(params) => (
            <TextField label={label} {...params} error={!!error} helperText={error?.message} />
          )}
        />
      )}
    />
  )
}
