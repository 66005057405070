import {
  Box,
  Card,
  CircularProgress,
  Container,
  Link,
  Stack,
  styled,
  Typography,
} from '@mui/material'
import { Trans } from 'react-i18next'
import { Link as RouterLink } from 'react-router-dom'
import Image from 'src/components/Image'
import Logo from 'src/components/Logo'
import Page from 'src/components/Page'
import useAuth from 'src/hooks/useAuth'
import useResponsive from 'src/hooks/useResponsive'
import { useLocales } from 'src/locales'
import { PATH_AUTH } from 'src/routes/paths'
import { LoginForm } from 'src/sections/auth/login'
import OrganizationSection from 'src/sections/auth/login/OrganizationSection'

const RootStyle = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex',
  },
}))

const HeaderStyle = styled('header')(({ theme }) => ({
  top: 0,
  zIndex: 9,
  lineHeight: 0,
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  position: 'absolute',
  padding: theme.spacing(3),
  justifyContent: 'space-between',
  flexDirection: 'row-reverse',
  [theme.breakpoints.up('md')]: {
    alignItems: 'flex-start',
    padding: theme.spacing(7, 5, 0, 7),
  },
  [theme.breakpoints.down('sm')]: {
    flexDirection: 'row',
  },
}))

const ContentStyle = styled('div')(({ theme }) => ({
  maxWidth: 400,
  margin: 'auto',
  minHeight: '100vh',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  padding: theme.spacing(12, 0),
}))

const SectionStyle = styled(Card)(({ theme }) => ({
  width: '100%',
  maxWidth: 464,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  borderRadius: '0',
  background: "url('/assets/bg_login.png')",
  backgroundSize: 'cover',
  paddingLeft: '2.5em',
}))

const LogoImage = styled(Image)(({ theme }) => ({
  width: '45%',
}))

function Login() {
  const { translate: t } = useLocales()

  const smUp = useResponsive('up', 'sm')
  const mdUp = useResponsive('up', 'md')
  const mdDown = useResponsive('down', 'md')

  const { isMainAuthenticated, isMainInitialized, isInitialized } = useAuth()

  return (
    <Page title={`${t('login')}`}>
      <RootStyle>
        <HeaderStyle>
          {!isMainAuthenticated && smUp && (
            <Typography variant="body2" sx={{ mt: { md: -2 } }}>
              {`${t('noAccount')}`}?{' '}
              <Link variant="subtitle2" component={RouterLink} to={PATH_AUTH.register}>
                {`${t('register')}`}
              </Link>
            </Typography>
          )}
          {mdDown && <Logo sx={{ width: '72x' }} />}
        </HeaderStyle>

        {mdUp && (
          <SectionStyle>
            <LogoImage
              px={5}
              visibleByDefault
              disabledEffect
              src="/assets/login_logo.png"
              alt="login"
            />
            <Typography
              variant="h3"
              sx={{ px: 5, mt: 5, fontWeight: 'light', color: 'common.white', lineHeight: '1' }}
            >
              <Trans i18nKey="atWork" />
            </Typography>
            <Typography
              variant="h3"
              sx={{ px: 5, mt: 0, fontWeight: 'light', color: 'common.white' }}
            >
              <Trans i18nKey="onTime" />
            </Typography>
          </SectionStyle>
        )}

        <Container maxWidth="sm">
          <ContentStyle>
            {!isMainInitialized && (
              <Stack direction="row" justifyContent="center">
                <CircularProgress />
              </Stack>
            )}
            {isMainInitialized && !isMainAuthenticated && (
              <>
                <Stack direction="row" alignItems="center" sx={{ mb: 5 }}>
                  <Box sx={{ flexGrow: 1 }}>
                    <Typography variant="h4" gutterBottom>
                      {`${t('login')}`}
                    </Typography>
                    <Typography sx={{ color: 'text.secondary' }}>
                      {`${t('loginSubheader')}`}
                    </Typography>
                  </Box>
                </Stack>

                <LoginForm />

                {!smUp && (
                  <Typography variant="body2" align="center" sx={{ mt: 3 }}>
                    {`${t('noAccount')}`}?{' '}
                    <Link variant="subtitle2" component={RouterLink} to={PATH_AUTH.register}>
                      {`${t('register')}`}
                    </Link>
                  </Typography>
                )}
              </>
            )}
            {isMainAuthenticated && isInitialized && (
              <>
                <Stack direction="row" alignItems="center" sx={{ mb: 5 }}>
                  <Box sx={{ flexGrow: 1 }}>
                    <Typography variant="h4" gutterBottom>
                      {`${t('chooseAdmin')}`}
                    </Typography>
                    <Typography sx={{ color: 'text.secondary' }}>
                      {`${t('chooseCompany')}`}
                    </Typography>
                  </Box>
                </Stack>
                <OrganizationSection />
              </>
            )}
          </ContentStyle>
        </Container>
      </RootStyle>
    </Page>
  )
}

export default Login
