function path(root: string, sublink: string) {
  return `${root}${sublink}`
}

const ROOTS_AUTH = '/auth'
const ROOTS_DASHBOARD = '/dashboard'
const ROOTS_PAYMENT = '/payment'
const ROOTS_DOCS = '/documentation'
const ROOTS_ATTENDANCE = '/attendance'

export const PATH_AUTH = {
  root: ROOTS_AUTH,
  login: path(ROOTS_AUTH, '/login'),
  register: path(ROOTS_AUTH, '/register'),
  resetPassword: path(ROOTS_AUTH, 'reset-password'),
}

export const PATH_DASHBOARD = {
  root: ROOTS_DASHBOARD,
  general: {
    attendances: path(ROOTS_DASHBOARD, '/attendances'),
    employees: path(ROOTS_DASHBOARD, '/employees'),
    departments: path(ROOTS_DASHBOARD, '/departments'),
    companies: path(ROOTS_DASHBOARD, '/companies'),
    requests: path(ROOTS_DASHBOARD, '/requests'),
    settings: path(ROOTS_DASHBOARD, '/settings'),
    logs: path(ROOTS_DASHBOARD, '/logs'),
    form: path(ROOTS_DASHBOARD, '/form'),
    announcement: path(ROOTS_DASHBOARD, '/announcement'),
    event: path(ROOTS_DASHBOARD, '/event'),
    report: path(ROOTS_DASHBOARD, '/report'),
    schedule: path(ROOTS_DASHBOARD, '/schedule'),
    document: path(ROOTS_DASHBOARD, '/document'),
    task: path(ROOTS_DASHBOARD, '/task'),
  },
  departments: path(ROOTS_DASHBOARD, '/departments'),
  companies: path(ROOTS_DASHBOARD, '/companies'),
  attendances: {
    daily: path(ROOTS_DASHBOARD, '/attendances/daily'),
    employee: path(ROOTS_DASHBOARD, '/attendances/employee'),
    report: path(ROOTS_DASHBOARD, '/attendances/report'),
  },
  employees: {
    edit: (id: string) => path(ROOTS_DASHBOARD, `/employees/${id}/edit`),
    rating: (id: string) => path(ROOTS_DASHBOARD, `/employees/${id}/rating`),
    create: path(ROOTS_DASHBOARD, '/employees/create'),
  },
  settings: {
    locations: path(ROOTS_DASHBOARD, '/settings/locations'),
    timeshifts: path(ROOTS_DASHBOARD, '/settings/timeshifts'),
    specialDays: path(ROOTS_DASHBOARD, '/settings/special-day'),
    account: path(ROOTS_DASHBOARD, '/settings/account'),
    invoice: path(ROOTS_DASHBOARD, '/settings/invoice'),
  },
  timeshifts: {
    create: path(ROOTS_DASHBOARD, '/settings/timeshifts/create'),
    edit: (id: string) => path(ROOTS_DASHBOARD, `/settings/timeshifts/${id}/edit`),
  },
  form: {
    create: path(ROOTS_DASHBOARD, '/form/create'),
    edit: (id: number) => path(ROOTS_DASHBOARD, `/form/${id}/edit`),
    overview: (id: number) => path(ROOTS_DASHBOARD, `/form/${id}`),
  },
  report: {
    overview: (id: number) => path(ROOTS_DASHBOARD, `/report/${id}`),
  },
  subscription: {
    root: path(ROOTS_PAYMENT, '/subscription'),
  },
  attendance: {
    phone: path(ROOTS_ATTENDANCE, '/phone'),
  },
  schedule: {
    calendar: path(ROOTS_DASHBOARD, '/schedule/calendar'),
    attendance: path(ROOTS_DASHBOARD, '/schedule/attendance'),
  },
  task: {
    overview: (id: number) => path(ROOTS_DASHBOARD, `/task/${id}`),
  },
}

export const PATH_DOCS = {
  root: ROOTS_DOCS,
  general: {
    auth: path(ROOTS_DOCS, '/auth'),
  },
}
