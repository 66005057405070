import { Suspense, lazy, ElementType } from 'react'
import { Navigate, useRoutes, useLocation } from 'react-router-dom'
// layouts
import DashboardLayout from '../layouts/dashboard'
import LogoOnlyLayout from '../layouts/LogoOnlyLayout'
// components
import LoadingScreen from '../components/LoadingScreen'
import useAuth from 'src/hooks/useAuth'
import Login from 'src/pages/auth/Login'
import GuestGuard from 'src/guards/GuestGuard'
import Register from 'src/pages/auth/Register'
import AuthGuard from 'src/guards/AuthGuard'
import PaymentGuard from 'src/guards/PaymentGuard'
import DocumentationLayout from 'src/layouts/documentation'

// ----------------------------------------------------------------------

const Loadable = (Component: ElementType) => (props: any) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { pathname } = useLocation()

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { isAuthenticated } = useAuth()

  const isDashboard = pathname.includes('/dashboard') && isAuthenticated

  return (
    <Suspense fallback={<LoadingScreen isDashboard={isDashboard} />}>
      <Component {...props} />
    </Suspense>
  )
}

export default function Router() {
  return useRoutes([
    {
      path: 'auth',
      children: [
        {
          path: 'login',
          element: (
            <GuestGuard>
              <Login />
            </GuestGuard>
          ),
        },
        {
          path: 'register',
          element: (
            <GuestGuard>
              <Register />
            </GuestGuard>
          ),
        },
      ],
    },
    {
      path: 'payment',
      children: [
        {
          path: 'subscription',
          element: (
            <PaymentGuard>
              <Subscription />
            </PaymentGuard>
          ),
        },
      ],
    },
    {
      path: 'attendance',
      children: [
        {
          path: 'phone',
          element: <PhoneAttendance />,
        },
      ],
    },
    {
      path: 'dashboard',
      element: (
        <AuthGuard>
          <DashboardLayout />,
        </AuthGuard>
      ),
      children: [
        { element: <Navigate to="overview" replace />, index: true },
        { path: 'overview', element: <Overview /> },
        {
          path: 'settings',
          children: [
            { element: <Navigate to="/dashboard/settings/general" replace />, index: true },
            { path: 'account', element: <SettingsAccount /> },
            { path: 'locations', element: <SettingsLocation /> },
            { path: 'special-day', element: <SpecialDay /> },
            {
              path: 'timeshifts',
              children: [
                {
                  element: <Timeshifts />,
                  index: true,
                },
                { path: 'create', element: <CreateTimeshift /> },
                { path: ':id/edit', element: <EditTimeshift /> },
              ],
            },
            { path: 'timeshifts/create', element: <CreateTimeshift /> },
            { path: 'invoice', element: <InvoicesList /> },
          ],
        },
        { path: 'departments', element: <Departments /> },
        {
          path: 'employees',
          children: [
            { element: <EmployeesList />, index: true },
            // { path: ':id/edit', element: <EmployeeEdit /> },
            { path: ':id/rating', element: <EmployeeRating /> },
            // { path: 'create', element: <EmployeeCreate /> },
          ],
        },
        {
          path: 'attendances',
          children: [
            { element: <Navigate to="/dashboard/attendances/daily" replace />, index: true },
            { path: 'daily', element: <AttendanceDaily /> },
            { path: 'employee', element: <AttendanceEmployee /> },
            { path: 'report', element: <AttendanceReport /> },
          ],
        },
        {
          path: 'requests',
          element: <Requests />,
        },

        {
          path: 'report',
          element: <ReportPage />,
        },
        {
          path: 'logs',
          element: <AdminLogs />,
        },
        {
          path: 'form',
          children: [
            { element: <FormsTable />, index: true },
            { path: ':id', element: <FormOverview /> },
            { path: ':id/edit', element: <EditForm /> },
            { path: 'create', element: <CreateForm /> },
          ],
        },
        {
          path: 'announcement',
          element: <Announcement />,
        },
        {
          path: 'event',
          element: <Event />,
        },
        {
          path: 'thanks-box',
          element: <ThanksBox />,
        },
        {
          path: 'document',
          element: <DocumentRequestList />,
        },
        {
          path: 'schedule',
          children: [
            { element: <Navigate to="/dashboard/calendar/schedule" replace />, index: true },
            { element: <CustomCalendar />, path: 'calendar' },
            { element: <CalendarAttendance />, path: 'attendance' },
          ],
        },
        {
          path: 'task',
          children: [
            { element: <TaskSpacesPage />, index: true },
            { path: ':id', element: <TaskBoardsPage /> },
          ],
        },
      ],
    },
    {
      path: 'documentation',
      element: (
        // <GuestGuard>
        <DocumentationLayout />
        // </GuestGuard>
      ),
      children: [
        { element: <Navigate to="auth" replace />, index: true },
        { path: 'auth', element: <AuthDocumentation /> },
        { path: 'location', element: <LocationDocumentation /> },
        { path: 'timeshift', element: <TimeshiftDocumentation /> },
        { path: 'special-day', element: <SpecialDayDocumentation /> },
        { path: 'department', element: <DepartmentDocumentation /> },
        { path: 'employee', element: <EmployeeDocumentation /> },
        { path: 'attendance', element: <AttendanceDocumentation /> },
      ],
    },
    { path: '/', element: <Navigate to="/dashboard" replace /> },
    {
      path: '*',
      element: <LogoOnlyLayout />,
      children: [
        { path: '404', element: <NotFound /> },
        { path: '403', element: <Page403 /> },
        { path: '*', element: <Navigate to="/404" replace /> },
      ],
    },
    { path: '*', element: <Navigate to="/404" replace /> },
  ])
}

// Dashboard
const Overview = Loadable(lazy(() => import('../pages/dashboard/Overview')))

const AttendanceDaily = Loadable(lazy(() => import('../pages/attendances/Daily')))
const AttendanceEmployee = Loadable(lazy(() => import('../pages/attendances/Employee')))
const AttendanceReport = Loadable(lazy(() => import('../pages/attendances/Report')))

const Requests = Loadable(lazy(() => import('../pages/Requests')))

const EmployeesList = Loadable(lazy(() => import('../pages/employees/List')))
// const EmployeeEdit = Loadable(lazy(() => import('../pages/employees/Edit')))
// const EmployeeCreate = Loadable(lazy(() => import('../pages/employees/Create')))

const Timeshifts = Loadable(lazy(() => import('../pages/timeshifts')))
const CreateTimeshift = Loadable(lazy(() => import('../pages/timeshifts/CreateTimeshift')))
const EditTimeshift = Loadable(lazy(() => import('../pages/timeshifts/EditTimeshift')))

const SettingsAccount = Loadable(lazy(() => import('../pages/settings/Account')))
const SettingsLocation = Loadable(lazy(() => import('../pages/settings/Location')))
const SpecialDay = Loadable(lazy(() => import('../pages/settings/SpecialDay')))
const Departments = Loadable(lazy(() => import('../pages/Departments')))
const InvoicesList = Loadable(lazy(() => import('../pages/Invoices')))

const AdminLogs = Loadable(lazy(() => import('../pages/AdminLogs')))

const FormsTable = Loadable(lazy(() => import('../pages/forms/FormsTable')))
const CreateForm = Loadable(lazy(() => import('../pages/forms/CreateForm')))
const EditForm = Loadable(lazy(() => import('../pages/forms/EditForm')))
const FormOverview = Loadable(lazy(() => import('../pages/forms/FormOverview')))

const Announcement = Loadable(lazy(() => import('../pages/Announcement')))

const Event = Loadable(lazy(() => import('../pages/Event')))

const ReportPage = Loadable(lazy(() => import('../pages/report')))

const ThanksBox = Loadable(lazy(() => import('../pages/ThanksBox')))
const Subscription = Loadable(lazy(() => import('../pages/subscription')))
const PhoneAttendance = Loadable(lazy(() => import('../pages/attendance/phone')))

const NotFound = Loadable(lazy(() => import('../pages/Page404')))
const Page403 = Loadable(lazy(() => import('../pages/Page403')))

// const Documentation = Loadable(lazy(() => import('../pages/documentation')))
const AuthDocumentation = Loadable(lazy(() => import('../pages/documentation/Auth')))
const LocationDocumentation = Loadable(lazy(() => import('../pages/documentation/Location')))
const AttendanceDocumentation = Loadable(lazy(() => import('../pages/documentation/Attendance')))
const EmployeeDocumentation = Loadable(lazy(() => import('../pages/documentation/Employee')))
const DepartmentDocumentation = Loadable(lazy(() => import('../pages/documentation/Department')))
const TimeshiftDocumentation = Loadable(lazy(() => import('../pages/documentation/Timeshift')))
const SpecialDayDocumentation = Loadable(lazy(() => import('../pages/documentation/SpecialDay')))
const EmployeeRating = Loadable(lazy(() => import('../pages/employees/rating/index')))

// scheduling
const CustomCalendar = Loadable(lazy(() => import('../sections/custom-calendar/CustomCalendar')))
const CalendarAttendance = Loadable(lazy(() => import('../pages/CalendarAttendance')))

const DocumentRequestList = Loadable(
  lazy(() => import('../pages/document-request/DocumentRequests'))
)

const TaskSpacesPage = Loadable(lazy(() => import('../pages/task/index')))
const TaskBoardsPage = Loadable(lazy(() => import('../pages/task/TaskBoard')))
