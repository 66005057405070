const mn = {
  company: 'Байгууллага',
  companyDetail: {
    name: 'Нэр',
    website: 'Вебсайт',
    phone: 'Утасны дугаар',
    avatar: 'Зураг',
    subtitle: 'Байгууллагын мэдээлэл өөрчлөх',
    success: 'Байгууллагын мэдээлэл амжилттай өөрчлөгдлөө!',
  },
  passwordSubtitle: 'Нэвтрэх нууц үг солих',
  home: {
    totalDepartments: 'Нийт хэлтэс',
    recruit: {
      title: 'Ажилтан бүртгэх код',
      description: 'Ажилтан аппликейшнээр бүртгэлээ үүсгэсний дараа компанид нэгдэх код.',
    },
    pendingRequests: 'Хүлээгдэж буй хүсэлтүүд',
    attendanceForToday: 'Өнөөдрийн ирц',
    weeklyAttendance: 'Долоо хоногийн ирц',
    registered: 'Ажилласан',
    nonregistered: 'Ажиллаагүй',
  },
  features: {
    home: 'Нүүр',
    settings: 'Тохиргоо',
    location: 'Байршил',
    timeshift: 'Цагийн хуваарь',
    specialDay: 'Амралтын өдөр',
    account: 'Хаяг',
    payment: 'Төлбөр',
    schedule: 'Цагийн хуваарь',
    attendance: 'Ирц',
    departments: 'Хэлтсүүд',
    employees: 'Ажилтнууд',
    requests: 'Хүсэлтүүд',
    report: 'Тайлан',
    survey: 'Санал асуулга',
    announcement: 'Мэдэгдэл',
    event: 'Үйл ажиллагаа',
    adminLog: 'Өөрчилсөн түүх',
    thanksBox: 'Талархлын хайрцаг',
    task: 'Даалгавар',
    invoice: 'Нэхэмжлэл',
  },
  requests: 'Хүсэлтүүд',
  noRequests: 'Хүсэлт байхгүй байна',
  created: 'Үүсгэсэн',
  details: 'Дэлгэрэнгүй',
  decideRequest: 'Хүсэлт шийдэх',
  request: {
    requestType: 'Хүсэлтийн төрөл',
    additionalTypes: 'Хүсэлтийн нэмэлт төрлүүд',
    createAdditionalType: 'Нэмэлт төрөл үүсгэх',
    editAdditionalType: 'Нэмэлт төрөл засах',
    deleteAdditionalType: 'Нэмэлт төрөл устгах',
    deleteAdditionalConfirm:
      'Та <strong>{{name}}</strong> нэмэлт төрлийг устгахдаа итгэлтэй байна уу?',

    notFound: 'Хүсэлт ирээгүй байна',
    status: 'Төлөв',
    start_date: 'Ирсэн',
    end_date: 'Явсан',
    type: 'Төлөв',
    additional: 'Нэмэлт',
  },
  paid: 'Цалинтай чөлөө',
  unpaid: 'Цалингүй чөлөө',
  timeRequestStart: 'Цаг нөхөх - ирэх',
  timeRequestEnd: 'Цаг нөхөх - явах',
  timeRequestFull: 'Цаг нөхөх - бүтэн',

  // Form Values
  description: 'Тайлбар',
  name: 'Нэр',
  date: 'Өдөр',
  startDate: 'Эхлэх өдөр',
  endDate: 'Дуусах өдөр',
  startTime: 'Эхлэх цаг',
  endTime: 'Дуусах цаг',
  type: 'Төрөл',
  requestType: 'Хүсэлтийн төрөл',
  additional: 'Нэмэлт',
  response: 'Хариулт',
  password: 'Нууц үг',
  oldPassword: 'Хуучин нууц үг',
  newPassword: 'Шинэ нууц үг',
  passwordConfirmation: 'Шинэ нүүц үг (давтсан)',
  email: 'Имэйл',
  phone: 'Утас',
  role: 'Мэргэжил',
  addRole: 'Мэргэжил нэмэх',
  status: 'Төлөв',
  chooseDates: 'Өдөр сонгох',
  selectOrder: 'Админ сонгох',
  arriveDate: 'Ирэх өдөр',
  arriveTime: 'Ирэх цаг',
  leaveDate: 'Явах өдөр',
  leaveTime: 'Явах цаг',
  admin: 'Админ',
  firstName: 'Нэр',
  lastName: 'Овог',
  title: 'Гарчиг',

  contrast: 'Контраст',
  direction: 'Чиглэл',
  layout: 'Байгуулалт',
  stretch: 'Суналт',
  availableXl: 'Зөвхөн урт дэлгэцүүд дээр > 1600px',
  fullscreen: 'Fullscreen',
  exit: 'Exit',

  // Common
  my: 'Надад',
  general: 'Ерөнхий',
  all: 'Бүгд',
  pending: 'Хүлээгдэж буй',
  cancel: 'Цуцлах',
  close: 'Close',
  accept: 'Зөвшөөрөх',
  arrive: 'Ирлээ',
  leave: 'Явлаа',
  plan: 'Багц',
  remove: 'Устгах',
  delete: 'Устгах',
  edit: 'Засах',
  create: 'Үүсгэх',
  check: 'Шалгах',
  choose: 'Сонгох',
  continue: 'Үргэлжлүүлэх',
  back: 'Буцах',
  checkout: 'Төлөх',
  add: 'Нэмэх',
  export: 'Экспорт',
  uploadPic: 'Зураг оруулах',
  updatePic: 'Зураг шинэчлэх',
  accepted: 'Зөвшөөрсөн',
  rejected: 'Цуцалсан',
  required: 'Шаардлагатай',
  cancelled: 'Цуцалсан',
  view: 'Үзэх',
  action: 'Үйлдэл',
  before: 'Өмнөх',
  after: 'Дараах',
  changes: 'Өөрчлөлтүүд',
  change: 'Өөрчлөлт',
  approve: 'Зөвшөөрөх',
  basic: 'Энгийн',
  qpay: 'QPay',

  active: 'Идэвхитэй',
  inactive: 'Идэвхгүй',
  resigned: 'Гарсан',
  overview: 'Ерөнхий',
  duration: 'Хугацаа',
  available: 'Ашиглах боломжтой',
  unavailable: 'Ашиглах боломжгүй',
  week: 'долоо хоног',
  month: 'сар',
  settings: 'Тохиргоо',
  search: 'Хaйх...',
  color: 'Өнгө',
  code: 'Код',
  newCode: 'Шинэ код',
  other: 'Бусад',
  image: 'Зураг',
  dragDrop: 'Файл зөөх эсвэл сонгох', // Файл зөөх эсвэл сонгох
  dragHere: 'Файлаа зөөж оруулах эсвэл <strong>энд</strong> дарж сонгоно уу.',
  choice: 'Сонголт',
  try: 'Турших',
  exportType: 'Төрөл',
  download: 'Татах',
  selectEmployee: 'Ажилтан сонгох',
  selectDepartment: 'Хэлтэс сонгох',
  total: 'Нийт',

  totalEmployees: 'Нийт ажилтнууд',
  totalLocations: 'Нийт байрлал',
  totalDepartments: 'Нийт хэлтэс',

  // Auth
  logout: 'Гарах',
  login: 'Нэвтрэх',
  register: 'Бүртгүүлэх',
  noAccount: 'Шинэ хэрэглэгч үү',
  haveAccount: 'Хаягтай юу?',
  atWork: 'Ажилдаа <strong>ирлээ</strong>',
  onTime: 'Цагтаа <strong>ирлээ</strong>',
  loginSubheader: 'Өөрийн нэвтрэх мэдээллээ оруулна уу.',
  chooseAdmin: 'Админ хаягаа сонгоно уу.',
  chooseCompany: 'Байгууллага сонгох',
  registerSuccess: 'Амжилттай бүртгэлээ.',
  registerError: 'Бүртгэхэд асуудал гарлаа.',
  registerStep: 'Бүртгэл',
  welcome: 'Тавтай морил',
  irleInfo:
    'IRLE APP нь ажлын байрны төвөгтэй дэс дарааллыг халж ажилтан болон ажил олгогч нарт цагаа үр ашигтайгаар зарцуулах боломжийг олгож буй байгууллагын систем юм.',
  nameQuestion: 'Танай байгууллагын нэр юу вэ?',
  getStarted: 'Эхлэх',
  companyInfo: 'Байгууллагын мэдээлэл',
  companyInfoSubheader: 'IRLE апп болон админ хэсэгт ашиглагдах танай байгууллагын мэдээлэл.',
  fileType: 'Файлын төрөл: *.jpeg, *.jpg, *.png, *.gif, *.webp',
  companyRegistry: 'Байгууллагын регистр',
  loginInfo: 'Нэвтрэх мэдээлэл',
  loginInfoSubheader: 'Админ хэсэг рүү нэвтрэхэд ашиглах мэдээлэл',
  termsOfUse: 'Үйлчилгээний нөхцөл',
  acceptTerms: 'Би үйлчилгээний нөхцөлийг зөвшөөрч байна.',
  saveChanges: 'Хадгалах',
  verifyMail: 'Имэйл батлагаажуулах',
  verify: 'Батлагаажуулах',
  accountOverview: 'Админ хаяг',
  editDetails: 'Админ хаягын мэдээлэл өөрчлөх',
  accountName: 'Нэр',
  profilePic: 'Зураг',
  clickToUpload: 'Энд',
  orDrag: 'дарж эсвэл зургаа зөөж оруулна уу.',
  fileTypes: 'PNG, JPG эсвэл WEBP (хязгаар: 1MB)',
  codeSent: 'Таны шинэ имэйл рүү батлагаажуулах код илгээлээ. Та имэйлээ шалгаад кодоо оруулна уу.',
  changePassword: 'Нууц үг солих',
  accountSuccess: 'Мэдээлэл амжилттай өөрчлөгдлөө.',
  passwordSuccess: 'Нууц үг амжилттай солигдлоо.',
  companySuccess: 'Байгууллагын мэдээлэл амжилттай өөрчлөгдлөө.',
  settingsSuccess: 'Тохиргоо амжилттай өөрчлөгдлөө.',
  mailExists: 'Бүртгэлтэй имэйл хаяг байна.',
  target: {
    title: 'Хамаарах',
    all: 'Байгууллага',
    employee: 'Ажилтан',
    department: 'Хэлтэс',
    employees: 'Ажилтнууд',
    departments: 'Хэлтсүүд',
  },

  // Errors
  error: {
    default: 'Гэнтийн алдаа гарлаа!',
    startDateMore: 'Эхлэх өдөр дуусах өдрийн дараа байж болохгүй.',
    daysRangeLimit: 'Өдөр хоорондын зай 90-ээс их байж болохгүй',
    notFound: 'Олдсонгүй.',
    chooseEmployee: 'Ажилтан сонгоно уу.',
    chooseDepartment: 'Хэлтэс сонгоно уу.',
    chooseDay: 'Өдөр сонгоно уу.',
    chooseAdmin: 'Админ сонгоно уу.',
    chooseHeadDepartment: 'Толгой хэлтэс сонгоно уу.',
    chooseRole: 'Мэргэжил сонгоно уу.',
    selectDate: 'Өдөр сонгоогүй байна',
    enterNumber: 'Тоо оруулна уу.',
    enterName: 'Нэр оруулна уу.',
    enterDuration: 'Хугацаа оруулна уу.',
    enterShortName: 'Богино нэр хоосон байна.',
    endDateAfter: 'Дуусах өдөр эхлэх өдрөөс хойш байх ёстой.',
    invalidEmail: 'Зөв имэйл оруулна уу.',
    emailRequired: 'Имэйл байх шаардлагатай.',
    oldPasswordRequired: 'Хуучин нууц үг байх ёстой',
    passwordRequired: 'Нууц үг байх ёстой.',
    passwordMin8: 'Нууц үгийн урт 8-аас их байх ёстой.',
    passwordValid:
      'Нууц үг нь багадаа 1 том үсэг, 1 жижиг үсэг, 1 онцгой тэмдэгт байх шаардлагатай',
    passwordConfirmation: 'Нууц үгүүд тохирохгүй байна.',
    enterEmployeeCount: 'Ажилтны тоог оруулна уу.',
    employeeCountLow: 'Ажилтны тоо одоогийн байгууллагын ажилчдаас бага байна.',
    signatureRequired: 'Гарын үсэг шаардлагатай',
    errorInvoiceCreate: 'Нэхэмжлэл үүсэхэд алдаа гарлаа.',
    activateSubscription: 'Багцыг идэвхижүүлэхэд алдаа гарлаа.',
    paymentFailed: 'Төлбөр амжилтшзй боллоо! Лавлах: +976 7222 2220',
    paymentPending: 'Төлбөр хүлээлгийн байдалд байна.',
    contractFailed: 'Гэрээ үүсгэхэд алдаа гарлаа.',
    contractSuccess: 'Гэрээ амжилттай үүслээ. Имэйлээ шалгана уу!',
    enterCompanyRegister: 'Байгууллагын регистр шаардлагатай.',
    enterFirstName: 'Нэр хоосон байж болохгүй.',
    enterLastName: 'Овог хоосон байж болохгүй.',
    enterPosition: 'Албан тушаал оруулна уу.',
    enterPhoneNumber: 'Утасны дугаар шаардлагатай.',
    enterResponse: 'Хариу хоосон байж болохгүй.',
    enterLocation: 'Байршил оруулна уу.',
    uploadPicture: 'Зураг оруулагүй байна',
    registerMin10: 'Регистр 10 - аас багагүй урттай байна.',
    phoneTypeError: 'Утасны дугаар байх ёстой.',

    enterQuestion: 'Асуулт хоосон байж болохгүй.',
    enterAssessmentValue: 'Үнэлгээний утга хоосон байна.',
    enterChoice: 'Сонголт хоосон байна.',
    chooseCategory: 'Төрөл сонгоно уу.',

    enterDescription: 'Тайлбар хоосон байна.',
    enterExpireDate: 'Дуусах хугацаа оруулна уу.',

    leaveDateAfter: 'Явах өдөр ирэх өдрөөс хойно байх ёстой',
    leaveTimeAfter: 'Явах цаг ирэх цагаас хойно байх ёстой',
    requestType: 'Хүсэлтийн төрөл олдсонгүй.',

    existingPhone: 'Утасны дугаар бүртгэлтэй байна',
    existingEmail: 'Имэйл бүртгэлтэй байна',
    existingCompanyCode: 'Байгуулагийн код бүртгэлтгэй байна',

    invalidDate: 'Зөв өдөр оруулна уу.',
    invalidTime: 'Зөв цаг оруулна уу.',
    initialTimeBefore: 'Ирэх цаг эхлэх цагаас өмнө байх ёстой.',
    repeatEndAfter: 'Давталт дуусах өдөр явах өдрөөс өмнө байж болохгүй.',
    enterTemplate: 'Загварын нэр оруулна уу.',
  },

  createSuccess: '{{name}} амжилттай үүслээ.',
  editSuccess: '{{name}} амжилттай засагдлаа.',
  deleteSuccess: '{{name}} амжилттай устлаа.',
  acceptSuccess: 'Хүсэлт амжилттай батлагдлаа.',
  rejectSuccess: 'Хүсэлт амжилттай цуцлагдлаа.',

  // Days
  days: {
    mo: 'Да',
    tu: 'Мя',
    we: 'Лх',
    th: 'Пү',
    fr: 'Ба',
    sa: 'Бя',
    su: 'Ня',
    monday: 'Даваа',
    tuesday: 'Мягмар',
    wednesday: 'Лхавга',
    thursday: 'Пүрэв',
    friday: 'Баасан',
    saturday: 'Бямба',
    sunday: 'Ням',
  },

  durations: {
    month: 'Сар',
    quarter: 'Улирал',
    yearly: 'Жил',
    weekly: 'Долоо хоног',
  },

  location: {
    name: 'Байршил',
    heading: 'Байгууллагын байршил тохируулах',
    locations: 'Байршлууд',
    notFound: 'Байршил байхгүй',
    add: 'Байршил нэмэх',
    radius: 'Радиус',
    create: 'Байршил үүсгэх',
    formName: 'Байршлын нэр',
    latitude: 'Өргөрөг',
    longitude: 'Уртраг',
    confirm: 'Та {{name}} байршлыг устгахдаа итгэлтэй байна уу?',
    meter: 'метр',

    enterLocation: 'Байршлын нэрээ оруулна уу.',
    rangePositive: 'Радиус 1метрээс урт байх ёстой',
    enterRange: 'Радиус оруулна уу.',
    permission: 'Байршлын тогтоох зөвшөөрөл өгнө үү.',
  },

  timeshift: {
    heading: 'Цагийн хуваариуд',
    createHeading: 'Шинэ хуваарь',
    editHeading: 'Цагийн хуваарь засах',
    short: 'Хуваарь',
    name: 'Нэр',
    type: 'Төрөл',
    target: 'Хамаарах',
    location: 'Байршил шалгах',
    daily: 'Тогтмол',
    shift: 'Ээлжийн',
    free: 'Чөлөөт',
    duration: 'Хугацаат',
    breakTime: 'Цайны цаг',
    checkLocation: 'Байршил шалгах',
    dailyDuration: 'Өдөрт ажиллах хугацаа',
    flexibleInfo:
      'Чөлөөт цагийн хуваарь нь тогтсон ирэх, явах цаг байхгүй ба хоцролт, илүү цаг бодогдохгүй.',
    workDuration: 'Ажиллах цаг',
    moveConfirm: 'Энэ өдрийн бүх тогтмол цагууд адил хөдлөхийг анхаарна уу.',
  },

  specialDay: {
    meta: 'Тохиргоо: Өдөр',
    heading: 'Амралтын өдөр',
    edit: 'Өдөр засах',
    create: 'Өдөр нэмэх',
  },

  account: {
    meta: 'Тохиргоо: Хаяг',
    heading: 'Админ хаяг шинэчлэх',
  },

  subscription: {
    meta: 'Төлбөр',
    choosePlan: 'Багц сонгох',
    checkout: 'Шалгах',
    payment: 'Төлбөр төлөх',

    info: 'Танд <strong>{{date}}</strong> дуусах <strong>{{plan}}</strong> багц байна.',
    employeeCount: 'Ажилчдын тоо',
    trial: 'Туршилтаар ашиглах',
    employee: 'ажилтан',
    promo: 'Промо',
    available: 'Ашиглах боломжтой',
    unavailable: 'Ашиглах боломжгүй',
    total: 'Нийт үнэ',

    plan: 'Багц',
    price: 'Үнэ',
    initialPrice: 'Анхны үнэ',
    tax: 'НӨАТ',
    totalPrice: 'Нийт төлөх дүн',

    trialLoading: 'Туршилтын эрх үүсгэж байна.',
    trialSuccess: 'Туршилтын эрх амжилттай үүслээ.',
    transferInfo:
      'Шилжүүлгээр төлөх тохиолдолд гүйлгээний утга дээр гүйгээний дугаарыг бичсэн байх шаардлагатай.',
    bankTransfer: 'Шилжүүлэг',

    bank: 'Банк',
    tdb: 'Худалдаа Хөгжлийн банк',
    bankAccount: 'Дансны нэр',
    faroLimited: 'Фаро Лимитед Групп',
    accountNumber: 'Дансны дугаар',
    transferDescription: 'Гүйлгээний утга',

    contractWait:
      'Гэрээг үүсгэхэд <strong>1 минут</strong> хүртэлх хугацаа шаардагддаг тул хэсэг хугацаанд хүлээнэ үү!',
  },

  schedule: {
    calendarHeading: 'Цагийн хуваарь',
    filterType: 'Сонгох төрөл',
    templates: 'Загварууд',
    template: 'Загвар',
    clear: 'Цэвэрлэх',
    apply: 'Батлах',
    chooseEmployee: 'Ажилтан сонгох',
    chooseDepartment: 'Хэлтэс сонгох',
    daily: 'Тогтмол',
    shift: 'Ээлжийн',
    initialTime: 'Уян хатан цаг',
    initialInfo:
      'Ажилтан ирэх ёстой цагаасаа өмнө ирэн ажлаа эхлүүлэх боломжтой цаг Жнь: Ажилтан 07:00-д ирэн 16:00 тарах боломжтой бол 07:00 гэж оруулна.',
    breakStart: 'Цайны эхлэх цаг',
    breakEnd: 'Цайны дуусах цаг',
    repeatEnd: 'Давталт дуусах өдөр',
    repeatDuration: 'Давталтын хугацаа',
    create: 'Хуваарь үүсгэх',
    edit: 'Хуваарь шинэчлэх',
    delete: 'Хуваарь устгах',
    repeat: 'Давталт',
    noRepeat: 'Давтахгүй',
    repeatWeekly: 'Долоо хоног',
    deleteDailyInfo: 'Энэ үйлдэл нь давхардаж буй бусад бүх хуваарийг хамт устгана.',
    updateDailyInfo: 'Энэ үйлдэл нь давхардаж буй бусад бүх хуваарийг хамт засна.',
    deleteShiftInfo: 'Та аль төрлийн хуваарийг устгах вэ?',
    updateShiftOnfo: 'Та аль төрлийн хуваарийг засах вэ?',
    singleConfirm: 'Зөвхөн энэ хуваарь',
    recurringConfirm: 'Давхцаж буй бүх хуваарь',

    newTemplate: 'Шинэ загвар',
    applyTemplate: 'Загвар оруулах',
    removeTemplate: 'Загвар устгах',
    applyConfirmation:
      '<strong>{{template}}</strong> загварыг сонгосон бүх ажилчдад оруулахадаа итгэлтэй байна уу?',
    deleteConfirmation: 'Та <strong>{{template}}</strong> загварыг устгахдаа итгэлтэй байна уу?',

    clearHeading: 'Хуваарь цэвэрлэх',
    clearInfo:
      'Энэ үйлдэл нь сонгосон хугацаа, ажилтнууд дээрх бүх цагийн хуваарийг устгана. Тогтмол цагийн хуваариуд заасан бүх өдрүүдэд устахыг анхаарна уу.',
    clearTypes: 'Цагийн хуваарийн төрлүүд',
    applied: 'Батласан',
    unapplied: 'Батлаагүй',
    all: 'Бүгд',
    selectEmployee: 'Ажилтан сонгох',

    applySuccess: 'Хуваариуд амжилттай баталгаажлаа..',
    templateDeleted: 'Загвар амжилттай устлаа.',
    clearSuccess: 'Хуваариуд амжилттай устлаа.',

    startDate: 'Ирэх өдөр',
    endDate: 'Явах өдөр',
    startTime: 'Ирэх цаг',
    endTime: 'Явах цаг',
  },

  calendarAtt: {
    meta: 'Хуваарь | Ирц',
    heading: 'Ирц',
    scheduled: 'Ажиллах',
    totalWork: 'Ажилласан',
    overtime: 'Илүү цаг',
    cut: 'Таслалт',
    totalNonWork: 'Бүртгэгдээгүй',
    salaryLeave: 'Цалинтай чөлөө',
    nonSalaryLeave: 'Цалингүй чөлөө',
    overtimeLeave: 'Илүү цагийн амралт',
    vacation: 'Ээлжийн амралт',
    specialDay: 'Амралтын өдөр',
    attendances: 'Ирцүүд',
    add: 'Ирц нэмэх',
    addAttendance: 'Ирц бүртгэх', // Ирц бүртгэх

    notFound: 'Ирц олдсонгүй',
    export: 'Ирцийн экспорт',
    exportDuration: 'Экспорт хугацаа',
  },

  department: {
    heading: 'Хэлтсүүд',
    short: 'Хэлтэс',
    edit: 'Хэлтэс засах',
    create: 'Хэлтэс үүсгэх',
    delete: 'Хэлтэс устгах',
    shortName: 'Богино нэр',
    headDepartment: 'Толгой хэлтэс',
    admins: 'Админууд',
    deleteConfirm: 'Та <strong>{{name}}</strong> хүсэлтийг устгахдаа итгэлтэй?',
  },

  employee: {
    search: 'Ажилтан, утас',
    makeAdmin: 'Админ болгох',
    removeAdmin: 'Админ хасах',
    assessment: 'Үнэлгээ',
    changePhone: 'Утас солих',
    makeAdminConfirm: 'Та <strong>{{name}}</strong> ажилтанг админ болгохдоо итгэлтэй байна уу?',
    removeAdminConfirm:
      'Та <strong>{{name}}</strong> ажилтанг админаас хасахдаа итгэлтэй байна уу?',
    edit: 'Ажилтан засах',
    create: 'Ажилтан нэмэх',
    joinDate: 'Ажилд орсон өдөр',
    insuranceDuration: 'Нийгмийн даатгал төлсөн сар',
    phoneInfo: 'Байгууллагад бүртгэлгүй утасны дугаар оруулна уу.', // Байгууллагад бүртгэлгүй утасны дугаар оруулна уу.
    changeCode: 'Утас солих код',
    removeConfirm:
      'Та ажилтанг <strong>{{name}}</strong> байгууллагаас хасахдаа итгэлтэй байна уу?',
    assessmentMeta: 'Ажилтан: Үнэлгээ',
    kpi: 'КPI утга',
    totalRating: 'Нийт үнэлгээ',
    assessmentCount: 'Үнэлгээ өгсөн тоо',
    averageRating: 'Дундаж үнэлгээ',
    recieved: 'Ирэх',
    assessed: 'Өгөх',
  },

  attendance: {
    heading: 'Ирц',
    daily: 'Өдрөөр',
    employee: 'Нэрсээр',
    report: 'Ирц татах',
    dailyHeading: 'Өдрийн ирц',
    employeeHeading: 'Нэрсээр',
    reportHeading: 'Ирц татах',
    create: 'Ирц үүсгэх',
    edit: 'Ирц засах',
    delete: 'Ирц устгах',
    deleteConfirm: 'Та <strong>{{date}}</strong> өдрийн ирцийг устгахдаа итгэлтэй байна уу?',
    requestDuration: 'Хүсэлтийн хугацаа',
  },

  objectTypes: {
    leave: 'Чөлөө',
    overtime: 'Илүү цаг',
    vacation: 'Ээлжийн амралт',
    overtimeLeave: 'Илүү цагийн амралт',
    businessTrip: 'Томилолт',
    offsite: 'Гадуур ажиллах',
    timeRequest: 'Цаг нөхөх',
    longTerm: 'Урт хугацааны чөлөө',
    document: 'Тодорхойлолт',
    all: 'Бүгд',
  },

  report: {
    heading: 'Тайлан',
    overview: 'Ерөнхий',
    detailed: 'Дэлгэрэнгүй',
    date: 'Өдөр',
    created: 'Бичсэн',
    description: 'Тайлан',
  },

  survey: {
    heading: 'Санал асуулга',
    short: 'Санал асуулга',
    category: 'Ангилал',
    duration: 'Бөглөх хугацаа',
    responses: 'Хариултууд',
    target: 'Хамаарах',
    expireDate: 'Дуусах өдөр',
    expireTime: 'Дуусах цаг',
    created: 'Үүсгэсэн',
    normal: 'Санал асуулга',
    assessment: 'Үнэлгээ',
    overview: 'Ерөнхий',
    detailed: 'Дэлгэрэнгүй',
    employeeNames: 'Ажилчдын нэрс',
    noResponses: 'Хариулт байхгүй байна.',
    create: 'Санал асуулга үүсгэх',
    edit: 'Санал асуулга засах',
    min: 'минут',
    questions: 'Асуултууд',
    question: 'Асуулт',
    assessmentVal: 'Үнэлгээний утга',
    text: 'Текст',
    choice: 'Ганц сонголт',
    checkbox: 'Олон сонголт',
    anonymous: 'Хэн хариулт илгээснийг хадгалахгүй',
    addCategory: 'Ангилал нэмэх',
    assessmentInfo:
      '<strong>Үнэлгээний</strong> асуулга үүсгэх тохиолдолд асуултууд зөвхөн <strong>тоон</strong> утга авна',
    assessmentTarget: 'Үнэлгээний судалгаанд ажилтнууд сонгохгүй байж болно.',

    editTitle: 'Та энэ асуулгыг засахдаа итгэлтэй байна уу?',
    editBody:
      "Танд хадгалаагүй асуултууд байна! '✓' товчин дээр дарж өөрчлөлт хийсэн асуултуудаа хадгална уу.",

    deleteTitle: 'Та энэ асуултыг устгахдаа итгэлтэй байна уу?',
    deleteBody: 'Энэ асуултны өмнөх хариултууд бас устахыг анхаарна уу.',
    deleteConfirmation: 'Та <strong>{{name}}</strong> санал асуулгыг устгахдаа итгэлтэй байна уу?',
  },

  announcement: {
    heading: 'Мэдэгдэл',
    announcements: 'Мэдэгдлүүд',
    create: 'Мэдэгдэл үүсгэх',
    createConfirm: 'Та <strong>{{name}}</strong> мэдэгдэлийг үүсгэх үү?',
    createBody: 'Үүсгэсэн мэдэгдлийг засах боломжгүйг анхаарна уу',
    deleteConfirm: 'Та <strong>{{name}}</strong> мэдэгдлийг устгахдаа итгэлтэй байна уу?',
  },

  event: {
    heading: 'Үйл ажиллагаанууд',
    short: 'Үйл ажиллагаа',
    create: 'Үйл ажиллагаа үүсгэх',
    edit: 'Үйл ажиллагаа засах',
    location: 'Байршил',
    deleteConfirm: 'Та <strong>{{name}}</strong> үйл ажиллагааг устгахдаа итгэлтэй байна уу?',
  },

  logs: {
    heading: 'Админ өөрчлөлтүүд',
  },

  thanksBox: {
    heading: 'Талархалын хайрцаг',
    empty: 'Талархалын хайрцаг хоосон байна.',
    from: 'Хэнээс',
    to: 'Хэнд',
    created: 'Үүсгэсэн',
    thanks: 'Талархал',
  },

  task: {
    heading: 'Даалгаварууд',
    short: 'Даалгавар',
    space: 'Ажлын талбар',
    board: 'Ажлын талбар',

    addSpace: 'Ажлын талбар нэмэх',
    createSpace: 'Ажлын талбар үүсгэх',
    editSpace: 'Ажлын талбар засах',
    deleteSpace: 'Ажлын талбар устгах',
    spaceConfirmation: 'Та <strong>{{name}}</strong> ажлын талбарыг устгахдаа итгэлтэй байна уу?',

    addBoard: 'Ажлын талбар нэмэх',
    editBoard: 'Ажлын талбар засах',
    deleteBoard: 'Ажлын талбар устгах',
    boardConfirmation: 'Та <strong>{{name}}</strong> ажлын талбарыг устгахдаа итгэлтэй байна уу?',

    deleteTask: 'Ажил устгах',
    taskConfirmation: 'Та <strong>{{name}}</strong> ажлыг устгахдаа итгэлтэй байна уу?',
    addTask: 'Ажил нэмэх',
    taskName: 'Ажлын нэр',
    description: 'Тайлбар',
    assignees: 'Ажилтанууд',
    assigned: 'Оноосон',
    assign: 'Оноох',

    dueDate: 'Дуусах хугацаа',
    addDueDate: 'Дуусах хугацаа нэмэх',
    applyDate: 'Сонгох',
    selectDate: 'Өдөр сонгох',
    save: 'Хадгалах',

    subtasks: 'Ажлууд',
    taskChanged: 'Ажил амжилттай шинэчлэгдлээ.',
    enterTask: 'Ажлын нэр оруулна уу',
  },

  nav: {
    hello: 'Сайн уу, {{name}}',
    needHelp: 'Тусламж хэрэгтэй юу?',
  },

  tablet: {
    registerAtt: 'Ирц бүртгүүлэх',
    noPermission: 'Танд эрх байхгүй байна.',
    enterPhone: 'Утасны дугаар оруулах',
    phoneDescription:
      'Ирцээ бүртгүүлэхийн тулд өөрийн утасны дугаарыг ашиглан өөрийн мэдээллийг хайх шаардлагатай.',
  },

  invoice: {
    heading: 'Нэхэмжлэл',
    number: 'Дугаар',
    method: 'Төрөл',
    trial: 'Туршилт',
    employeeCount: 'Ажилчдын тоо',
    duration: 'Хугацаа',
    download: 'Нэхэмжлэл татах',
    notFound: 'Нэхэмжлэл байхгүй байна.',
    total: 'Нийт төлбөр',
  },

  verification: {
    sendAlert: 'Батлагаажуулах код <strong>{{email}}</strong> цахим шуудан луу илгээгдлээ.',
    reload: 'Та <strong>{{countdown}}</strong> секундын дараа дахин илгээнэ үү!.',
    code: 'Баталгаажуулах код',
  },

  alert: {
    location: 'Улаан сумыг чирж өөрийн хүссэн байршил дээр тавина уу.',
  },

  requestSettings: {
    type: 'Төрөл',
    policy: 'Дүрэм',
    policyTitle: 'Шинэ дүрэм нэмэх',
    adminOrder: 'Админы дараалал',
    adminAdd: 'Админ нэмэх',
    adminInfo: 'Админууд нь бага дугаартайгаас эхлэн хүсэлтэнд хариуг илгээнэ',
    objectTypeRequired: 'Хүсэлтийн төрөл сонгох шаардлагатай.',
  },
  defaultCreateSuccess: 'Амжилттай үүслээ.',
  defaultCreateError: 'Үүсгэхэд алдаа гарлаа.',
  defaultDeleteSuccess: 'Амжилттай устгалаа.',
  defaultDeleteError: 'Устгахад алдаа гарлаа.',
}

export default mn
