import { createSlice } from '@reduxjs/toolkit'
import DepartmentService from 'src/services/DepartmentService'
import { dispatch } from '../store'
import { DepartmentState } from 'src/@types/department'

const initialState: DepartmentState = {
  departments: [],
  isLoading: false,
  error: null,
}

const slice = createSlice({
  name: 'department',
  initialState,
  reducers: {
    startLoading(state) {
      state.isLoading = true
    },

    hasError(state, action) {
      state.isLoading = false
      state.error = action.payload
    },
    getDepartmentsSuccess(state, action) {
      state.isLoading = false
      state.departments = action.payload
      // state.total = action.payload.total
    },
  },
})

export default slice.reducer
export const { actions } = slice

export function getDepartments(params?: object) {
  return async () => {
    dispatch(slice.actions.startLoading())
    try {
      const response = await DepartmentService.getDepartments(params)
      dispatch(slice.actions.getDepartmentsSuccess(response.data))
    } catch (error) {
      dispatch(slice.actions.hasError(error))
    }
  }
}
